import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'unidades'
})
export class UnidadesPipe implements PipeTransform {

  transform(value): unknown {

    switch (value) {
      case 99: return "S/U";break;
      case 1: return "Unidad";break;
      case 2: return "Caja";break;
      case 3: return "Gl";break;
      case 4: return "Kg";break;
      case 5: return "Bolsas";break;
      case 6: return "";break;
      // case 7: return "";break;
      // case 8: return "";break;
    
      default: return "Indefinido"
        break;
    }

  }

}
