import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClienteService {

  private apiUrl = environment.url;
  constructor(private http: HttpClient) { }

  guardar(data): Observable<any>{

    var url = `${this.apiUrl}/clientes`;
    return this.http.post(url, data);

  }

  listar(): Observable<any>{
    var url = `${this.apiUrl}/clientes`;
    return this.http.get(url);
  }

  update(data:any):Observable<any>{
    var url = `${this.apiUrl}/clientes`;
    return this.http.put(url,data);
  }
}
