import { Component, OnInit } from '@angular/core';
import { GastosGeneralesService } from 'src/app/shared/services/gastos-generales.service';
import { ProyectoService } from 'src/app/shared/services/proyecto.service';
import { DatePipe, DecimalPipe } from '@angular/common';
import { environment } from 'src/environments/environment';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { config } from 'rxjs';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-lista-gastos-generales',
  templateUrl: './lista-gastos-generales.component.html',
  styleUrls: ['./lista-gastos-generales.component.css']
})
export class ListaGastosGeneralesComponent implements OnInit {

  monto_total_transporte: number = 0;
  monto_total_compras: number = 0;
  monto_total_gf: number = 0;

  constructor(
    config: NgbModalConfig,
    private modal:NgbModal,
    private gg:GastosGeneralesService, 
    private proyecto:ProyectoService,
    public DecimalPipe:DecimalPipe
    ) { }
  proyectos:any;
  tabla_resumen:boolean=false;
  tabla:any;
  total_form:number=0;
  total_pro:number=0;
  total_comi:number=0;
  total_fle:number=0;
  total_cob:number=0;
  tabla_proyectos:any;
  count_pro:number=0;
  tabla_comision:any;
  count_comision:number=0;
  tabla_flete:any;
  count_flete:number=0;
  tabla_pago:any;
  count_pago:number=0;
  desde:Date;
  hasta:Date;
  ngproyecto:string="0";
  _modal=null;
  btn_busca:boolean=false;
  ver:boolean=false;
  public apiUrl = environment._url;
  ngOnInit(): void {
    
    this.get_proyectos();
  } 

  get_proyectos(){
    this.proyecto.listar().subscribe(res=>{
      this.proyectos = res.proyectos;
    });
  }
  change_proyecto(){
    this.limpiar_tablas();
  }
  filtrar(){
    this.tabla_resumen = false;
    this.btn_busca = true;
    if(!this.desde || !this.hasta || this.ngproyecto==null){
      Swal.fire(
        'Ingrese todos los campos con (*)',
        '',
        'error'
      )
      this.tabla_resumen = false;
      this.btn_busca = false;
      return false;
    }
    this.limpiar_tablas();
    this.gg.get_gastos(this.ngproyecto||"0",this.desde, this.hasta).subscribe(res =>{
      if(res.estado){
        this.ver = true;
        this.tabla_resumen = true;
        this.tabla = res.formulario;
        this.suma_gasto(this.tabla);
        this.tabla_proyectos = res.proyecto;
        this.count_pro = res.proyecto.length;
        this.tabla_comision = res.comision;
        this.count_comision = res.comision.length;
        this.tabla_flete = res.fletes;
        this.count_flete = res.fletes.length;
        this.tabla_pago = res.pagos;
        this.count_pago = res.pagos.length;

        this.total_formulario(res.formulario);
        this.total_proyecto(res.proyecto);
        this.total_comision(res.comision);
        this.total_flete(res.fletes);
        this.total_cobros(res.pagos);
        this.btn_busca = false;
        
      }
    }, error =>{
      this.tabla_resumen = false;
      this.btn_busca = false;
      this.ver = false;

      Swal.fire(
        'No se ha podido filtrar',
        '',
        'error'
      )
      this.tabla_resumen = false;
      this.btn_busca = false;
      return false;
    })
  }
  limpiar_tablas(){
        this.ver = false;
        this.tabla_resumen = false;
        this.tabla = null;
        this.tabla_proyectos = null;
        this.count_pro = 0;
        this.tabla_comision = null;
        this.count_comision = 0;
        this.tabla_flete = null;
        this.count_flete = 0;
        this.tabla_pago = null;
        this.count_pago = 0;
        // totales
        this.total_form = 0;
        this.total_comi = 0;
        this.total_pro = 0;
        this.total_cob = 0;
        this.total_fle = 0;

  }

  money_clp(value){
    // console.log(value);
    return '$ '+this.DecimalPipe.transform(value)?.replace(/,/g,'.')
  }
  txt_proyecto(elemento){
    console.log("elemento", elemento.itemsList._selectionModel._selected[0].label)
    
    return elemento.itemsList._selectionModel._selected[0].label;
  }
  traer_documento(name_doc){

    var xhr = new XMLHttpRequest();
    xhr.open('GET', environment._url+name_doc);
    xhr.onreadystatechange = handler;
    xhr.responseType = 'blob';
    xhr.setRequestHeader('authorization-header', environment.header)
    xhr.setRequestHeader('authorization-token', localStorage.getItem('token')?localStorage.getItem('token'):'')
    ;
    xhr.send();
    
    function handler() {
      if (this.readyState === this.DONE) {
        if (this.status === 200) {
          
          var data_url = URL.createObjectURL(this.response);
             console.log("test->",data_url);
             document.querySelector("iframe").src = data_url
        } else {
          console.error('no pdf :(');
        }
      }
    } 
  }

  open_modal(ref){
    console.log(ref)
    this.modal.open(ref, { size:"lg", windowClass: 'big-modal-none' });
  }
  open_modal_editar(ref){
    console.log(ref)
    this._modal = this.modal.open(ref, { size:"lg", windowClass: 'big-modal' });
  }

  total_formulario(objeto:any){
    let total=0;
    objeto.forEach(c => {
      c.gastos.forEach(g => {
        let monto = g.monto;
        total+=monto;
        this.total_form = total;
      });
    });
  }

  total_proyecto(objeto:any){
    let total=0;
    objeto.forEach(c => {
        let monto = c.total;
        
        total+=Number(monto);
        this.total_pro = total;
        
    
    });
  }
  total_comision(objeto:any){
    let total=0;
    objeto.forEach(c => {
        let monto = c.total;
        
        total+=Number(monto);
        this.total_comi = total;
    });
  }

  total_flete(objeto:any){
    let total=0;
    objeto.forEach(c => {
        let monto = c.total;
        
        total+=Number(monto);
        this.total_fle = total;
    });
  }
  total_cobros(objeto:any){
    let total=0;
    objeto.forEach(c => {
        let monto = c.total;
        total+=Number(monto);
        this.total_cob = total;
    });
  }

  recibe(event){
    if(event == 'true'){
      this._modal.close();
      this.filtrar();
    }
  }
  suma_gasto(item:any){
    console.log('item total, ',item)
    item.forEach(loop => {
      console.log('loop', loop)
      if(loop.categoria=='TRANSPORTE'){
        loop.gastos.forEach(i => {
          console.log('->item, ',i)
          this.monto_total_transporte+=Number(i.monto);
        });
      }

      if(loop.categoria=='COMPRAS'){
        loop.gastos.forEach(i => {
          console.log('->item, ',i)
          this.monto_total_compras+=Number(i.monto);
        });
      }

      if(loop.categoria=='GASTOS FIJOS'){
        loop.gastos.forEach(i => {
          console.log('->item, ',i)
          this.monto_total_gf+=Number(i.monto);
        });
      }
    });
   
  }

  

}
