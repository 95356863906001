<div class="cp-panel-head">
    <div align="center">
        Proyecto: {{input_proyecto_txt}} | Presupuesto: {{ input_presupuesto_txt }}

    </div>
</div>
<div class="cp-panel-body">

    <div class="alert alert-warning" role="alert">
        Mientras no exista un "valor presupuestado" definido no se podrá ingresar un flete al detalle.
      </div>

        <div class="row">
            <div class="col-md-6">
                <label for="">Valor presupuestado para presupuesto {{input_presupuesto_txt}}</label>
                <input [(ngModel)]="valor" type="text" class="form-control">
            </div>
            <div class="col-md-3">
                
                <div class="mt-4" align="center"><label class="css-clp" for="">{{money_clp(valor)}}</label></div>
            </div>
            <div class="col-md-3">
            
                <button (click)="reg_actu();" class="mt-4 btn btn-registrar">Registrar / actualizar</button>
            </div>
        </div>

        <!-- formulario detalle flete -->
        
    
</div>

<div class="mt-4 cp-panel-head">
    <div align="center">
        Detalle del flete

    </div>
</div>
<div class="cp-panel-body">

   

    <form [formGroup]="form_fte">
        <div class="row">
           <div class="col-md-4">
               <label>Fecha</label>
               <input formControlName="fecha"  class="form-control" type="date" >
           </div>
           <div class="col-md-4">
               <label>Responsable</label>
               <input formControlName="responsable" class="form-control" type="text" >
           </div>
           <div class="col-md-4">
               <label>Valor</label>
               <input formControlName="valor" class="form-control" type="text">
           </div> 
        </div>
    </form>
    <div class="row">
        <div class="col-md-12">
            <!-- {{btn_detalle}} -->
            <button  (click)="register();" [disabled]="!btn_detalle" class="mt-4 btn btn-registrar w-100">Registrar</button>
        </div>
    </div>

    
</div>



<div *ngIf="lista_detalle_fte.length > 0" class="mt-4 cp-panel-head">
    <div align="center">
        Lista Detalle del flete

    </div>
</div>
<div *ngIf="lista_detalle_fte.length > 0" class="cp-panel-body">
    <div class="row">
        <div class="col-md-12">
            <table class="table table-bordered">
                <thead class="thead-dark">
                    <tr>
                        <th>Fecha</th>
                        <th>Responsable</th>
                        <th>Monto</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let i of lista_detalle_fte">
                        <td>{{i.fecha | date:'dd/MM/yyyy':'+0000':'es-CL'}}</td>
                        <td>{{ i.responsable }}</td>
                        <td>{{ money_clp(i.valor) }}</td>
                    </tr>
                </tbody>
                <tfoot class="thead-dark">
                    <tr>
                        <th colspan="2" class="text-right">Total monto flete</th>
                        <td class="tbl-foot-td"><b>{{ money_clp(total_flete) }}</b></td>
                    </tr>
                    <tr>
                        <th colspan="2" class="text-right">Diferencia ({{money_clp(valor)}} - {{ money_clp(total_flete) }} )</th>
                        <td class="tbl-foot-td"><b>{{ money_clp(valor-total_flete) }}</b></td>
                    </tr>
                </tfoot>

            </table>
        </div>
    </div>
</div>
