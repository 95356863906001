import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tipoPresupuesto'
})
export class TipoPresupuestoPipe implements PipeTransform {

  transform(value): unknown {
    
    switch (value) {
      case 1: return "Materiales"; break;
      case 2: return "Mano de obra"; break;
      case 3: return "Maquinarias, herramientas y equipos"; break;
      case 4: return "Comisión"; break;
    
      default:
        break;
    }
  }

}
