<app-navbar></app-navbar>

<div class="container mt-4">
  <div class="card salmon-outline">

    <div class="card-header cp-card-header">
      <div align="center">FORMULARIO DE GASTOS GENERALES</div>
    </div>

    <div class="card-body">
      <form [formGroup]="form">
        <div class="row mt-2">
          <div class="col-md-6">
            <label>CATEGORIA*</label>
            <ng-select formControlName="id_categoria"  placeholder="SELECCIONE" (clear)="clear_categoria()" (change)="sub_categoria($event)" >
              <ng-option *ngFor="let categoria of categorias" [value]="categoria.id">{{categoria.desc}}</ng-option>
            </ng-select>
          </div>
          <div class="col-md-6">
            <label>SUB-CATEGORIA*</label>
            <ng-select formControlName="id_sub_categoria"  placeholder="SELECCIONE">
              <ng-option *ngFor="let categoria of subCategorias" [value]="categoria.id">{{categoria.desc}}</ng-option>
            </ng-select>
          </div>
        </div>
      
        <div class="row mt-2">

          <div class="col-md-4">
            <label for="">FECHA*</label>
            <input formControlName="fecha" class="form-control" type="date">
          </div>

          <div class="col-md-4">
            <label for="">MONTO*</label>
            <input formControlName="monto" class="form-control" type="text">
          </div>
          <div class="col-md-4">
            <label>DOCUMENTO</label>
            <input formControlName="documento" (change)="onSelectImage($event)" class="form-control" type="file">
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-md-12">
            <label>DESCRIPCION</label>
            <input formControlName="desc"  class="form-control" type="text">
          </div>
        </div>
      </form>
    </div>

    <div class="card-footer">
      <div class="row">
        <div class="col-12 col-md-6 mt-2">
          <button [disabled]="btn" (click)="register();" class="btn btn-registrar btn-block">Registar</button>
        </div>
        <div class="col-12 col-md-6 mt-2">
          <button class="btn btn-visualizar btn-block" [routerLink]="['/auth/lista-gastos-generales']"><i
              class="fas fa-list"></i> VER GASTOS</button>
        </div>
      </div>
    </div>

  </div>
</div>


<app-footer></app-footer>
