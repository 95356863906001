import { Component, OnInit } from '@angular/core';
import { DatePipe, DecimalPipe } from '@angular/common';
import { ProveedorService } from '../../../../shared/services/proveedor.service';
import { MaterialesService } from '../../../../shared/services/materiales.service';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-lista-materiales',
  templateUrl: './lista-materiales.component.html',
  styleUrls: ['./lista-materiales.component.css'],
})
export class ListaMaterialesComponent implements OnInit {
  _empresas: any;
  empresa: string = '0';
  material: string = null;
  listado: any;
  count = 0;
  modalref=null;
  constructor(
    private _proveedor: ProveedorService,
    private _materiales: MaterialesService,
    public DecimalPipe: DecimalPipe,
    config: NgbModalConfig,
    private modal: NgbModal,
  ) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit(): void {
    this.empresas();
  }

  empresas() {
    this._proveedor.traer(1).subscribe((res) => {
      if (res.estado) {
        this._empresas = res.proveedores;
      }
      console.log(res.proveedores);
    });
  }

  listar_materiales() {
    this._materiales.listar_materiales(this.empresa, this.material||null).subscribe(
      (res) => {
        if (res.estado) {
          this.listado = res.materiales;
          this.count = res.materiales.length;
        } else {
          this.listado = [];
          this.count = res.materiales.length;
        }
      },
      (error) => {
        this.listado = [];
        this.count = 0;
      }
    );
  }
  reload(){
    this.material = null;
    this.empresa = '0';
    this.listar_materiales();
  }

  money_clp(value) {
    // console.log(value);
    return '$ ' + this.DecimalPipe.transform(value)?.replace(/,/g, '.');
  }
  open_modal(ref) {
    this.modalref = this.modal.open(ref, { size: 'lg' });
    return false;
  }
  recibe(entrada){
    if(entrada == 'true'){
      this.listar_materiales();
      this.modalref.close();
    }
  }
}
