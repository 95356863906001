import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProyectoService } from 'src/app/shared/services/proyecto.service'
import { SelectoresService } from 'src/app/shared/services/selectores.service';
import Swal from 'sweetalert2';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'app-formulario-proyecto',
  templateUrl: './formulario-proyecto.component.html',
  styleUrls: ['./formulario-proyecto.component.css']
})
export class FormularioProyectoComponent implements OnInit {

  public form: FormGroup;
  clientes;
  btn_guarda:boolean=false;
  constructor(
    private _proyecto:ProyectoService,
    public DecimalPipe:DecimalPipe,
    private fb: FormBuilder, private _selectores:SelectoresService) { }

  ngOnInit(): void {
    this.construccion_form();
    this.select();
    
    
  }

  construccion_form(){
    this.form = this.fb.group({
      id_cliente:[null, Validators.required],
      proyecto:['', Validators.required],
      fecha_inicio:['', Validators.required],
      total:['', Validators.required],
      cuotas:['', Validators.required]
    });

    this.dibujar_moneda();
  }

  select(){
    this._selectores.getClientes().subscribe(res=>{
      this.clientes = res.clientes

      console.log('clientes->',this.clientes)
    });
  }
  
  registrar(){
  
    // console.log(this.form.controls.total.setValue('$'+this.form.controls.total.value))
    this.btn_guarda=true;
    const data ={
      id_cliente:this._form('id_cliente'),
      proyecto:this._form('proyecto'),
      fecha_inicio:this._form('fecha_inicio'),
      total:this._form('total').replace(/[$.]/g,''),
      cuotas:this._form('cuotas'),
    };
    
    
    this._proyecto.guardar(data).subscribe(res =>{
      if(res.estado){
        Swal.fire(
          'Proyecto ingresado!',
          '',
          'success'
        )
        this.btn_guarda=false;
        this.construccion_form();
        // alert("Proyecto ingresado");
      }else{
        Swal.fire(
          'Error de ingreso!',
          '',
          'error'
        )
        this.btn_guarda=false;
        // alert("Error de ingreso"); 
        return false;
      }
    }, error=>{
      Swal.fire(
        'Error de ingreso!',
        '',
        'error'
      )
      this.btn_guarda=false;
    });
  }
  _form(name){
    return this.form.get(name).value;
  }

  dibujar_moneda(){
    this.form.valueChanges.subscribe( form => {
      console.log("mi-form=>",form);
      if(form.total){
        this.form.patchValue({
          total: this.DecimalPipe.transform(form.total.replace(/\D/g,'')?.replace(/^0/,''))?.replace(/,/g,'.')
          //minimo: (this.currencyPipe.transform(form.minimo.replace(/\D/g,'').replace(/^0/,''),'USD','symbol','1.0-0').replace("$", "")).toString().replace(/,/g, ".")
        },{emitEvent:false}
        );
      }
    });
  }
  

}
