import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FleteService {

  private apiUrl = environment.url;
  constructor(private http: HttpClient) { }

  guardar_flete(data):Observable<any>{
    var url = `${this.apiUrl}/presupuestos/flete`;
    return this.http.post(url, data);
  }

  get_flete(id):Observable<any>{
    var url = `${this.apiUrl}/presupuestos/flete/${id}`;
    return this.http.get(url);
  }

  guardar_detalle_flete(data):Observable<any>{
    var url = `${this.apiUrl}/presupuestos/flete/detalle`;
    return this.http.post(url, data);
  }
  listar_detalles(id):Observable<any>{
    var url = `${this.apiUrl}/presupuestos/flete/detalle/${id}`;
    return this.http.get(url);
  }

  // control de gastos
  flete_historial_por_proyecto(id):Observable<any>{
    var url = `${this.apiUrl}/proyectos/flete/${id}`;
    return this.http.get(url);
  }
}
