
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})


export class SelectoresService {

  private apiUrl = environment.url;
  constructor(private http: HttpClient) { }

  getProyectos(): Observable<any>  {
    return this.http.get(`${this.apiUrl}/proyectos`);
  }

  getClientes(): Observable<any>{
    var url = `${this.apiUrl}/clientes`;
    return this.http.get(url);
  }
}
