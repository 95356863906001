
<!-- <pre>{{ data_cuenta | json }}</pre> -->
<div class="cp-panel-body">
<form [formGroup]="form">
    <!-- <h4><b>proveedor: {{proveedor.nombre}}</b></h4> -->
    <!-- <hr> -->
    <div class="row">
        <div class="col-12 col-lg-4">
            <label>RUT</label>
            <input formControlName="rut" type="text" class="form-control">
        </div>
        <div class="col-12 col-lg-4">
            <label>NOMBRE</label>
            <input formControlName="nombre" type="text" class="form-control">
        </div>
        <div class="col-12 col-lg-4">
            <label>BANCO</label>
            <!-- {{bancos|json}} -->
            <!-- <input formControlName="banco" type="text" class="form-control"> -->
            <div class="ng-autocomplete">
                <ng-autocomplete formControlName="banco"
                 [data]="datos" [searchKeyword]="keyword"
                 [itemTemplate]="itemTemplate" 
                 [notFoundTemplate]="notFoundTemplate"
                 (selected)='selectEvent($event)'
                 (inputChanged)='onChangeSearch($event)'
                 (inputFocused)='onFocused($event)'
                 >
                </ng-autocomplete>

                <ng-template #itemTemplate let-item>
                    <a [innerHTML]="item.nombre"></a>
                </ng-template>

                <ng-template #notFoundTemplate let-notFound>
                    <div [innerHTML]="notFound"></div>
                </ng-template>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12 col-lg-4">
            <label>CUENTA</label>
            <input formControlName="cuenta" type="text" class="form-control">
        </div>
        <div class="col-12 col-lg-4">
            <label>N DE CUENTA</label>
            <input formControlName="n_cuenta" type="text" class="form-control">
        </div>
        <div class="col-12 col-lg-4">
            <label>CORREO</label>
            <input formControlName="correo" type="text" class="form-control">
        </div>
    </div>

    <div class="row justify-content-center mt-3">
        <div class="col-12 col-md-4">
            <button (click)="update();" class="btn btn-registrar w-100">ACTUALIZAR</button>
        </div>
    </div>
</form>

</div>