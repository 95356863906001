import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ClienteService } from 'src/app/shared/services/cliente.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-actualizar-cliente',
  templateUrl: './actualizar-cliente.component.html',
  styleUrls: ['./actualizar-cliente.component.css']
})
export class ActualizarClienteComponent implements OnInit {

  @Input() input_cliente:any=[];
  @Output() envia = new EventEmitter<Boolean>();
  form_update:FormGroup;
  constructor(
    public fb:FormBuilder,
    public cliente:ClienteService,
    public datepipe: DatePipe
  ) { }

  ngOnInit(): void {
    console.log("@input=>",this.input_cliente)
    this.construir_form();
    this.llenar_form();
  }

  construir_form(){
    this.form_update = this.fb.group({
      id:['', Validators.required],
      n_cliente:['', Validators.required],
      nombres:['', Validators.required],
      apellidos:['', Validators.required],
      n_contacto:['', Validators.required],
      fecha_creacion:['', Validators.required],
      direccion:['', Validators.required]
    });
    this.form_update.controls.n_cliente.disable()
  }
  llenar_form(){
    const i = this.input_cliente;
    this.form_update.setValue({
      id: i.id,
      n_cliente: i.n_cliente,
      nombres: i.nombres,
      apellidos: i.apellidos,
      n_contacto: i.n_contacto,
      fecha_creacion: this.datepipe.transform(i.fecha_creacion, 'yyyy-MM-dd','+0000','es-CL'), 
      direccion: i.direccion,
    });
  }

  update(){
    if(this.form_update.valid){
      this.cliente.update(this.form_update.value).subscribe(res=>{
        if(res.estado){
          Swal.fire({
            title: res.mensaje,
            text: '',
            icon: 'success',
            allowOutsideClick: false,
            
          })
          
          this.construir_form();
          
          this.envia.emit(true);
        }else{

        }
      })
    }else{
      alert("No se puede actualizar el cliente")
    }
  }

}
