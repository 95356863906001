import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { ProveedorService } from 'src/app/shared/services/proveedor.service';

@Component({
  selector: 'app-cuenta-bancaria',
  templateUrl: './cuenta-bancaria.component.html',
  styleUrls: ['./cuenta-bancaria.component.css'],
})
export class CuentaBancariaComponent implements OnInit {
  @Input() proveedor: any = null;
  btn_save: boolean = false;
  modalref = null;
  lista: any;
  form: FormGroup;

  keyword = 'nombre';
  datos: any = [];
  constructor(
    config: NgbModalConfig,
    private modal: NgbModal,
    private fb: FormBuilder,
    private _proveedor: ProveedorService
  ) {
    this.traer_bancos();
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit(): void {
    this.construir_form();
    console.log('prov', this.proveedor);
    this.traer_cuentas_bancarias(this.proveedor.id);
  }

  open_modal(ref, size) {
    this.modalref = this.modal.open(ref, { size: 'lg' });
    return false;
  }
  construir_form() {
    this.form = this.fb.group({
      id_proveedor: [this.proveedor.id, Validators.required],
      rut: [this.proveedor.rut, Validators.required],
      nombre: [this.proveedor.nombre, Validators.required],
      banco: ['', Validators.required],
      cuenta: ['', Validators.required],
      n_cuenta: ['', Validators.required],
      correo: ['', Validators.required],
    });
  }
  _form(campo) {
    return this.form.get(campo).value || null;
  }

  register() {
    this.btn_save = true;
    if (this.form.valid) {
      const data = {
        id_proveedor: this._form('id_proveedor'),
        rut: this._form('rut'),
        nombre: this._form('nombre'),
        banco: this._form('banco').nombre
          ? this._form('banco').nombre
          : this._form('banco'),
        cuenta: this._form('cuenta'),
        n_cuenta: this._form('n_cuenta'),
        correo: this._form('correo'),
      };

      this._proveedor.guardar_cuenta_bancaria(data).subscribe(
        (res) => {
          if (res.estado) {
            this.construir_form();
            Swal.fire('' + res.mensaje + '', '', 'success');
            this.traer_cuentas_bancarias(this.proveedor.id);

            this.btn_save = false;
          } else {
            Swal.fire('Ha ocurrido un error, intente nuevamente', '', 'error');
            this.btn_save = false;
          }
        },
        (error) => {
          Swal.fire('Ha ocurrido un error, intente nuevamente', '', 'error');
        }
      );
    } else {
      Swal.fire('Faltan campos con (*) por llenar', '', 'error');
      this.btn_save = false;
    }
  }

  traer_cuentas_bancarias(proveedor) {
    this._proveedor.traer_cuentas_bancarias(proveedor).subscribe((res) => {
      if (res.estado) {
        this.lista = res.cuentas;
      }
    });
  }

  traer_bancos() {
    this.datos = this._proveedor.traer_bancos();
  }
  selectEvent($event) {
    console.log($event.nombre);
    this.form.controls.banco.setValue('' + $event.nombre);
    console.log(this.form.value);
  }
  onChangeSearch($event) {
    console.log('onChangeSearch', $event);
  }
  onFocused($event) {
    console.log('onFocused', $event);
  }
  open_modal_lg(ref){
    this.modalref = this.modal.open(ref, { size: 'lg' });
    return false;
  }
  recibe(event){
    if(event == 'true'){
      this.traer_cuentas_bancarias(this.proveedor.id);
      this.modalref.close();
    }
  }

  // do something when input is focused
}
