import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'estadoProyecto'
})
export class EstadoProyectoPipe implements PipeTransform {

  transform(value: any): any {

    switch (value) {
      case 1:
        return "Inicial"
      break;
      
      case 2:
        return "En proceso"
      break;

      case 3:
        return "Finalizado"
      break;
      default:
        return "--"
        break;
    }

  }

}
