import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DecimalPipe } from '@angular/common';
import { MaterialesService } from '../../../../shared/services/materiales.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-formulario-materiales',
  templateUrl: './formulario-materiales.component.html',
  styleUrls: ['./formulario-materiales.component.css'],
})
export class FormularioMaterialesComponent implements OnInit {
  @Input() proveedor: any = null;
  @Output() salida = new EventEmitter<string>();
  form: FormGroup;
  constructor(
    private fb: FormBuilder,
    private DecimalPipe: DecimalPipe,
    private _material: MaterialesService
  ) {}

  ngOnInit(): void {
    this.construir_form();
  }

  construir_form() {
    console.log(this.proveedor);
    this.form = this.fb.group({
      id: [this.proveedor.proveedor_empresa.id],
      material: ['', Validators.required],
      precio: ['', Validators.required],
    });
    this.dibujar_moneda();
  }
  dibujar_moneda() {
    this.form.valueChanges.subscribe((form) => {
      console.log('mi-form=>', form);
      if (form.precio) {
        this.form.patchValue(
          {
            precio: this.DecimalPipe.transform(
              form.precio.replace(/\D/g, '')?.replace(/^0/, '')
            )?.replace(/,/g, '.'),
            //minimo: (this.currencyPipe.transform(form.minimo.replace(/\D/g,'').replace(/^0/,''),'USD','symbol','1.0-0').replace("$", "")).toString().replace(/,/g, ".")
          },
          { emitEvent: false }
        );
      }
    });
  }

  register() {
    console.log(this.form.value);
    if (this.form.valid) {
      const data = {
        id_empresa: this._form('id'),
        material: this._form('material'),
        precio: this._form('precio')?.replace(/[$.]/g, ''),
      };
      this._material.guardar_material(data).subscribe((res) => {
        if (res.estado) {
          Swal.fire(''+res.mensaje+'', '', 'success');
          this.construir_form();
          this.salida.emit('true');
          
        }
      });
    }
  }

  _form(campo) {
    return this.form.get(campo).value || null;
  }
}
