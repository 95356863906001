import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../shared/services/auth.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  usuario:any;
  constructor(private router:Router, private auth:AuthService) { }

  ngOnInit(): void {
    this.user()
  }

  salir(){
    localStorage.removeItem('token');
    localStorage.removeItem('usuario');
    this.router.navigate(['']);
  }

  user(){
    const u= this.auth.cargarUsuario();
    this.usuario = u['usuario'].usuario
    console.log('xusuario', this.usuario)
  }

}
