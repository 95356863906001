import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import {ClienteService} from 'src/app/shared/services/cliente.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-formulario-cliente',
  templateUrl: './formulario-cliente.component.html',
  styleUrls: ['./formulario-cliente.component.css']
})
export class FormularioClienteComponent implements OnInit {

  public form: FormGroup;
  tabla:any;
  search:string='';
  btn_guarda:boolean=false;
  constructor(
    private activeModalService: NgbActiveModal,
    private fb: FormBuilder,
    private _cliente:ClienteService,
    private modal:NgbModal,
    config: NgbModalConfig, 
    ) {
      config.backdrop = 'static';
      config.keyboard = false;
     }

  ngOnInit(): void {
    
    this.construccion_form();
  }

  construccion_form(){
    this.form = this.fb.group({
      n_cliente:['', Validators.required],
      nombres:['', Validators.required],
      apellidos:['', Validators.required],
      n_contacto:['', Validators.required],
      fecha_creacion:['', Validators.required],
      direccion:['', Validators.required]
    });
  }

  registrar(){
    this.btn_guarda = true;
    if(this.form.valid){
      console.log(this.form.value);
      this._cliente.guardar(this.form.value).subscribe(res =>{
       if(res.estado){
        // alert("Cliente ingresado")
        Swal.fire(
          'Cliente ingresado!',
          '',
          'success'
        )
        this.btn_guarda = false;
        this.construccion_form();
       }else{
        Swal.fire(
          'Error de ingreso!',
          '',
          'error'
        )
        this.btn_guarda = false;
        // alert("Error de ingreso")
        return false;
       }
       
      }, error=>{
        console.log(error)
        Swal.fire(
          ''+error.error.error,
          '',
          'error'
        )
        this.btn_guarda = false;
        // alert("Error de ingreso")
        return false;
      });
    }else{
      Swal.fire(
        'Error de ingreso!',
        '',
        'error'
      )
      this.btn_guarda = false;
      // alert("Error de ingreso")
      return false;
    }
    
  }

  open_modal(ref,size:string){
    this.activeModalService = this.modal.open(ref, { size: size });
  }

  listar(){
    this._cliente.listar().subscribe(res=>{
      if(res.estado){
        this.tabla = res.clientes;
        console.log(this.tabla)
      }
    });
  }
  filter_cliente(query){
    this._cliente.listar().subscribe(res=>{
      
      if(res.estado){
        let data = res.clientes.filter(function(el) {
          // console.log("filter->",el)
           return (el.n_cliente+' '+el.nombres+' '+el.apellidos).toLowerCase().indexOf(query.toLowerCase()) > -1;
          // console.log(el.item.toLowerCase().indexOf(query.toLowerCase()) > -1);
        })

        console.log(data);
        this.tabla = data;
      }
    })
  }

  recibe(value){
    console.log("recibe->",value)
    this.activeModalService.close();
    this.filter_cliente(this.search);
  }

}
