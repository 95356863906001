import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { CompraService } from 'src/app/shared/services/compra.service';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'app-formulario-compra',
  templateUrl: './formulario-compra.component.html',
  styleUrls: ['./formulario-compra.component.css']
})
export class FormularioCompraComponent implements OnInit {

  @Input() item_txt='';
  @Input() id_detalle='';
  @Input() tipo_presupuesto='';
  @Input() actualiza:any;
  @Output() envia = new EventEmitter<string>();
  btn_ingresa:boolean=false;
  btn_update:boolean=false;
  public _form: FormGroup;
  
  constructor(
    public _compra: CompraService,
    private fb: FormBuilder,
    public DecimalPipe:DecimalPipe
    ) {

      const options = {
        allowOutsideClick: false
    } as SweetAlertOptions;

     }
  ngOnInit(): void {
    console.log("item_txt=>",this.item_txt)
    if(this.tipo_presupuesto==''){
      this.construccion_form();
      // descomentar este metodo para actualizar todos los presupuestos
      this.update_form();
    }else{
      this.construccion_form();
      this.update_form();
    }
    
    this._form.controls.id_detalle.setValue(this.id_detalle);
  }

  construccion_form(){
    this._form = this.fb.group({
      id_detalle:[],
      n_documento:[],
      cantidad:[0],
      valor:[0],
      neto:[''],
      iva:[''],
      observacion:['']
    });
    this.dibujar_moneda();
    // this._form.controls['neto'].disable();
    // this._form.controls.iva.disable();
  }
  update_form(){
    this._form.patchValue({//no es necesario poner [] en los fields
      id_detalle:[this.actualiza?.id],
      n_documento:this.actualiza?.n_documento,
      cantidad:[this.actualiza?.cantidad],
      valor:[this.actualiza?.valor],
      neto:[this.actualiza?.neto],
      iva:[this.actualiza?.iva],
      observacion:this.actualiza?.observacion
    });
    
    this.dibujar_moneda();
  }

  sacar_neto(){
    var cantidad = this._form.controls.cantidad.value;
    var valor = (""+this._form.controls.valor.value)?.replace(/[$.]/g,'');

    this._form.controls.neto.setValue(cantidad * Number(valor));
    var neto = (""+this._form.controls.neto.value)?.replace(/[$.]/g,'');
    console.log(neto+" * 1.19 = "+(Number(neto) * 1.19))
    this._form.controls.iva.setValue( Math.round(Number(neto) * 1.19));
  }

  ingresar(){
    this.btn_ingresa = true;
    const data = {
      id_detalle: this.__form('id_detalle'),
      n_documento: (this.tipo_presupuesto=='')?this.__form('n_documento'):'--',
      cantidad: (this.tipo_presupuesto=='')?this.__form('cantidad'):'0',
      valor: (this.tipo_presupuesto=='')?this.__form('valor')?.replace(/[$.]/g,''):'0',
      neto: (this.tipo_presupuesto=='')?this.__form('neto')?.replace(/[$.]/g,''):'0',
      iva: this.__form('iva')?.replace(/[$.]/g,''),
      observacion: this.__form('observacion'),
    };

    this._compra.guardar_compra(data).subscribe(res=>{
      if(res.estado){
        Swal.fire({
          title: 'Compra ingresada a este item!',
          text: '',
          icon: 'success',
          allowOutsideClick: false,
        })
        this.btn_ingresa = false;
        this.construccion_form();
        
        this.envia.emit("verdadero");
      }

      if(!res.estado){
        Swal.fire({
          icon: 'error',
          title: '',
          text: '',
          allowOutsideClick: false,
          
        })
        this.btn_ingresa = false;
        this.envia.emit("falso");
      }
    },(error)=>{
      
      Swal.fire({
        icon: 'error',
        title: 'Algo salió mal, posiblemente ya exista una compra en este item!',
        text: '',
        allowOutsideClick: false,
        
      })
      this.btn_ingresa = false;
      this.envia.emit("falso");
      
    });
  }

  actualizar(){
    this.btn_update = true;
    const data = {
      id: this.actualiza?.id,
      id_detalle: this.__form('id_detalle'),
      n_documento: (this.tipo_presupuesto=='')?this.__form('n_documento'):'--',
      cantidad: (this.tipo_presupuesto=='')?this.__form('cantidad'):'0',
      valor: (this.tipo_presupuesto=='')?this.__form('valor')?.replace(/[$.]/g,''):'0',
      neto: (this.tipo_presupuesto=='')?this.__form('neto')?.replace(/[$.]/g,''):'0',
      iva: this.__form('iva')?.replace(/[$.]/g,''),
      observacion: this.__form('observacion'),
    };
    
    this._compra.actualizar_compra(data).subscribe(res=>{
      if(res.estado){
        Swal.fire({
          title: 'Compra actualizada a este item!',
          text: '',
          icon: 'success',
          allowOutsideClick: false,
          
        })
        this.btn_update = false;
        this.construccion_form();
        console.log("v")
        this.envia.emit("verdadero");
      }

      if(!res.estado){
        Swal.fire({
          icon: 'error',
          title: '',
          text: '',
          allowOutsideClick: false,
          
        })
        this.btn_update = false;
        this.envia.emit("falso");
      }
    },(error)=>{
      
      Swal.fire({
        icon: 'error',
        title: 'Algo salió mal, posiblemente ya exista una compra en este item!',
        text: '',
        allowOutsideClick: false,
        
      })

      this.btn_update = false;
      this.envia.emit("falso");
      
      
    });
  }

  dibujar_moneda(){
    this._form.valueChanges.subscribe( form => {
      console.log("mi-form=>",form);
      if(form.valor){
        var valor =""+form.valor;
        this._form.patchValue({
          valor: this.DecimalPipe.transform(valor.replace(/\D/g,'')?.replace(/^0/,''))?.replace(/,/g,'.'),
          
        },{emitEvent:false}
        );
      }

      if(form.neto){
        var neto =""+form.neto;
        console.log("ttottal->",neto)
        this._form.patchValue({
          
           neto: this.DecimalPipe.transform(neto.replace(/\D/g,'')?.replace(/^0/,''))?.replace(/,/g,'.')
        },{emitEvent:false}
        );
      }

      if(form.iva){
        var iva =""+form.iva;
        console.log("ttottal->",iva)
        this._form.patchValue({
          
           iva: this.DecimalPipe.transform(iva.replace(/\D/g,'')?.replace(/^0/,''))?.replace(/,/g,'.')
        },{emitEvent:false}
        );
      }
    });
  }

  __form(name){
    return this._form.get(name).value;
  }
  
}
