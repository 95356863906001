import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { ProveedorService } from '../../../../shared/services/proveedor.service';

@Component({
  selector: 'app-editar-cuenta-bancario',
  templateUrl: './editar-cuenta-bancario.component.html',
  styleUrls: ['./editar-cuenta-bancario.component.css']
})
export class EditarCuentaBancarioComponent implements OnInit {

  form:FormGroup;
  keyword = 'nombre';
  datos: any = [];

  data_cuenta:any;
  @Input() set cuenta(data:any){
    if(data){
      console.log('data',data)
      this.data_cuenta = data;
    }
  }
  @Output() salida = new EventEmitter<string>();
  constructor(private fb:FormBuilder, private _proveedor:ProveedorService) { 
    this.traer_bancos();
  }

  ngOnInit(): void {
    console.log('data',this.data_cuenta)
    this.construir_form();
  }
  construir_form() {
    this.form = this.fb.group({
      id: [this.data_cuenta.id, Validators.required],
      rut: [this.data_cuenta.rut, Validators.required],
      nombre: [this.data_cuenta.nombre, Validators.required],
      banco: [this.data_cuenta.banco, Validators.required],
      cuenta: [this.data_cuenta.cuenta, Validators.required],
      n_cuenta: [this.data_cuenta.n_cuenta, Validators.required],
      correo: [this.data_cuenta.correo, Validators.required],
    });
  }
  _form(campo) {
    return this.form.get(campo).value || null;
  }
  traer_bancos() {
    this.datos = this._proveedor.traer_bancos();
  }
  selectEvent($event) {
    console.log($event.nombre);
    this.form.controls.banco.setValue('' + $event.nombre);
    console.log(this.form.value);
  }
  onChangeSearch($event) {
    console.log('onChangeSearch', $event);
  }
  onFocused($event) {
    console.log('onFocused', $event);
  }
  update(){
   
    const data = {
      id: this._form('id'),
      rut: this._form('rut'),
      nombre: this._form('nombre'),
      banco: this._form('banco').nombre
        ? this._form('banco').nombre
        : this._form('banco'),
      cuenta: this._form('cuenta'),
      n_cuenta: this._form('n_cuenta'),
      correo: this._form('correo'),
    };
    this._proveedor.actualizar_cuenta(data).subscribe(res=>{
      if(res.estado){
        Swal.fire(
          ''+res.mensaje+'',
          '',
          'success'
        );
        this.salida.emit('true');
      }else{
        Swal.fire(
          'Faltan campos con (*) por llenar',
          '',
          'error'
        );
      }
    }, error =>{
      Swal.fire(
        'Faltan campos con (*) por llenar',
        '',
        'error'
      );
    });
  }

}
