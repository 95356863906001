<app-navbar></app-navbar>

<div class="container mt-4">
  <div class="card salmon-outline">

    <div class="card-header cp-card-header">
      <div align="center">FORMULARIO DE PROVEEDORES</div>
    </div>

    <div class="card-body">
      <form [formGroup]="form">
        <div class="row">
          <div class="col-md-12">
            <label for="">PROVEEDOR</label>
            <ng-select formControlName="tipo" (change)="changeProveedor($event)" placeholder="seleccione"
              [searchable]="true">
              <section *ngFor="let i of proveedores;">
                <ng-option value="{{i.id}}">
                  {{ i.name }}
                </ng-option>
              </section>
            </ng-select>

          </div>

        </div>

        <div *ngIf="proveedor == 1">
          <div class="row mt-2">

            <div class="col-md-4">
              <label for="">rut de empresa*</label>
              <input formControlName='rut' class="form-control" type="text">
            </div>

            <div class="col-md-4">
              <label for="">Nombre de empresa*</label>
              <input formControlName='nombre' class="form-control" type="text">
            </div>
            <div class="col-md-4">
              <label for="">Giro</label>
              <input formControlName='giro' class="form-control" type="text">
            </div>
          </div>
          <div class="row mt-2">

            <div class="col-md-4">
              <label for="">contacto*</label>
              <input formControlName='contacto' class="form-control" type="text">
            </div>
            <div class="col-md-8">
              <label for="">direccion*</label>
              <input formControlName='direccion' class="form-control" type="text">
            </div>

          </div>
          <div class="row mt-2">
            <div class="col-md-6">
              <label>horario</label>
              <input formControlName='horario' class="form-control" type="text">
            </div>
            <div class="col-md-6">
              <label for="">vendedor</label>
              <input formControlName='vendedor' class="form-control" type="text">
            </div>
          </div>
        </div>

        <div *ngIf="proveedor == 2">
          <div class="row mt-2">

            <div class="col-md-4">
              <label for="">rut del conductor</label>
              <input formControlName='rut' class="form-control" type="text">
            </div>

            <div class="col-md-8">
              <label for="">Nombre del conductor*</label>
              <input formControlName='nombre' class="form-control" type="text">
            </div>
          </div>
          <div class="row mt-2">

            <div class="col-md-4">
              <label for="">contacto*</label>
              <input formControlName='contacto' class="form-control" type="text">
            </div>
            <div class="col-md-8">
              <label for="">direccion*</label>
              <input formControlName='direccion' class="form-control" type="text">
            </div>

          </div>
          <div class="row mt-2">
            <div class="col-md-4">
              <label>tipo de vehiculo</label>
              <input formControlName='vehiculo' class="form-control" type="text">
            </div>
            <div class="col-md-4">
              <label for="">capacidad</label>
              <input formControlName='capacidad' class="form-control" type="text">
            </div>
            <div class="col-md-4">
              <label for="">tarifa</label>
              <input formControlName='tarifa' class="form-control" type="text">
            </div>
          </div>
        </div>

        <div *ngIf="proveedor == 3">
          <div class="row mt-2">

            <div class="col-md-4">
              <label for="">rut de maestro</label>
              <input formControlName='rut' class="form-control" type="text">
            </div>

            <div class="col-md-8">
              <label for="">Nombre de maestro*</label>
              <input formControlName='nombre' class="form-control" type="text">
            </div>
          </div>
          <div class="row mt-2">

            <div class="col-md-4">
              <label for="">contacto*</label>
              <input formControlName='contacto' class="form-control" type="text">
            </div>
            <div class="col-md-8">
              <label for="">direccion*</label>
              <input formControlName='direccion' class="form-control" type="text">
            </div>

          </div>
          <div class="row mt-2">
            <div class="col-md-12">
              <label>especialidad</label>
              <input formControlName='especialidad' class="form-control" type="text">
            </div>
            <div class="col-md-12">
              <label for="">observacion</label>
              <textarea formControlName='observacion' class="form-control" cols="30" rows="5"></textarea>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="card-footer">
      <div class="row">
        <div class="col-12 col-md-6 mt-2">
          <button (click)="register();" class="btn btn-registrar btn-block">Registar</button>
        </div>
        <div class="col-12 col-md-6 mt-2">
          <button class="btn btn-visualizar btn-block" [routerLink]="['/auth/lista-proveedor']"><i
              class="fas fa-list"></i> VER PROVEEDORES</button>
        </div>
      </div>
    </div>

  </div>
</div>


<app-footer></app-footer>