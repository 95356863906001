
<!-- <pre>{{prov|json}}</pre> -->
<div class="cp-panel-body">
<form [formGroup]="form">
    
<div class="row">
    <div class="col-12 col-md-6">
        <label>RUT</label>
        <input type="text" formControlName="rut" class="form-control">
    </div>
    <div class="col-12 col-md-6">
        <label>NOMBRE</label>
        <input type="text" formControlName="nombre" class="form-control">
    </div>
</div>
<div class="row">
    <div class="col-12 col-md-6">
        <label>CONTACTO</label>
        <input type="text" formControlName="contacto" class="form-control">
    </div>
    <div class="col-12 col-md-6">
        <label>DIRECCION</label>
        <input type="text" formControlName="direccion" class="form-control">
    </div>
</div>
<div *ngIf="prov.tipo==1">
    <div class="row">
        <div class="col-12 col-md-6">
            <label>HORARIO</label>
            <input type="text" formControlName="horario" class="form-control">
        </div>
        <div class="col-12 col-md-6">
            <label>VENDEDOR</label>
            <input type="text" formControlName="vendedor" class="form-control">
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-md-12">
            <label>GIRO</label>
            <input type="text" formControlName="giro" class="form-control">
        </div>
    </div>
</div>

<div *ngIf="prov.tipo==2">
    <div class="row">
        <div class="col-12 col-md-4">
            <label>VEHICULO</label>
            <input type="text" formControlName="vehiculo" class="form-control">
        </div>
        <div class="col-12 col-md-4">
            <label>CAPACIDAD</label>
            <input type="text" formControlName="capacidad" class="form-control">
        </div>
        <div class="col-12 col-md-4">
            <label>TARIFA</label>
            <input type="text" formControlName="tarifa" class="form-control">
        </div>
    </div>
</div>

<div *ngIf="prov.tipo==3">
    <div class="row">
        <div class="col-12 col-md-6">
            <label>ESPECIALIDAD</label>
            <input type="text" formControlName="especialidad" class="form-control">
        </div>
        
    </div>
</div>
<hr>
<div class="row justify-content-center">
    <div class="col-md-3">
        <button class="btn btn-registrar w-100" (click)="update();">ACTUALIZAR</button>
    </div>
</div>


</form>
</div>