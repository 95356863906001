<div>
    <!-- <pre>{{ $material | json}}</pre> -->
    <div class="cp-panel-head mt-3" align="center">MATERIAL</div>
    <div class="cp-panel-body">
        <div class="row justify-content-center">
            <div class="col-12 col-md-12">
                {{$material.material }}
            </div>
        </div>
    </div>

    <div class="cp-panel-head mt-3" align="center">HISTORIAL</div>
    <div class="cp-panel-body">
        <table class="table table-bordered">
            <thead class="thead-dark text-center">
                <tr class="table-primary">
                    <th>FECHA</th>
                    <th>PRECIO</th>
                </tr>
            </thead>
            <tbody>
                <tr class="text-center" *ngFor="let i of lista;">
                    <td>{{ i.fecha | date:'dd/MM/yyyy':'+0000':'es-CL'}}</td>
                    <td>{{ money_clp(i.precio) }}</td>
                </tr>
        </table>
    </div>
</div>
