import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth.service'

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private auth: AuthService, 
    private router: Router){

  }

  canActivate(): boolean {
    if (!this.auth.isAuthenticated()) {
      console.log("activo", false)
      this.router.navigate(['']);
      // return false;
    }
    console.log("activo", true)
    return true;
  }
  
}
