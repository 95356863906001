import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Usuario } from 'src/app/shared/models/usuario.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {


  showPasswordLogin: boolean = false;
  fecha: Date = new Date();
  loginForm: FormGroup;
  // loadingLogin=false;
  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.crearLoginFormulario();
  }

  crearLoginFormulario() {
    this.loginForm = this.formBuilder.group({
      email: [
        '',
        [
          Validators.required,
          Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$'),
        ],
      ],
      password: ['', [Validators.required, Validators.min(6)]],
    });
  }

  login() {
    if (this.loginForm.invalid) {
      return Swal.fire(
        '¡Advertencia!',
        'Complete todos los campos.',
        'warning'
      );
    } else {
      // this.loadingLogin = true;
      const usuario: Usuario = new Usuario();
      const { email, password } = this.loginForm.value;
      usuario.email = email;
      usuario.password = password;
      this.authService.login(usuario).subscribe(
        (res) => {
          if (res.estado) {
            return Swal.fire({
              title: 'Ingreso correcto',
              text: 'Se redigira automaticamente.',
              icon: 'success',
              showConfirmButton: true,
            }).then((act) => {
              if (act.value) {
                this.authService.guardarUsuario(
                  res
                  ,
                  this.authService.estadosUsuario(2)
                );
                this.router.navigate(['/auth/home']);
              }
            });
          }
        })
    }
  }

}
