import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PresupuestoService {

  private apiUrl = environment.url;
  constructor(private http: HttpClient) { }

  guardar_presupuesto(data):Observable<any>{
    var url = `${this.apiUrl}/presupuestos`;
    return this.http.post(url, data);
  }

  traer_presupuesto_por_proyecto(id):Observable<any>{
    var url = `${this.apiUrl}/presupuestos/proyecto/${id}`;
    return this.http.get(url);
  }

  guardar_detalle_presupuesto(data):Observable<any>{
    var url = `${this.apiUrl}/presupuestos/detalle`;
    return this.http.post(url, data);
  }
  actualizar_detalle_presupuesto(data:any):Observable<any>{
    var url = `${this.apiUrl}/presupuestos/detalle`;
    return this.http.put(url, data);
  }
  listar_detalle_presupuesto(id):Observable<any>{
    var url = `${this.apiUrl}/presupuestos/detalle/${id}`;
    return this.http.get(url);
  }

  compras_por_proyecto(id):Observable<any>{
    var url = `${this.apiUrl}/proyectos/compra/${id}`;
    return this.http.get(url);
  }

  mano_de_obras_proyecto(id):Observable<any>{
    var url = `${this.apiUrl}/proyectos/mano-obra/${id}`;
    return this.http.get(url);
  }

}
