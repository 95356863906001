<app-navbar></app-navbar>

<div class="container-fluid mt-4">
  <div class="card salmon-outline">

    <div class="card-header cp-card-header">
      <div class="row">
        <div class="col-4 col-md-2"><a [routerLink]="['/auth/home']"><i class="fas fa-arrow-left"></i>
            Volver</a>
        </div>
        <div class="col-7 col-md-8 text-center font-weight-bold">LISTA DE GASTOS GENERALES</div>
      </div>
    </div>

    <div class="card-body">
      <div class="row">
        <!-- <div class="col-md-3 mt-1">
          <label>GASTO</label>
          <input type="text" class="form-control" placeholder="BUSCAR..." aria-label="Recipient's username"
            aria-describedby="button-addon2">
        </div> -->
        <div class="col-md-12 mt-1">
          <label>PROYECTO*</label>
          <ng-select [(ngModel)]="ngproyecto" #selectProyecto placeholder="SELECCIONE" (change)="change_proyecto()" [searchable]="true" [clearable]="true">
            <section>
              <ng-option value="0">TODOS LOS PROYECTOS</ng-option>
              <ng-option *ngFor="let i of proyectos" value="{{i.id}}">{{i.proyecto}} - {{'Nº'+i.cliente.n_cliente+' - '+i.cliente.nombre_completo}}</ng-option>
            </section>
          </ng-select>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-md-3 mt-1">
          <label>DESDE*</label>
          <input [(ngModel)]="desde" type="date" class="form-control">
        </div>
        <div class="col-md-3 mt-1">
          <label>HASTA*</label>
          <input [(ngModel)]="hasta" type="date" class="form-control">
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-md-3 mt-1">
          <label>&nbsp;</label>
          <button [disabled]="btn_busca" (click)="filtrar()" class="btn btn-registrar w-100"><i class="fas fa-search"></i> BUSCAR</button>
        </div>
        <div class="col-md-3 mt-1">
          <label>&nbsp;</label>
          <button (click)="limpiar_tablas();" class="btn btn-registrar w-100">
            <i class="fas fa-sync-alt"></i> LIMPIAR
          </button>
        </div>
        <div class="col-md-3 mt-1" *ngIf="ver">
          <label>&nbsp;</label>
          <button class="btn btn-registrar w-100" onclick="printJS({
            printable: 'printJS-table',
            type:'html',
            style: '.table {font-family: Arial, Helvetica, sans-serif; text-align: center;} .table{width:100%} .table td, .table th {border: 1px solid #020202; padding: 5px; color:black; font-size: 13px;}'
          })">
            <i class="fas fa-print"></i> Imprimir Datos
          </button>
          
        </div>
      </div>
      
      
    </div>
  </div>


  
  <div *ngIf="ver" class="card mt-2" id="printJS-table">
    <div class="card-body">
      <div class="row justify-content-center">
        <div class="col-md-8">
          <table *ngIf="tabla_resumen" class="table table-bordered">
            <thead class="thead-dark text-center">
              <tr class="table-primary">
                <th style="background: #343a40; color: white;" colspan="6">RESUMEN</th>
              </tr>
            </thead>
            <thead class="thead-dark text-center">
              <tr class="table-primary">
                <th style="background: #343a40; color: white;"><i class="fas fa-hammer"></i> PROYECTO</th>
                <th style="background: #343a40; color: white; width: 20%;"><i class="fas fa-calendar-week"></i> DESDE</th>
                <th style="background: #343a40; color: white; width: 20%;"><i class="fas fa-calendar-week"></i> HASTA</th>
              </tr>
            </thead>
            <tbody>
              <tr class="text-center">
                <td>{{txt_proyecto(selectProyecto)}}</td>
                <td>{{desde | date:'dd/MM/yyyy':'+0000':'es-CL'}}</td>
                <td>{{hasta | date:'dd/MM/yyyy':'+0000':'es-CL'}}</td>
              </tr>
            </tbody>
            <thead class="thead-dark text-center">
              <tr class="table-primary">
                <th style="background: #343a40; color: white;"><i class="fas fa-plus-circle"></i> INGRESOS</th>
                <th style="background: #343a40; color: white;"><i class="fas fa-minus-circle"></i> COSTOS GENERALES</th>
                <th style="background: #343a40; color: white;"><i class="fas fa-equals"></i> TOTAL</th>
              </tr>
            </thead>
            <tbody>
              <tr class="text-center">
                <td>{{money_clp(total_cob)}}</td>
                <td>{{ money_clp(total_form + total_pro + total_comi + total_fle)}}</td>
                <td>{{ money_clp(total_cob - (total_form + total_pro + total_comi + total_fle))}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <br>
        <div class="table-responsive">
          <table *ngFor="let c of tabla" class="table table-bordered">
  
            <thead class="thead-dark text-center">
              <tr class="table-primary">
                <th style="background: #343a40; color: white;" colspan="6">{{c.categoria}}</th>
              </tr>
              <tr class="table-primary">
                <th style="width: 10%; background: #343a40; color: white;">FECHA</th>
                <th style="width: 10%; background: #343a40; color: white;">SUB-CATEGORIA</th>
                <th style="width: 5%; background: #343a40; color: white;">PDF</th>
                <th style="width: 10%; background: #343a40; color: white;">DESC</th>
                <th style="width: 10%; background: #343a40; color: white;"><i class="fas fa-minus-circle"></i> MONTO</th>
                <th style="width: 10%; background: #343a40; color: white;">OPCION</th>
              </tr>
            </thead>
  
            <tbody *ngFor="let g of c.gastos">
              <tr class="text-center">
                <td><b>{{g?.fecha | date:'dd/MM/yyyy':'+0000':'es-CL'}}</b></td>
                <td><b>{{g.sub_categoria}}</b></td>
                <td>
                  <button (click)="traer_documento(g.documento);open_modal(visor)" class="btn btn-primary">
                    <i class="fas fa-file"></i>
                  </button>
  
                </td>
                <td>{{g.desc}}</td>
                <td>{{money_clp(g.monto)}}</td>
                <td>
                  <button (click)="open_modal_editar(editar);__gasto=g" class="btn btn-editar tooltipCustom top mx-1">
                    <i class="fas fa-edit"></i>
                    <span class="tiptext">Editar gasto</span>
                  </button>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr class="text-center">
                <th style="background: #343a40; color: white;" class="text-right" colspan="4">TOTAL</th>
                <td *ngIf="(c.categoria=='TRANSPORTE')"><b>{{money_clp(monto_total_transporte)}}</b></td>
                <td *ngIf="(c.categoria=='COMPRAS')"><b>{{money_clp(monto_total_compras)}}</b></td>
                <td *ngIf="(c.categoria=='GASTOS FIJOS')"><b>{{money_clp(monto_total_gf)}}</b></td>
                <td></td>
              </tr>
            </tfoot>

          </table>
          <table *ngIf="tabla" class="table table-bordered">
            <thead class="thead-dark text-center">
              <tr>
                <th style="background: #343a40; color: white;" class="text-right" colspan="4"><i class="fas fa-minus-circle"></i> TOTAL</th>
                <td style="background: white;" colspan="2"><b>{{money_clp(total_form) }}</b></td>
              </tr>
            </thead>
          </table>
          
          <br>
          <div *ngIf="count_pro > 0">
            <table class="table table-bordered">
              <thead class="thead-dark text-center">
                <tr class="table-secondary">
                  <th style="background: #343a40; color: white;" colspan="4">PRESUPUESTOS</th>
                </tr>
                <tr class="table-secondary">
                  <th style="width: 30%;background: #343a40; color: white;" >PROYECTO</th>
                  <th style="width: 20%;background: #343a40; color: white;">TIPO</th>
                  <th style="background: #343a40; color: white;">TITULO</th>
                  <th style="background: #343a40; color: white;"><i class="fas fa-minus-circle"></i> MONTO</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let i of tabla_proyectos" class="text-center">
                  <td>{{ i.proyecto }}</td>
                  <td>{{ i.tipo }}</td>
                  <td>{{ i.titulo }}</td>
                  <td>{{ money_clp(i.total) }}</td>
                </tr>
              </tbody>
              <tbody class="text-center">
                <tr class="thead-dark">
                  <th style="background: #343a40; color: white;" class="text-right" colspan="3"><i class="fas fa-minus-circle"></i> TOTAL</th>
                  <td style="background: white;">
                    <b>{{money_clp(total_pro)}}</b>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <br>
          <div *ngIf="count_comision > 0">
            <table class="table table-bordered">
              <thead class="thead-dark text-center">
                <tr class="table-secondary">
                  <th style="background: #343a40; color: white;" colspan="4">COMISIONES</th>
                </tr>
                <tr class="table-secondary">
                  <th style="width: 30%;background: #343a40; color: white;" >PROYECTO</th>
                  
                  <th style="background: #343a40; color: white;">TITULO</th>
                  <th style="background: #343a40; color: white;"><i class="fas fa-minus-circle"></i> MONTO</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let i of tabla_comision" class="text-center">
                  <td>{{ i.proyecto }}</td>
                 
                  <td>{{ i.titulo }}</td>
                  <td>{{ money_clp(i.total) }}</td>
                </tr>
              </tbody>
              <tbody class="text-center">
                <tr class="thead-dark">
                  <th style="background: #343a40; color: white;" class="text-right" colspan="2"><i class="fas fa-minus-circle"></i> TOTAL</th>
                  <td style="background: white;">
                    <b>{{money_clp(total_comi)}}</b>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <br>
          <div *ngIf="count_flete > 0">
            <table class="table table-bordered">
              <thead class="text-center">
                <tr class="thead-dark">
                  <th style="background: #343a40; color: white;" colspan="4">FLETES</th>
                </tr>
                <tr class="thead-dark">
                  <th style="width: 30%;background: #343a40; color: white;" >PROYECTO</th>
                  
                  <th style="background: #343a40; color: white;">TITULO</th>
                  <th style="background: #343a40; color: white;"><i class="fas fa-minus-circle"></i> MONTO</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let i of tabla_flete" class="text-center">
                  <td>{{ i.proyecto }}</td>
                 
                  <td>{{ i.titulo }}</td>
                  <td>{{ money_clp(i.total) }}</td>
                </tr>
              </tbody>
              <tbody class="text-center">
                <tr class="thead-dark">
                  <th style="background: #343a40; color: white;" class="text-right" colspan="2"><i class="fas fa-minus-circle"></i> TOTAL</th>
                  <td style="background: white;"><b>{{money_clp(total_fle)}}</b></td>
                </tr>
              </tbody>
            </table>
          </div>
          <br>
          <div *ngIf="count_pago > 0">
            <table class="table table-bordered">
              <thead class="text-center">
                <tr class="thead-dark">
                  <th style="background: #343a40; color: white;" colspan="4">COBROS</th>
                </tr>
                <tr class="thead-dark">
                  <th style="background: #343a40; color: white;" >PROYECTO</th>
                  <th><i class="fas fa-plus-circle"></i> MONTO</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let i of tabla_pago" class="text-center">
                  <td>{{ i.proyecto }}</td>
                  <td>{{ money_clp(i.total) }}</td>
                </tr>
              </tbody>
              <tbody class="text-center">
                <tr class="thead-dark">
                  <th class="text-right" style="background: #343a40; color: white;"><i class="fas fa-plus-circle"></i> TOTAL</th>
                  <td style="background: white;"><b>{{money_clp(total_cob)}}</b></td>
                </tr>
              </tbody>
            </table>
          </div>

          
  
  
          <!--MODAL VISOR PDF-->
  <ng-template #visor let-c="close" let-d="dismiss">
    <div class="row">
      <div class="col">
        <div class="modal-header text-center" id="demoFont">
          <h4 class="modal-title" id="modal-basic-title">Visor de PDF</h4>
          <button id="closeModalButton" type="button" class="close" aria-label="Close"
            (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
  
            <iframe id="iframex" width="100%" height="500px"
            frameborder="0" allowfullscreen></iframe>
        </div>
        <div class="modal-footer" [hidden]="true">
          <button type="button" class="btn btn-outline-dark" (click)="c('Save click')">OK</button>
        </div>
      </div>
    </div>
  </ng-template>
  
  
          <ng-template #editar let-c="close" let-d="dismiss">
                    <div class="row">
                      <div class="col">
                        <div class="cp-modal-header modal-header text-center" id="demoFont">
                          <h4 class="modal-title" id="modal-basic-title">EDITAR</h4>
                          <button id="closeModalButton" type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div class="modal-body">
                          
                          <app-editar-gastos
                          [gasto]="__gasto"
                          (salida)="recibe($event)"
                          >
  
                          </app-editar-gastos>
                        </div>
                      </div>
                    </div>
          </ng-template>
        </div>
    </div>
  </div>
</div>


<app-footer></app-footer>
