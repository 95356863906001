<div class="cp-panel-head">
    <div align="center">
      
      {{(tipo_presupuesto=='')?'Detalle de compra por item':'Detalle de pago mano de obra'}}
  
    </div>
  </div>
  <div *ngIf="compra!=null" class="cp-panel-body">
      <!-- <pre>{{  compra | json }}</pre> -->
      <div *ngIf="tipo_presupuesto==''">
        <label><b>Nº documento: </b>{{ compra?.n_documento }}</label><br>
        <label><b>Cantidad: </b>{{ compra?.cantidad }}</label><br>
        <label><b>valor: </b>{{ money_clp(compra?.valor) }}</label><br>
        <label><b>neto: </b>{{ money_clp(compra?.neto) }}</label><br>
        <label><b>i.v.a: </b>{{ money_clp(compra?.iva) }}</label><br>
        <label><b>Observacion: </b>
          <textarea style="background-color: white;" [disabled]="true" class="form-control" cols="60" rows="10">{{ compra?.observacion }}</textarea>
        </label><br>
      </div>

      <div *ngIf="tipo_presupuesto=='mano_obra'">
      <label><b>Monto pago: </b>{{ money_clp(compra?.iva) }}</label><br>
      <label><b>Observacion: </b>
       <!-- <pre><samp> {{ compra?.observacion.trim() }} </samp></pre> -->
       <textarea style="background-color: white;" [disabled]="true" class="form-control" cols="60" rows="10">{{ compra?.observacion }}</textarea>
       
      </label><br>
      </div>
  </div>