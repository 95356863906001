<app-navbar></app-navbar>

<div class="container">
    <div class="card my-4">

        <div class="card-header cp-card-header">
            <div class="text-center">FORMULARIO DE CLIENTES</div>
        </div>

        <div class="card-body">
           
            <form [formGroup]="form">
                <div class="row">
                    <div class="col-md-6">
                        <label  for="">Nª Cliente</label>
                        <input formControlName="n_cliente" type="text" class="form-control">
                    </div>
                    <div class="col-md-6">
                        <label  for="">Fecha de ingreso</label>
                        <input formControlName="fecha_creacion" type="date" class="form-control">
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col-md-6">
                        <label  for="">Nombres</label>
                        <input formControlName="nombres" type="text" class="form-control">
                    </div>
                    <div class="col-md-6">
                        <label  for="">Apellidos</label>
                        <input formControlName="apellidos" type="text" class="form-control">
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col-md-6">
                        <label  for="">Contacto</label>
                        <input formControlName="n_contacto" type="text" class="form-control">
                    </div>
                    <div class="col-md-6">
                        <label  for="">Dirección</label>
                        <input formControlName="direccion" type="text" class="form-control">
                    </div>
                </div>


            </form>
        </div>

        <div class="card-footer">
            <div class="row">
              <div class="col-12 col-md-6 mt-2">
                <button [disabled]="btn_guarda" (click)="registrar();" class="btn btn-registrar btn-block"><i class="fas fa-user-plus"></i> Registrar</button>
              </div>
              <div class="col-12 col-md-6 mt-2">
                  <button (click)="open_modal(lista,'xl'); listar();" class="btn btn-visualizar btn-block"><i class="fas fa-list"></i> ver clientes</button>
              </div>
            </div>
        </div>
    </div>

    <ng-template #lista let-c="close" let-d="dismiss">
        <div class="row">
            <div class="col">
                <div  class="cp-modal-header modal-header text-center" id="demoFont">
                    <h4 class="modal-title" id="modal-basic-title">Lista de clientes</h4>
                    <button id="closeModalButton" type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    
                    <div class="row">
                        <div class="col-md-10">
                          <input type="text" class="form-control" [(ngModel)]="search" (keydown.enter)="filter_cliente  (search)"
                            placeholder="buscar por nombre o numero cliente..">    
                        </div>
                        <div class="col-md-1">
                          <button class="btn btn-light" (click)="listar(); search='';">
                            <i class="fas fa-sync-alt"></i>
                          </button>
                        </div>
                      </div>
                    <br>
                    <div class="table-responsive">
                        <table class="table table-bordered">
                          <thead class="thead-dark text-center">
                            <tr class="table-primary">
                                <th>Nº</th>
                                <th>Apellidos</th>
                                <th>Nombres</th>
                                <th>Fecha creación</th>
                                <th>Contacto</th>
                                <th>Dirección</th>
                                <th>Opción</th>
                            </tr>
                          </thead>
                            <tr *ngFor="let t of tabla">
                                <td>{{ t.n_cliente }}</td>
                                <td>{{ t.apellidos }}</td>
                                <td>{{ t.nombres }}</td>
                                <td>{{ t.fecha_creacion  | date:'dd/MM/yyyy':'+0000':'es-CL'}}</td>
                                <td>{{ t.n_contacto }}</td>
                                <td>{{ t.direccion }}</td>
                                <td>
                                    <button (click)="update=t;open_modal(editar,'md')" class="btn btn-editar tooltipCustom top mx-1">
                                        <i class="fas fa-edit"></i>
                                    </button>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div class="modal-footer" [hidden]="true">
                    <button type="button" class="btn btn-outline-dark" (click)="c('Save click')">OK</button>
                </div>
            </div>
        </div>
    </ng-template>


    <!-- update -->
    <ng-template #editar let-c="close" let-d="dismiss">
        <div class="row">
            <div class="col">
                <div  class="cp-modal-header modal-header text-center" id="demoFont">
                    <h4 class="modal-title" id="modal-basic-title">Actualizar cliente</h4>
                    <button id="closeModalButton" type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <app-actualizar-cliente
                        [input_cliente]="update"
                        (envia)="recibe($event)"
                    >

                    </app-actualizar-cliente>
                </div>
            </div>
        </div>
    </ng-template>
</div>

<app-footer></app-footer>
