<app-navbar></app-navbar>

<div class="container-fluid">
  <div class="row mt-4">
    <div class="col-12">
      <div class="card py-5">

        <div class="row mx-2 justify-content-center">
          <div class="col-12 col-md-4 col-lg-4 my-2">
            <div class="card card-individual pointer orange-background text-white"
              [routerLink]="['/auth/formulario-cliente']">
              <div class="card-body">
                <h3 class="text-center">
                  <i class="fas fa-users"></i> Clientes
                </h3>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-4 col-lg-4 my-2">
            <div class="card card-individual pointer orange-background text-white"
              [routerLink]="['/auth/formulario-proyecto']">
              <div class="card-body">
                <h3 class="text-center">
                  <i class="fas fa-hammer"></i> proyecto
                </h3>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-4 col-lg-4 my-2">
            <div class="card card-individual pointer orange-background text-white"
              [routerLink]="['/auth/formulario-presupuesto']">
              <div class="card-body">
                <h3 class="text-center">
                  <i class="fas fa-dollar-sign"></i> Presupuestos
                </h3>
              </div>
            </div>
          </div>

        </div>

        <div class="row mx-2 justify-content-center">
          <div class="col-12 col-md-4 col-lg-4 my-2">
            <div class="card card-individual pointer orange-background text-white"
              [routerLink]="['/auth/formulario-proveedor']">
              <div class="card-body">
                <h3 class="text-center">
                  <i class="fas fa-warehouse"></i> Proveedores
                </h3>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-4 col-lg-4 my-2">
            <div class="card card-individual pointer orange-background text-white"
              [routerLink]="['/auth/lista-materiales']">
              <div class="card-body">
                <h3 class="text-center">
                  <i class="fas fa-tools"></i> Materiales
                </h3>
              </div>
            </div>
          </div>

          <div class="col-10 col-md-5 col-lg-4 my-2">
            <div class="card card-individual pointer orange-background text-white"
              [routerLink]="['/auth/formulario-gastos-generales']">
              <div class="card-body">
                <h3 class="text-center">
                  <i class="fas fa-cash-register"></i> Gastos generales
                </h3>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
