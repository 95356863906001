import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgbModal, NgbModalConfig, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { PresupuestoService } from 'src/app/shared/services/presupuesto.service';
import { SelectoresService } from 'src/app/shared/services/selectores.service';

@Component({
  selector: 'app-presupuesto-existente',
  templateUrl: './presupuesto-existente.component.html',
  styleUrls: ['./presupuesto-existente.component.css']
})
export class PresupuestoExistenteComponent implements OnInit {

  proyectos:any=[];
  tbla_presupuestos_exist=false;
  listado:any=[];
  proyecto_txt='';
  cliente_txt='';
  modalref;
  constructor(config: NgbModalConfig,
    private modal:NgbModal,
    // private modalref:NgbModalRef,
    private fb: FormBuilder,
    private _selectores: SelectoresService,
    private _presupuesto: PresupuestoService) {

      config.backdrop = 'static';
      config.keyboard = false;
     }

  ngOnInit(): void {
    this.selectores();
  }

  selectores(){

    this._selectores.getProyectos().subscribe(response =>{
        this.proyectos = response.proyectos;
        console.log(this.proyectos);
    });
  }

  ch_proyecto(event){
    let proyecto = this.proyectos.filter(item =>{
      return item.id == event
    });
    console.log(proyecto,event);
    this.tbla_presupuestos_exist = false;
    this.proyecto_txt = proyecto[0].proyecto;
    this.cliente_txt = proyecto[0].cliente.nombre_completo;
    this.traer_presupuesto_por_proyecto(event);
  }

  traer_presupuesto_por_proyecto(id){
    this._presupuesto.traer_presupuesto_por_proyecto(id).subscribe(res=>{
      if(res.estado){
        this.tbla_presupuestos_exist = true;
        this.listado = res.presupuestos;
      }
    });
  }

  open_modal(ref){
    this.modal.open(ref, { size: 'lg' , windowClass: 'big-modal' });
  }

  open_modal2(ref,size?:string, clase?:string){
    this.modal.open(ref, { size:size  , windowClass: clase });
  }

  open_modal_ref(ref){
     this.modalref = this.modal.open(ref, { size: 'lg' , windowClass: 'big-modal' })
  }


}
