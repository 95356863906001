import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProveedorService {

  private apiUrl = environment.url;
  constructor(private http: HttpClient) { }

  guardar(data:any):Observable<any>{
    var url = `${this.apiUrl}/proveedores`;
    return this.http.post(url, data);
  }
  traer(tipo):Observable<any>{
    var url = `${this.apiUrl}/proveedores/${tipo}`;
    return this.http.get(url);
  }

  guardar_cuenta_bancaria(data:any):Observable<any>{
    var url = `${this.apiUrl}/proveedores/cuentas`;
    return this.http.post(url, data);
  }

  traer_cuentas_bancarias(proveedor):Observable<any>{
    var url = `${this.apiUrl}/proveedores/cuentas/${proveedor}`;
    return this.http.get(url);
  }
  actualizar_proveedor(data:any):Observable<any>{
    var url = `${this.apiUrl}/proveedores`;
    return this.http.put(url, data);
  }
  actualizar_cuenta(data:any):Observable<any>{
    var url = `${this.apiUrl}/proveedores/cuentas`;
    return this.http.put(url, data);
  }
  traer_bancos():object{
    return [
      {"nombre":"BANCO DE CHILE"},
      {"nombre":"BANCO INTERNACIONAL"},
      {"nombre":"SCOTIABANK"},
      {"nombre":"BANCO DE CREDITO E INVERSIONES"},
      {"nombre":"BANCO BICE"},
      {"nombre":"HSBC BANK"},
      {"nombre":"BANCO SANTANDER CHILE"},
      {"nombre":"ITAU CORPBANCA"},
      {"nombre":"BAMCO SECURITY"},
      {"nombre":"BANCO FALABELLA"},
      {"nombre":"BANCO RIPLEY"},
      {"nombre":"BANCO CONSORCIO"},
      {"nombre":"SCOTIABANK AZUL (EX BBVA)"},
      {"nombre":"BANCO BTG PACTUAL CHILE"},
      {"nombre":"BANCO DEL ESTADO DE CHILE"},

    ]
  }
}
