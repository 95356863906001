import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './shared/guards/auth.guard';
import { PublicGuardGuard } from './shared/guards/public-guard.guard'

import { HomeComponent } from './private/pages/home/home.component';
import { FormularioClienteComponent } from './private/components/cliente/formulario-cliente/formulario-cliente.component';
import { FormularioPresupuestoComponent } from './private/components/presupuesto/formulario-presupuesto/formulario-presupuesto.component';
import { FormularioProyectoComponent } from './private/components/proyecto/formulario-proyecto/formulario-proyecto.component';
import { ListaProyectoComponent } from './private/components/proyecto/lista-proyecto/lista-proyecto.component';
import { PagoProyectoComponent } from './private/components/proyecto/pago-proyecto/pago-proyecto.component'
import { LoginComponent } from './public/pages/login/login.component';
import { PresupuestoExistenteComponent } from './private/components/presupuesto/presupuesto-existente/presupuesto-existente.component';
import { AuthComponent } from './private/pages/auth/auth.component';
import { HistorialProyectoComponent } from './private/components/proyecto/historial-proyecto/historial-proyecto.component';

// import { Name2Component } from './';
// import { Name3Component } from './';
// import { Name4Component } from './';
import { FormularioProveedoresComponent } from './private/components/proveedores/formulario-proveedores/formulario-proveedores.component';
import { FormularioGastosGeneralesComponent } from './private/components/gastos-generales/formulario-gastos-generales/formulario-gastos-generales.component';
import { ListaProveedoresComponent } from './private/components/proveedores/lista-proveedores/lista-proveedores.component';
import { ListaGastosGeneralesComponent } from './private/components/gastos-generales/lista-gastos-generales/lista-gastos-generales.component';
import { ListaMaterialesComponent } from './private/components/proveedores/lista-materiales/lista-materiales.component';

const routes: Routes = [
   { path: '', component: LoginComponent, canActivate:[PublicGuardGuard] },
   {
     path:'auth',
     canActivate: [AuthGuard],
     component: AuthComponent,
     children:[
      { path: 'home', canActivate: [AuthGuard],  component: HomeComponent },
      { path: 'formulario-cliente',  component: FormularioClienteComponent },
      { path: 'formulario-proyecto', component: FormularioProyectoComponent },
      { path: 'lista-proyecto',  component: ListaProyectoComponent },
      { path: 'pago-proyecto/:id', component: PagoProyectoComponent },
      { path: 'historial-proyecto/:id', component: HistorialProyectoComponent },
      { path: 'formulario-presupuesto', component: FormularioPresupuestoComponent },
      { path: 'existente-presupuesto', component: PresupuestoExistenteComponent },
      { path: 'formulario-proveedor', component: FormularioProveedoresComponent },
      { path: 'lista-proveedor', component: ListaProveedoresComponent },
      { path: 'formulario-gastos-generales', component: FormularioGastosGeneralesComponent },
      { path: 'lista-gastos-generales', component: ListaGastosGeneralesComponent },
      {path: 'lista-materiales', component:ListaMaterialesComponent},
      { path: '**', pathMatch: 'full', redirectTo: '/home' }
     ]
   },
   { path: '**', pathMatch: 'full', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

