import { Component, OnInit } from '@angular/core';
import { DatePipe, DecimalPipe } from '@angular/common';
import { ProyectoService } from 'src/app/shared/services/proyecto.service'
import { NgbActiveModal, NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-lista-proyecto',
  templateUrl: './lista-proyecto.component.html',
  styleUrls: ['./lista-proyecto.component.css']
})
export class ListaProyectoComponent implements OnInit {

  form_editar:FormGroup;
  listado:any;
  search:string='';

  proyecto_id:string;
  estado_index:string;
  campos_editar:any;
  estados:any=[
    {id:"1", estado:"Inicial"},
    {id:"2", estado:"En proceso"},
    {id:"3", estado:"Finalizado"}
  ];
  btn_update:boolean=false;
  constructor(
    config: NgbModalConfig, 
    private modal:NgbModal,
    private _proyecto:ProyectoService, 
    public DecimalPipe:DecimalPipe,
    public fb:FormBuilder,
    private activeModalService: NgbActiveModal,
    public datepipe:DatePipe
    ) { }

  ngOnInit(): void {

    this.listar();
  }
  construir_form(){
    this.form_editar = this.fb.group({
      id:[''],
      estado:[null, Validators.required],
      proyecto:[''],
      fecha_inicio:[''],
      total:[''],
      cuotas:['']

    });
   
    // this.form_editar.controls.id.setValue(this.proyecto_id);
    // this.form_editar.controls.estado.setValue(this.estado_index.toString());
    this.dibujar_moneda();
    this.llenar_editar();
    

    
  }
  llenar_editar(){
    const e = this.campos_editar; 
    console.log("data->",e);
    this.form_editar.setValue({
      id: this.proyecto_id,
      estado: this.estado_index.toString(),
      proyecto: e.proyecto,
      fecha_inicio: this.datepipe.transform(e.fecha_inicio, 'yyyy-MM-dd','+0000','es-CL'),
      total: e.total,
      cuotas: e.cuotas
    });
  }
  update(){
    this.btn_update=true;
    if(!this.form_editar.valid){
      alert("No es posible actualizar")
      this.btn_update=false;
    }else{
      const data = {
        id: this.form_editar.controls.id.value,
        estado: this.form_editar.controls.estado.value,
        proyecto: this.form_editar.controls.proyecto.value,
        fecha_inicio: this.form_editar.controls.fecha_inicio.value,
        total: this.form_editar.controls.total.value.replace(/[$.]/g,''),
        cuotas: this.form_editar.controls.cuotas.value,
      };
      this._proyecto.update(data).subscribe(res=>{
        if(res.estado){
          // this.modal.close();
          this.activeModalService.close();
          Swal.fire(
            ''+res.mensaje+'',
            '',
            'success'
          );
          this.btn_update=false;
          this.filter_cliente(this.search);
        }else{
          Swal.fire(
            'Algo salió mal!',
            '',
            'error'
          );
          this.btn_update=false;
          return false;
        }
      });
    }
  }
  listar(){
    this._proyecto.listar().subscribe(res=>{
        if(res.estado){
          this.listado = res.proyectos;
        }
    })
  }
  filter_cliente(query){
    this._proyecto.listar().subscribe(res=>{
      
      if(res.estado){
        let data = res.proyectos.filter(function(el) {
          // console.log("filter->",el)
           return (el.proyecto+' '+el.cliente.n_cliente+' '+el.cliente.nombre_completo).toLowerCase().indexOf(query.toLowerCase()) > -1;
          // console.log(el.item.toLowerCase().indexOf(query.toLowerCase()) > -1);
        })

        // console.log(data);
        this.listado = data;
      }
    })
  }

  money_clp(value){
    // console.log(value);
    return '$ '+this.DecimalPipe.transform(value)?.replace(/,/g,'.')
  }

  open_modal(ref){
    // console.log("open=>",ref);
    this.activeModalService = this.modal.open(ref, { size: 'md' ,  windowClass: 'big-modal-none'});
  }

  dibujar_moneda(){
    this.form_editar.valueChanges.subscribe( form => {
      console.log("mi-form=>",form);
      if(form.total){
        console.log(form.total)
        // return false;
        this.form_editar.patchValue({
          total: this.DecimalPipe.transform(form.total.toString().replace(/\D/g,'')?.replace(/^0/,''))?.replace(/,/g,'.')
          //minimo: (this.currencyPipe.transform(form.minimo.replace(/\D/g,'').replace(/^0/,''),'USD','symbol','1.0-0').replace("$", "")).toString().replace(/,/g, ".")
        },{emitEvent:false}
        );
      }
    });
  }

  eliminar(id){
    Swal.fire({
      title: "'Aceptar' para eliminar",
      showDenyButton: true,
      confirmButtonText: 'Aceptar',
      denyButtonText: `Cancelar`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        const data = {
          id:id
        }
        this._proyecto.eliminar_proyecto(data).subscribe(res=>{
          if(res.estado){
            Swal.fire(
              ''+res.mensaje+'',
              '',
              'success'
            );

            if(this.search==''){
              this.listar();
            }else{
              this.filter_cliente(this.search);
            }

          }else{
            Swal.fire(
              'Error!',
              '',
              'error'
            )
            return false
          }
        }, error =>{
          Swal.fire(
            'Error!',
            '',
            'error'
          )
          return false
        });
      } else if (result.isDenied) {
        
      }
    })
  }
  

}
