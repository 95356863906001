import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DecimalPipe } from '@angular/common';

import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {SelectoresService} from 'src/app/shared/services/selectores.service'
import { PresupuestoService } from 'src/app/shared/services/presupuesto.service'
import Swal from 'sweetalert2';


@Component({
  selector: 'app-formulario-presupuesto',
  templateUrl: './formulario-presupuesto.component.html',
  styleUrls: ['./formulario-presupuesto.component.css']
})
export class FormularioPresupuestoComponent implements OnInit {
  
  public form_item: FormGroup;
  @ViewChild('visor') public lgModal: NgbModal;
  @ViewChild('compra') public compra: NgbModal;
  
  data=[];
  tabla={};

  get_lista:any=[];
  list_materiales:any=[];
  list_mano_obra:any=[];
  list_maquinaria:any=[];
  list_comision:any=[];
  
  detalle=false;
  proyecto=null;
  titulo='';
  proyectos:any=[];
  presupuesto_id:number;
  presupuestos=[
    {nombre:'Presupuesto 1', idpresupuesto:1},
    {nombre:'Presupuesto 2', idpresupuesto:2},
  ];
  estado=[
    {id:1, estado:'Presupuestado', color:'blue'},
    {id:2, estado:'No presupuestado', color:'red'}
  ];
  tipos=[
    {id:1, tipo:'Materiales'},
    {id:2, tipo:'Mano de obra'},
    {id:3, tipo:'Maquinarias, herramientas y equipos'},
    {id:4, tipo:'Comision'},
  ];
  unidades=[
    {id:99, unidad:'S/U'},
    {id:1, unidad:'Unidad'},
    {id:2, unidad:'Caja'},
    {id:3, unidad:'Gl'},
    {id:4, unidad:'Kg'},
    {id:5, unidad:'Bolsas'}
  ];
  tbla_presupuestos_exist = false;
  no_mostrar_cv=false;
  btn_guarda:boolean=false;
  btn_agrega:boolean=false;
  constructor(config: NgbModalConfig, 
              private modal:NgbModal,
              private fb: FormBuilder,
              private _selectores: SelectoresService,
              private _presupuesto: PresupuestoService,
              public DecimalPipe:DecimalPipe,
              ) { 
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit(): void {
    this.construccion_form();
    localStorage.removeItem('items')

    this.selectores();
    // this.data = localStorage.getItem('items');
  }

  construccion_form(){
    this.form_item = this.fb.group({
      estado:[null, Validators.required],
      id_presupuesto:[null, Validators.required],
      tipo:[null, Validators.required],
      item:['', Validators.required],
      unidad:[99],
      ml:[''],
      m2:[''],
      m3:[''],
      // m_total:[''], // opcional 
      cantidad:['', Validators.required],
      precio:['', Validators.required],
      total:[''], //opcional
    });

    this.dibujar_moneda();
  }

  open_modal(ref){
    console.log("open=>",ref);
    this.modal.open(ref, { size: 'lg' ,  windowClass: 'big-modal'});
  }
  open_compra(){
    this.open_modal(this.compra)
  }

  insert_item(){
    this.btn_agrega=true;
    if(this.form_item.valid){
      // const data = this.form_item.value;
      const data={
        estado: this._form('estado'),
        id_presupuesto: this._form('id_presupuesto'),
        tipo: this._form('tipo'),
        item: this._form('item'),
        unidad: this._form('unidad'),
        ml: this._form('ml'),
        m2: this._form('m2'),
        m3: this._form('m3'),
        cantidad: this._form('cantidad'),
        precio: this._form('precio')?.replace(/[$.]/g,''),
        total: (this._form('tipo')=='4')? this._form('total')?.replace(/[$.]/g,''):0,
      };
      
      console.log(data);
      this._presupuesto.guardar_detalle_presupuesto(data).subscribe(res=>{
        if(res.estado){
          
          this.limpiar_form();
          this.listar();
          Swal.fire(
            'Item ingresado!',
            'Cierre para volver a ingresar más',
            'success'
          );
          this.btn_agrega=false;
          this.limpiar_form();

        }else{
          Swal.fire(
            'Error de ingreso!',
            '',
            'error'
          )
          this.btn_agrega=false;
          return false;
        }
      }, error=>{
        Swal.fire(
          'Error de ingreso!',
          '',
          'error'
        )
        this.btn_agrega=false;
        return false;
      });
    }
   

  }
  delete_item(indice){
    console.log(indice);
    this.data.splice(indice, 1);
  }
  limpiar_form(){
    this.form_item.controls.estado.setValue(null);
    this.form_item.controls.tipo.setValue(null);
    this.form_item.controls.item.setValue('');
    this.form_item.controls.unidad.setValue(99);
    this.form_item.controls.ml.setValue('');
    this.form_item.controls.m2.setValue('');
    this.form_item.controls.m3.setValue('');
    // this.form_item.controls.m_total.setValue('');
    this.form_item.controls.cantidad.setValue('');
    this.form_item.controls.precio.setValue('');
    this.form_item.controls.total.setValue('');

  }

 

  m(){  
    if(this.form_item.controls.ml.value?.trim()==''
      &&this.form_item.controls.m2.value?.trim()==''
      &&this.form_item.controls.m3.value?.trim()==''){
      this.form_item.controls.cantidad.setValue('');
      return false;
    }

    let ml=(this.form_item.controls.ml.value == 0 || this.form_item.controls.ml.value == '' )?1:this.form_item.controls.ml.value;
    let m2=(this.form_item.controls.m2.value == 0 || this.form_item.controls.m2.value == '' )?1:this.form_item.controls.m2.value;
    let m3=(this.form_item.controls.m3.value == 0 || this.form_item.controls.m3.value == '' )?1:this.form_item.controls.m3.value;


    this.form_item.controls.cantidad.setValue(ml * m2 * m3);
  }

  t(){
    let cantidad = this.form_item.controls.cantidad.value;
    let  valor = this.form_item.controls.precio.value?.replace(/[$.]/g,'');
    this.form_item.controls.total.setValue(cantidad * valor);
  }

  selectores(){
    
    this._selectores.getProyectos().subscribe(response =>{
        this.proyectos = response.proyectos;
        console.log(this.proyectos);
    });
  }

  ch_proyecto(event){

    this.tbla_presupuestos_exist = true;
    console.log('ch->',event)
    if(event.id == 4){
      this.no_mostrar_cv = true;
      this.form_item.controls.ml.setValue('0');
      this.form_item.controls.m2.setValue('0');
      this.form_item.controls.m3.setValue('0');
      // this.form_item.controls.m_total.setValue('0');
      this.form_item.controls.cantidad.setValue('0');
      this.form_item.controls.precio.setValue('0');
      this.form_item.controls.total.setValue('0');
    }else{
      this.no_mostrar_cv = false;
      this.form_item.controls.ml.setValue('');
      this.form_item.controls.m2.setValue('');
      this.form_item.controls.m3.setValue('');
      // this.form_item.controls.m_total.setValue('');
      this.form_item.controls.cantidad.setValue('');
      this.form_item.controls.precio.setValue('');
      this.form_item.controls.total.setValue('');
    }
  }

  registrar_presupuesto(){
  
    this.btn_guarda=true;
    const data = {
      id_proyecto:this.proyecto,
      titulo: this.titulo
    };

    console.log(data);
  
  
    this._presupuesto.guardar_presupuesto(data).subscribe(res => {
      if(res.estado){
        this.btn_guarda=false;
        this.detalle = true;
        this.form_item.controls.id_presupuesto.setValue(res.presupuesto.id);
        Swal.fire({
          title: 'Ingreso correcto',
          text: "Se abrirá el formulario detalle de presupuestos",
          icon: 'success',
          
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Si, abrir formulario'
          
        }).then((result) => {
          if (result.isConfirmed) {
            this.open_modal(this.lgModal);
          }
        })
      }else{
        this.btn_guarda=true;
        return false;
      }
    },error=>{
      Swal.fire(
        'Error de ingreso!',
        '',
        'error'
      )
      this.btn_guarda=true;
        return false;
    });
  }

  listar(){
    this._presupuesto.listar_detalle_presupuesto(this.form_item.controls.id_presupuesto.value).subscribe(res=>{
      if(res.estado){
        console.log(res.presupuesto[0]["detalle-presupuestos"]);

        this.get_lista = res.presupuesto[0]["detalle-presupuestos"];

        //obtener solo totales (precio*cantidad)
        const total = this.get_lista.map(i =>{
          let item= i.precio * i.cantidad;
          return item;
        });

        this.list_materiales = this.get_lista.filter(item => item.tipo == 1);
        this.list_mano_obra = this.get_lista.filter(item => item.tipo == 2);
        this.list_maquinaria = this.get_lista.filter(item => item.tipo == 3);
        this.list_comision = this.get_lista.filter(item => item.tipo == 4);

        console.log('materiales =>',this.list_materiales)
        // this.total_c_p = total.reduce((a, b) => a + b, 0);
      }
    })
  }

  calculo_m(ml, m2, m3){
    if(ml=='0' && m2=='0' && m3=='0'){
      return 0;
    }
    return Number((ml>0)?ml:1)  *  Number((m2>0)?m2:1) *  Number((m3>0)?m3:1);
  }

  money_clp(value){
    // console.log(value);
    return '$ '+this.DecimalPipe.transform(value)?.replace(/,/g,'.')
  }

  ch_estado(event){
    console.log(event);
    //no presupuestado
    if(event == 2){
      this.form_item.controls.ml.setValue('0');
      this.form_item.controls.m2.setValue('0');
      this.form_item.controls.m3.setValue('0');
      // this.form_item.controls.m_total.setValue('0');
      this.form_item.controls.cantidad.setValue('0');
      this.form_item.controls.precio.setValue('0');
      this.form_item.controls.total.setValue('0');
    }
    if(event == 1){
      this.form_item.controls.ml.setValue('');
      this.form_item.controls.m2.setValue('');
      this.form_item.controls.m3.setValue('');
      // this.form_item.controls.m_total.setValue('');
      this.form_item.controls.cantidad.setValue('');
      this.form_item.controls.precio.setValue('');
      this.form_item.controls.total.setValue('');
    }

    if(this.form_item.controls.tipo.value == 4){
      // alert("es comision");
      this.form_item.controls.ml.setValue('0');
      this.form_item.controls.m2.setValue('0');
      this.form_item.controls.m3.setValue('0');
      // this.form_item.controls.m_total.setValue('0');
      this.form_item.controls.cantidad.setValue('0');
      this.form_item.controls.precio.setValue('0');
      this.form_item.controls.total.setValue('0');
    }
  }

  _form(name){
    return this.form_item.get(name).value;
  }

  dibujar_moneda(){
    this.form_item.valueChanges.subscribe( form => {
      console.log("mi-form=>",form);
      if(form.precio){
        this.form_item.patchValue({
          precio: this.DecimalPipe.transform(form.precio.replace(/\D/g,''))?.replace(/,/g,'.'),
          
        },{emitEvent:false}
        );
      }

      if(form.total){
        var total =""+form.total;
        console.log("ttottal->",total)
        this.form_item.patchValue({
          
           total: this.DecimalPipe.transform(total.replace(/\D/g,''))?.replace(/,/g,'.')
        },{emitEvent:false}
        );
      }
    });
  }

}
