import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CobroService } from 'src/app/shared/services/cobro.service';
import { ProyectoService } from 'src/app/shared/services/proyecto.service';
import { DecimalPipe } from '@angular/common';
import { CompraService } from 'src/app/shared/services/compra.service';
import { PresupuestoService } from 'src/app/shared/services/presupuesto.service';
import { FleteService } from 'src/app/shared/services/flete.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal, { SweetAlertOptions } from 'sweetalert2';

@Component({
  selector: 'app-historial-proyecto',
  templateUrl: './historial-proyecto.component.html',
  styleUrls: ['./historial-proyecto.component.css']
})
export class HistorialProyectoComponent implements OnInit {

  proyecto_id = this._route.snapshot.params.id;
  proyecto:any;
  flete:any;
  cobro:number=0;
  compra:number=0;
  compra_p;
  compra_np:number=0;
  ahorro:number=0;
  perdida:number=0;
  porcentaje_p:string='0';
  porcentaje_np:string='0';
  mdo:any;
  porcentajes:any;
  btn_guarda:boolean=false;
  constructor(
    private _route:ActivatedRoute,
    public DecimalPipe:DecimalPipe,
    private _proyecto:ProyectoService,
    private _cobros:CobroService,
    private _compra:CompraService,
    private _presupuesto:PresupuestoService,
    private _flete:FleteService,
    private modal:NgbModal,
    
    ) { }

  ngOnInit(): void {
    this.get_proyecto();
    this.get_cobros();
    this.get_compra();
    this.get_flete();
    this.get_mano_obra();
    // this.get_porcentaje();
  }

  get_proyecto(){
    this._proyecto.listar().subscribe(res=>{
      this.proyecto = res.proyectos.filter((i)=>{ return i.id == this.proyecto_id })[0];
      
    })
  }

  get_cobros(){
    this._cobros.listar_cobros(this.proyecto_id).subscribe(res=>{
      this.cobro = res.pagos.map(item => item.valor).reduce((prev, next) => prev + next);
      console.log(this.cobro)
    });
  }

  get_compra(){
    this._presupuesto.compras_por_proyecto(this.proyecto_id).subscribe(res=>{
      this.compra = res.compras.map(item => item.iva).reduce((prev, next) => prev + next);
      this.compra_p = res.compras.filter((item) => {return item.estado_detalle_presupuesto == 1}).map(item => item.iva).reduce((prev, next) => prev + next,0);
      this.compra_np = res.compras.filter((item) => {return item.estado_detalle_presupuesto == 2}).map(item => item.iva).reduce((prev, next) => prev + next,0);
      this.porcentaje_p = (this.compra_p / (this.compra_p + this.compra_np) * 100).toFixed(2); 
      this.porcentaje_np = (this.compra_np / (this.compra_p + this.compra_np) * 100).toFixed(2); 

      console.log("P=>",this.compra_p,'%='+this.porcentaje_p)
      console.log("NP=>",this.compra_np)

      // AHORRO/PERDIDA EN COMPRAS PROSUPUESTADAS.
      
      this.ahorro = res.compras.map(i => {
          var valor = (i.precio_detalle * i.cantidad_detalle)-i.iva;
          return (valor>0)?valor:0
          
        }
      ).reduce((prev, next) => prev + next,0);

      this.perdida =Math.abs(res.compras.map(i => {
        var valor = (i.precio_detalle * i.cantidad_detalle)-i.iva;
        return (valor<0)?valor:0
      }
    ).reduce((prev, next) => prev + next,0));
      
      
      
    })
  }
  get_flete(){
    this._flete.flete_historial_por_proyecto(this.proyecto_id).subscribe(res=>{
      this.flete = res.gastos;
    })
  }

  get_mano_obra(){
    this._presupuesto.mano_de_obras_proyecto(this.proyecto_id).subscribe(res=>{
      this.mdo = res.gastos
    });
  }
  get_porcentaje(){
    this._proyecto.traer_porcentaje(this.proyecto_id).subscribe(res=>{
      this.porcentajes = res.porcentajes
    })
  }

  money_clp(value){
    // console.log(value);
    if(!value){
      return "0";
    }
    return '$ '+this.DecimalPipe.transform(value)?.replace(/,/g,'.')
  }

  guardar_porcentaje(g_acierto, g_error){
    this.btn_guarda=true;
    const data = {
      id_proyecto:this.proyecto_id,
      acierto: g_acierto,
      error:g_error
    };
    this._proyecto.guardar_porcentaje(data).subscribe(res=>{
      
      if(res.estado){
        Swal.fire({
          icon: 'success',
          title: '',
          text: res.mensaje,
          allowOutsideClick: false,
          
        })
        this.btn_guarda = false;
      }
    });
  }
  open_modal(ref){
    console.log("open=>",ref);
    this.modal.open(ref, { size: 'lg' ,  windowClass: 'big-modalx'});
  }

  //metodos del resumen
  gasto_general(uno:number, dos:number, tres:number){
    return (uno + dos +tres);
  }
  saldo_actual(uno:number, dos:number){
    return (uno - dos);
  }



}
