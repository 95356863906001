import { Component, Input, OnInit } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'app-ver-presupuesto-compra',
  templateUrl: './ver-presupuesto-compra.component.html',
  styleUrls: ['./ver-presupuesto-compra.component.css']
})
export class VerPresupuestoCompraComponent implements OnInit {

  @Input() compra;
  @Input() tipo_presupuesto:string;

  constructor(public DecimalPipe:DecimalPipe,) { }

  ngOnInit(): void {
    console.log('compra->', this.compra)
  }

  money_clp(value){
    // console.log(value);
    return ''+this.DecimalPipe.transform(value)?.replace(/,/g,'.')
  }

}
