
<div class="cp-panel-head">
    <div align="center">
        Proyecto: {{input_proyecto_txt}} | Presupuesto: {{ input_presupuesto_txt }}
    </div>
</div>
<div class="cp-panel-body">
    <form [formGroup]="form">
        <label>Nº documento</label>
        <input formControlName="n_documento" type="text" class="form-control">
        <br>
        <label>Comprobante (pdf, img)</label>
        <input formControlName="documento" (change)="onSelectImage($event)" type="file" class="form-control-file">
    </form>
    <br>
    <button [disabled]="btn_registra" class="btn btn-registrar w-100" (click)="register();" >Registrar</button>
</div>

<br>

<div class="cp-panel-head">
    <div align="center">
        Listado
    </div>
</div>

<div class="cp-panel-body">
    <div class="table-responsive">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th>Nº documento</th>
                    <th>Documento</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let i of tabla">
                    <td>{{ i.n_documento }}</td>
                    <td>
                        <button (click)="traer_documento(i.documento);open_modal(visor)" class="btn btn-sm btn-registrar">ver</button>
                        <!-- <a target="_blank" href="{{ apiUrl+i.documento | safe }}">{{ apiUrl+i.documento }}</a> -->
                        <!-- <iframe #iframe [src]="apiUrl+i.documento | safe" style="width: 100%;height: 100%;border: none;"></iframe> -->
                        <!-- <iframe  #iframe  class="full" #iframe [src]="data_url | safe"  frameborder="0" ></iframe> -->
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<!--MODAL VISOR PDF-->
<ng-template #visor let-c="close" let-d="dismiss">
    <div class="row">
      <div class="col">
        <div class="modal-header text-center" id="demoFont">
          <h4 class="modal-title" id="modal-basic-title">Visor de PDF</h4>
          <button id="closeModalButton" type="button" class="close" aria-label="Close"
            (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">

            <iframe id="iframex" width="100%" height="500px"
            frameborder="0" allowfullscreen></iframe>
        </div>
        <div class="modal-footer" [hidden]="true">
          <button type="button" class="btn btn-outline-dark" (click)="c('Save click')">OK</button>
        </div>
      </div>
    </div>
  </ng-template>
