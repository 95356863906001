import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GastosGeneralesService {

  private apiUrl = environment.url;
  constructor(private http: HttpClient) { }
  //selects
  categorias():Observable<any>{
    var url = `${this.apiUrl}/categorias_gastos`;
    return this.http.get(url);
  }
  sub_categorias(id):Observable<any>{
    var url = `${this.apiUrl}/categorias_gastos/${id}`;
    return this.http.get(url);
  }

  guardar(data:any):Observable<any>{
    var url = `${this.apiUrl}/gastos`;
    return this.http.post(url, data);
  }
  get_gastos(proyecto:string,desde:Date, hasta:Date):Observable<any>{
    var url = `${this.apiUrl}/gastos/${proyecto}/${desde}/${hasta}`;
    return this.http.get(url);
  }
  editar(data:any):Observable<any>{
    var url = `${this.apiUrl}/gastos`;
    return this.http.put(url, data);
  }

}
