import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CobroService {

  private apiUrl = environment.url;
  constructor(private http: HttpClient) { }

  cobrar(data):Observable<any>{
    var url = `${this.apiUrl}/proyectos/pago`;
    return this.http.post(url, data);
  }

  listar_cobros(id):Observable<any>{
    var url = `${this.apiUrl}/proyectos/pago/${id}`;
    return this.http.get(url);
  }
}
