import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DecimalPipe } from '@angular/common';
import {GastosGeneralesService} from '../../../../shared/services/gastos-generales.service'
import Swal from 'sweetalert2';

@Component({
  selector: 'app-formulario-gastos-generales',
  templateUrl: './formulario-gastos-generales.component.html',
  styleUrls: ['./formulario-gastos-generales.component.css']
})
export class FormularioGastosGeneralesComponent implements OnInit {

  form:FormGroup;
  selectedCategoria: number;
  selectedSubCategoria: number;
  fileToUpload: File = null;

  categorias:any;
  subCategorias:any;
  btn:boolean=false;

  constructor(
    private fb:FormBuilder, 
    private DecimalPipe:DecimalPipe, 
    private gg:GastosGeneralesService
    ) { }

  ngOnInit(): void {
    this.get_categorias();
    this.cosntruir_form();
  }

  cosntruir_form(){
    this.form = this.fb.group({
      id_categoria:[null, Validators.required],
      id_sub_categoria:[null, Validators.required],
      fecha:['', Validators.required],
      monto:['', Validators.required],
      documento:[null],
      desc:['']
    });
    this.dibujar_moneda();
  }
  register(){
    this.btn = true;
    if(this.form.invalid){
      Swal.fire(
        'Faltan campos con(*) por llenar!',
        '',
        'error'
      )
      this.btn = false;
      return false;
    }
    const data = new FormData();
    //data.append('id_categoria',this.form.controls.id_categoria.value);
    data.append('id_categoria',this.form.controls.id_sub_categoria.value);
    data.append('fecha',this.form.controls.fecha.value);
    data.append('monto',this.form.controls.monto.value?.replace(/[$.]/g,''));
    data.append('documento',this.fileToUpload);
    data.append('desc', this.form.controls.desc.value);

    this.gg.guardar(data).subscribe(res =>{
      if(res.estado){
        Swal.fire(
          'Item ingresado!',
          'Cierre para volver a ingresar más',
          'success'
        );
        this.cosntruir_form();
        this.btn = false;
      }
    }, error =>{
      this.btn = false;
      Swal.fire(
        'Error de ingreso!',
        '',
        'error'
      )
    })

  }
  dibujar_moneda(){
    this.form.valueChanges.subscribe( form => {
     
      if(form.monto){
        this.form.patchValue({
          monto: this.DecimalPipe.transform(form.monto.replace(/\D/g,''))?.replace(/,/g,'.'),
        },{emitEvent:false}
        );
      }

    });
  }
  onSelectImage(event) {
    this.fileToUpload = event.srcElement.files[0];
  }
  get_categorias(){
    this.gg.categorias().subscribe(res=>{
      if(res.estado){
        this.categorias = res.categorias;
      }
    });
  }
  sub_categoria(event){
    this.gg.sub_categorias(event).subscribe(res=>{
      if(res.estado){
        this.form.controls.id_sub_categoria.setValue(null);
        this.subCategorias = res.categorias;
      }
    })
  }
  clear_categoria(){
    this.subCategorias = null;
    this.form.controls.id_sub_categoria.setValue(null);
  }

}
