export class Usuario {
    constructor(
      public idusuario?: number | string,
      public nombres?: string,
      public apellidopaterno?: string,
      public apellidomaterno?: string,
      public razonsocial?: string,
      public email?: string,
      public password?: string,
      public idrol?: string,
    //   public celular?: string,
    //   public telefono?: string,
    //   public cargo?: string,
    //   public empresa?: string,
    //   public genero?: string,
    //   public edad?: number,
    //   public nacionalidad?: string,
    //   public direccion?: string,
    //   public idestadousuario?: string,
    //   public rnmmartillero?: string,
    //   public rut?: string,
    //   public vigencia?: number,
    //   public fechacreacion?: Date,
    //   public fotoperfil?: string,
     public estados?: Estados
    ) {
      this.idrol = 'BASIC';
    }
  }
  
  export interface Estados {
    isConnecting: boolean;
    isConnected: boolean;
    isDisconnecting: boolean;
  }
  