import { Component, Input, OnInit } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import {FleteService}from '../../../../../shared/services/flete.service'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-opcion-flete',
  templateUrl: './opcion-flete.component.html',
  styleUrls: ['./opcion-flete.component.css']
})
export class OpcionFleteComponent implements OnInit {

  public form_fte: FormGroup;
  @Input() input_presupuesto;
  @Input() input_presupuesto_txt;
  @Input() input_proyecto_txt;

  valor=0;
  ver_detalle_flete=false;
  btn_detalle=false;
  flete_id;
  lista_detalle_fte:any=[];
  total_flete=0;
  
  constructor(public DecimalPipe:DecimalPipe,
    public flete:FleteService,
    private fb: FormBuilder,
    ) { }

  ngOnInit(): void {
    this.get_flete();
    this.construccion_form();
    
  }

  construccion_form(){
    this.form_fte = this.fb.group({
      id_flete:[null, Validators.required],
      fecha:[null, Validators.required],
      responsable:[null, Validators.required],
      valor:[null, Validators.required],
    });

    this.dibujar_moneda();
    
  }

  money_clp(value){
    if(!value){
      return "0";
    }
    return '$ '+this.DecimalPipe.transform(value)?.replace(/,/g,'.')
  }

  get_flete(){
    console.log("inisde")
    this.flete.get_flete(this.input_presupuesto).subscribe(res=>{
      console.log('flete->',res);
      if(res.estado){
         this.flete_id = res.flete.id;
         this.valor = res.flete.valor;
         this.btn_detalle = true;
         this.form_fte.controls.id_flete.setValue(this.flete_id)
         this.listar_detalles(this.flete_id);
         
      }
    })
  }

  reg_actu(){
    if(this.valor==0){
      Swal.fire(
        'Ingrese un monto presupuestado del flete',
        '',
        'error'
      );
      this.btn_detalle = false;
      return false;
    }else{
      const data = {
        'id_presupuesto':this.input_presupuesto,
        'valor':this.valor
      }
      console.log(data)
      // return false;
      this.flete.guardar_flete(data).subscribe(res=>{
        
        if(res.estado){
          Swal.fire(
            'Monto presupuestado del flete ingresado',
            '',
            'success'
          );
          this.btn_detalle = true;  
          this.form_fte.controls.id_flete.setValue(res.flete.id)
        }else{
          this.btn_detalle = false;  
        }
      });
    }
  }


  register(){
    this.btn_detalle=true;
    if(this.form_fte.valid){
      
      console.log(this.form_fte.value);
      const data = {
        id_flete: this.form_fte.controls.id_flete.value,
        responsable: this.form_fte.controls.responsable.value,
        fecha: this.form_fte.controls.fecha.value,
        valor:this.form_fte.controls.valor.value.replace(/[$.]/g,'')
      };

      this.flete.guardar_detalle_flete(data).subscribe(res=>{
          if(res.estado){
            // alert(res.mesaje)
            Swal.fire(
              'Detalle del flete ingresado!',
              '',
              'success'
            );
            this.btn_detalle = false;
            this.construccion_form();
            this.get_flete();
            this.listar_detalles(res.detalle_flete.id_flete);
          }
      },(error)=>{
        Swal.fire(
          'Error, trate de no ingresar caracteres invalidos!',
          '',
          'error'
        );
        this.get_flete();
        this.btn_detalle = false;
        return false;
      });
    }else{
      Swal.fire(
        'Faltan campos por llenar en el detalle del flete!',
        '',
        'error'
      );
      this.get_flete();
      this.btn_detalle = false;
    }
      
  }

  listar_detalles(id_flete){
    
    this.flete.listar_detalles(id_flete).subscribe(res=>{
       this.lista_detalle_fte = res.detalle_flete;
       this.total_flete = this.lista_detalle_fte.map(i =>{
        return i.valor;
      }).reduce((prev, next) => prev + next,0);
    });
  }

  dibujar_moneda(){
    this.form_fte.valueChanges.subscribe( form => {

      if(form.valor){
        var total =""+form.valor;
        console.log("ttottal->",total)
        this.form_fte.patchValue({
          
           valor: this.DecimalPipe.transform(total.replace(/\D/g,''))?.replace(/,/g,'.')
        },{emitEvent:false}
        );
      }
    });
  }

}
