<div>
    <!-- {{proveedor|json}} -->
    <form [formGroup]="form">
        <h4><b>proveedor: {{proveedor.nombre}}</b></h4>
        <hr>
        <div class="row">
            <div class="col-12 col-lg-4">
                <label>RUT</label>
                <input formControlName="rut" type="text" class="form-control">
            </div>
            <div class="col-12 col-lg-4">
                <label>NOMBRE</label>
                <input formControlName="nombre" type="text" class="form-control">
            </div>
            <div class="col-12 col-lg-4">
                <label>BANCO</label>
                <!-- {{bancos|json}} -->
                <!-- <input formControlName="banco" type="text" class="form-control"> -->
                <div class="ng-autocomplete">
                    <ng-autocomplete formControlName="banco"
                     [data]="datos" [searchKeyword]="keyword"
                     [itemTemplate]="itemTemplate" 
                     [notFoundTemplate]="notFoundTemplate"
                     (selected)='selectEvent($event)'
                     (inputChanged)='onChangeSearch($event)'
                     (inputFocused)='onFocused($event)'
                     >
                    </ng-autocomplete>

                    <ng-template #itemTemplate let-item>
                        <a [innerHTML]="item.nombre"></a>
                    </ng-template>

                    <ng-template #notFoundTemplate let-notFound>
                        <div [innerHTML]="notFound"></div>
                    </ng-template>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-lg-4">
                <label>CUENTA</label>
                <input formControlName="cuenta" type="text" class="form-control">
            </div>
            <div class="col-12 col-lg-4">
                <label>N DE CUENTA</label>
                <input formControlName="n_cuenta" type="text" class="form-control">
            </div>
            <div class="col-12 col-lg-4">
                <label>CORREO</label>
                <input formControlName="correo" type="text" class="form-control">
            </div>
        </div>
    </form>
    <button [disabled]="btn_save" (click)="register();" class="mt-2 btn btn-registrar">Registrar</button>
    <hr>
    <h5>listado de cuentas</h5>
    <!-- <pre>{{lista|json}}</pre> -->
    <div class="table-responsive">
        <table class="table table-bordered">
            <thead class="thead-dark">
                <tr>
                    <th>Rut</th>
                    <th>Nombre</th>
                    <th>Banco</th>
                    <th>Cuenta</th>
                    <th>N de cuenta</th>
                    <th>Correo</th>
                    <th>OPCION</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let i of lista">
                    <!-- <td>{{i.fecha | date:'dd/MM/yyyy':'+0000':'es-CL'}}</td> -->
                    <td>{{ i.rut }}</td>
                    <td>{{ i.nombre }}</td>
                    <td>{{ i.banco }}</td>
                    <td>{{ i.cuenta }}</td>
                    <td>{{ i.n_cuenta }}</td>
                    <td>{{ i.correo }}</td>
                    <td>
                        <button (click)="open_modal_lg(editar);datos=i;" class="btn btn-editar tooltipCustom top mx-1">
                            <!-- <i class="fas fa-edit"></i> -->
                            <i class="fas fa-edit"></i>
                            <span class="tiptext">Editar cuenta bancaria</span>
                        </button>
                    </td>
                    <!-- <td>{{ money_clp(i.valor) }}</td> -->
                </tr>
            </tbody>
            <!-- <tfoot class="thead-dark">
                <tr>
                    <th colspan="2" class="text-right">Total monto flete</th>
                    <td class="tbl-foot-td"><b>{{ money_clp(total_flete) }}</b></td>
                </tr>
                <tr>
                    <th colspan="2" class="text-right">Diferencia ({{money_clp(valor)}} - {{ money_clp(total_flete) }} )</th>
                    <td class="tbl-foot-td"><b>{{ money_clp(valor-total_flete) }}</b></td>
                </tr>
            </tfoot> -->

        </table>
        <ng-template #editar let-c="close" let-d="dismiss">
            <div class="row">
                <div class="col">
                    <div  class="cp-modal-header modal-header text-center" id="demoFont">
                        <h4 class="modal-title" id="modal-basic-title">EDITAR CUENTA BANCARIA</h4>
                        <button id="closeModalButton" type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        
                        <app-editar-cuenta-bancario
                        [cuenta]="datos"
                        (salida)="recibe($event)"
                        >

                        </app-editar-cuenta-bancario>
                    </div>
                </div>
            </div>
        </ng-template>
    </div>
</div>