<app-navbar></app-navbar>

<div class="container-fluid mt-4">
    <div class="card">
        <div class="card-header cp-card-header">
            <div align="center">PRESUPUESTO EXISTENTE</div>
        </div>

        <div class="card-body">

            <label for="">Proyecto</label>
            <!-- <ng-select
                [items]="proyectos"
                bindLabel="proyecto"
                bindValue="id"
                placeholder="--Seleccione--"
                (change)="ch_proyecto($event)"
                single="true"
                        >

            </ng-select> -->

            <ng-select
                class="mb-2"
                placeholder="--seleccione--"
                [searchable]="true"
                [clearable]="true"
                (change)="ch_proyecto($event)"
                 >
                <section *ngFor="let i of proyectos;">
                        <ng-option  value="{{i.id}}">

                           <b>{{ i.proyecto }}</b> - nº{{ i.cliente.n_cliente }} - {{ i.cliente.nombre_completo }}
                        </ng-option>
                     </section>
                </ng-select>


            <div class="table-responsive" *ngIf="tbla_presupuestos_exist">
                <hr>
                <table class="table table-bordered">
                    <thead class="thead-dark">
                        <tr>
                            <th colspan="2" class="text-center">
                                {{ proyecto_txt +' - '+ cliente_txt  }}
                            </th>
                        </tr>
                    </thead>
                    <tr class="thead-dark text-center">
                        <th>Presupuesto</th>
                        <!-- <td>Proyecto</td>
                        <td>Cliente</td> -->

                        <th colspan="2">Opciones</th>
                    </tr>
                    <tr *ngFor="let i of listado" class="text-center">
                        <td>{{i.titulo}}</td>
                        <!-- <td>{{i.id_proyecto}}</td>
                        <td>{{ 'aqui nombre'}}</td> -->
                        <td>
                             <button (click)="open_modal_ref(ver); presupuesto_id=i.id;presupuesto_txt=i.titulo" class="m-1 btn btn-sm btn-info font-weight-bold tooltipCustom top"><i class="far fa-eye"></i>  <span class="tiptext">Ver detalle</span></button>
                             <button (click)="open_modal(agregar); presupuesto_id=i.id;presupuesto_txt=i.titulo" class="m-1 btn btn-sm btn-success font-weight-bold tooltipCustom top"><i class="fas fa-plus-circle"></i> <span class="tiptext">Agregar item</span></button>
                             <button (click)="open_modal(flete); presupuesto_id=i.id;presupuesto_txt=i.titulo" class="m-1 btn btn-sm btn-secondary font-weight-bold tooltipCustom top"><i class="fas fa-truck-moving"></i><span class="tiptext">Flete</span> </button>
                             <button (click)="open_modal2(comprobante,'lg'); presupuesto_id=i.id;presupuesto_txt=i.titulo" class="m-1 btn btn-sm btn-success font-weight-bold tooltipCustom top"><i class="fas fa-file-invoice-dollar"></i><span class="tiptext">Comprobante</span></button>
                        </td>


                    </tr>

                </table>
            </div>
        </div>
    </div>
</div>

<!-- modal ver -->
<ng-template #ver let-c="close" let-d="dismiss">
    <div class="row">
        <div class="col">
            <div  class="cp-modal-header modal-header text-center" id="demoFont">
                <h4 class="modal-title" id="modal-basic-title">Ver Item de presupuesto</h4>
                <button id="closeModalButton" type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <app-opcion-ver-detalle-presupuesto
                [input_presupuesto]="presupuesto_id"
                [input_presupuesto_txt]="presupuesto_txt"
                ></app-opcion-ver-detalle-presupuesto>
            </div>
        </div>
    </div>
</ng-template>

<!-- Agregar ver -->
<ng-template #agregar let-c="close" let-d="dismiss">
    <div class="row">
        <div class="col">
            <div  class="cp-modal-header modal-header text-center" id="demoFont">
                <h4 class="modal-title" id="modal-basic-title">Agregar Item de presupuesto</h4>
                <button id="closeModalButton" type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <app-opcion-agregar-item-presupuesto
                [input_presupuesto]="presupuesto_id"
                [input_presupuesto_txt]="presupuesto_txt"
                [input_proyecto_txt]="proyecto_txt"
                ></app-opcion-agregar-item-presupuesto>
            </div>
        </div>
    </div>
</ng-template>

<!-- FLETE -->
<ng-template #flete let-c="close" let-d="dismiss">
    <div class="row">
        <div class="col">
            <div  class="cp-modal-header modal-header text-center" id="demoFont">
                <h4 class="modal-title" id="modal-basic-title">Flete</h4>
                <button id="closeModalButton" type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <app-opcion-flete
                [input_presupuesto]="presupuesto_id"
                [input_presupuesto_txt]="presupuesto_txt"
                [input_proyecto_txt]="proyecto_txt"
                ></app-opcion-flete>
            </div>
        </div>
    </div>
</ng-template>

<!-- COMPROBANTE -->
<ng-template #comprobante let-c="close" let-d="dismiss">
    <div class="row">
        <div class="col">
            <div  class="cp-modal-header modal-header text-center" id="demoFont">
                <h4 class="modal-title" id="modal-basic-title">Comprobante</h4>
                <button id="closeModalButton" type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <app-opcion-comprobante
                [input_presupuesto]="presupuesto_id"
                [input_presupuesto_txt]="presupuesto_txt"
                [input_proyecto_txt]="proyecto_txt"
                ></app-opcion-comprobante>
            </div>
        </div>
    </div>
</ng-template>

<app-footer></app-footer>
