import { Component, Input, OnInit } from '@angular/core';
import { PresupuestoService } from 'src/app/shared/services/presupuesto.service';
import { DecimalPipe } from '@angular/common';
import { NgbActiveModal, NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder } from '@angular/forms';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-opcion-ver-detalle-presupuesto',
  templateUrl: './opcion-ver-detalle-presupuesto.component.html',
  styleUrls: ['./opcion-ver-detalle-presupuesto.component.css']
})
export class OpcionVerDetallePresupuestoComponent implements OnInit {

  @Input() input_presupuesto;
  @Input() input_presupuesto_txt;



  get_lista:any=[];
  list_materiales:any=[];
  list_mano_obra:any=[];
  list_maquinaria:any=[];
  list_comision:any=[];
  total_c_p=0;
  total_compra=0;
  total_resultado=0;
  total_comision=0;
  search='';
  tipo_presupuesto:string;


 


  compra_presupuesto:any;

  constructor(
    private _presupuesto:PresupuestoService,
    public DecimalPipe:DecimalPipe,
    config: NgbModalConfig,
    private modal:NgbModal,
    private activeModalService: NgbActiveModal,
    private fb: FormBuilder,
    ) { }


  ngOnInit(): void {
    this.listar();
  }
 

  recibe(data, ref){
   
    if(data=="verdadero"){
      this.activeModalService.close();
      this.filterItems(this.search)
      // this.listar();
    }
  }

  listar(){
    this._presupuesto.listar_detalle_presupuesto(this.input_presupuesto).subscribe(res=>{
      if(res.estado){
        

        this.get_lista = res.presupuesto[0]["detalle-presupuestos"];

        //obtener solo totales (precio*cantidad)
        const total = this.get_lista.map(i =>{
          let item= i.precio * i.cantidad;
          return item;
        });

        this.list_materiales = this.get_lista.filter(item => item.tipo == 1);
        this.list_mano_obra = this.get_lista.filter(item => item.tipo == 2);
        this.list_maquinaria = this.get_lista.filter(item => item.tipo == 3);
        this.list_comision = this.get_lista.filter(item => item.tipo == 4);

        this.total_c_p = total.reduce((a, b) => a + b, 0);
        this.total_compra = this.get_lista.map(i =>{

          return (i['compra-presupuesto']==null)?0:i['compra-presupuesto']?.iva
        }).reduce((prev, next) => prev + next,0);

        this.total_resultado = this.get_lista.map(i =>{

          return (i['compra-presupuesto']==null)?
          0:i.cantidad * i.precio - (i['compra-presupuesto']?.iva);
        }).reduce((prev, next) => prev + next,0);

        this.total_comision = this.get_lista.map(i =>{

          return i?.total;
        }).reduce((prev, next) => prev + next,0);


      }
    })
  }

  calculo_m(ml, m2, m3){
    if(ml=='0' && m2=='0' && m3=='0'){
      return 0;
    }
    return Number((ml>0)?ml:1)  *  Number((m2>0)?m2:1) *  Number((m3>0)?m3:1);
  }

  money_clp(value){
    // console.log(value);
    return '$'+this.DecimalPipe.transform(value)?.replace(/,/g,'.')
  }

  filterItems(query) {

    this._presupuesto.listar_detalle_presupuesto(this.input_presupuesto).subscribe(res=>{
      if(res.estado){
        let data = res.presupuesto[0]["detalle-presupuestos"].filter(function(el) {
          return el.item.toLowerCase().indexOf(query.toLowerCase()) > -1;
          // console.log(el.item.toLowerCase().indexOf(query.toLowerCase()) > -1);
        })
        this.get_lista = data;
    
        //obtener solo totales (precio*cantidad)
        const total = this.get_lista.map(i =>{
          let item= i.precio * i.cantidad;
          return item;
        });
        this.list_materiales = this.get_lista.filter(item => item.tipo == 1);
        this.list_mano_obra = this.get_lista.filter(item => item.tipo == 2);
        this.list_maquinaria = this.get_lista.filter(item => item.tipo == 3);
        this.list_comision = this.get_lista.filter(item => item.tipo == 4);
        this.total_c_p = total.reduce((a, b) => a + b, 0);
        this.total_compra = this.get_lista.map(i =>{
          return (i['compra-presupuesto']==null)?0:i['compra-presupuesto']?.iva
        }).reduce((prev, next) => prev + next,0);

        this.total_resultado = this.get_lista.map(i =>{

          return (i['compra-presupuesto']==null)?
          0:i.cantidad * i.precio - (i['compra-presupuesto']?.iva);
        }).reduce((prev, next) => prev + next,0);

        this.total_comision = this.get_lista.map(i =>{

          return i?.total;
        }).reduce((prev, next) => prev + next,0);


      }
    });
    // this.get_lista = data;
  }

  eliminar(id){
    Swal.fire({
      title: "'Aceptar' para eliminar",
      showDenyButton: true,
      confirmButtonText: 'Aceptar',
      denyButtonText: `Cancelar`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        const data = {
          id:id,
          activo:'false'
        }
        this._presupuesto.actualizar_detalle_presupuesto(data).subscribe(res=>{
          if(res.estado){
            Swal.fire(
              ''+res.mensaje+'',
              '',
              'success'
            );

            if(this.search==''){
              this.listar();
            }else{
              this.filterItems(this.search);
            }

          }else{
            Swal.fire(
              'Error!',
              '',
              'error'
            )
            return false
          }
        }, error =>{
          Swal.fire(
            'Error!',
            '',
            'error'
          )
          return false
        });
      } else if (result.isDenied) {
        
      }
    })
  }

  open_modal(ref){
    this.modal.open(ref, { size:"lg", windowClass: 'big-modal-none' });
  }

  open_modal_compra(ref){
  //  
    
    this.activeModalService = this.modal.open(ref, { size:"lg" })
    
    
  }

  open_modal_small(ref){
    this.modal.open(ref, { size:"sm", centered: true});
  }

  _open_modal(ref, size:string){
    this.activeModalService = this.modal.open(ref, { size:size, centered: true});
  }
  _recibe(entrada){
    if(entrada=='true'){
      
      this.activeModalService.close();
      if(this.search==''){
        this.listar();
      }else{
        this.filterItems(this.search);
      }
    }
  }

}
