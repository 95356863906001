import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GastosGeneralesService } from 'src/app/shared/services/gastos-generales.service';
import { DatePipe, DecimalPipe } from '@angular/common';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-editar-gastos',
  templateUrl: './editar-gastos.component.html',
  styleUrls: ['./editar-gastos.component.css']
})
export class EditarGastosComponent implements OnInit {

  data:any;
  form:FormGroup;
  categorias: any;
  subCategorias: any;
  btn:boolean=false;
  fileToUpload: File = null;
  @Output() salida = new EventEmitter<string>();
  @Input() set gasto(data:any){
    if(data){
      this.data = data;
    }
  }
  constructor(private fb:FormBuilder, private datepipe:DatePipe, private gg:GastosGeneralesService, private DecimalPipe:DecimalPipe, ) { }

  ngOnInit(): void {
    console.log("xxx", this.data)
    this.cosntruir_form();
    this.get_categorias();
    this.sub_categoria(this.data.id_categoria)
    
  }

  cosntruir_form(){
    this.form = this.fb.group({
      id_categoria:[this.data.id_categoria, Validators.required],
      id_sub_categoria:[this.data.id_sub_categoria, Validators.required],
      fecha:[this.datepipe.transform(this.data.fecha , 'yyyy-MM-dd','+0000','es-CL'), Validators.required],
      monto:['1000', Validators.required],
      documento:[null],
      desc:[this.data.desc]
    });
    this.form.controls.id_categoria.disable()
     this.dibujar_moneda();
     this.form.controls.monto.setValue(this.data.monto);
  }
  get_categorias(){
    this.gg.categorias().subscribe(res=>{
      if(res.estado){
        this.categorias = res.categorias;
      }
    });
  }
  sub_categoria(event){
    this.gg.sub_categorias(event).subscribe(res=>{
      if(res.estado){
        //this.form.controls.id_sub_categoria.setValue(null);
        this.subCategorias = res.categorias;
        console.log("subCat", this.subCategorias)
      }
    })
  }

  dibujar_moneda(){
    this.form.valueChanges.subscribe( form => {
     
      if(form.monto){
        var monto =""+form.monto;
        this.form.patchValue({
          monto: this.DecimalPipe.transform(monto.replace(/\D/g,''))?.replace(/,/g,'.'),
        },{emitEvent:false}
        );
      }

    });
  }
  onSelectImage(event) {
    this.fileToUpload = event.srcElement.files[0];
  }
  clear_categoria(){
    this.subCategorias = null;
    this.form.controls.id_sub_categoria.setValue(null);
  }

  editar(){
    this.btn = true;
    if(this.form.invalid){
      Swal.fire(
        'Faltan campos con(*) por llenar!',
        '',
        'error'
      )
      this.btn = false;
      return false;
    }
    const data = new FormData();
    //data.append('id_categoria',this.form.controls.id_categoria.value);
    data.append('id',this.data.id);
    data.append('id_categoria',this.form.controls.id_sub_categoria.value);
    data.append('fecha',this.form.controls.fecha.value);
    data.append('monto',this.form.controls.monto.value?.replace(/[$.]/g,''));
    data.append('documento',this.fileToUpload);
    data.append('desc', this.form.controls.desc.value);

    this.gg.editar(data).subscribe(res =>{
      if(res.estado){
        Swal.fire(
          ''+res.mensaje+'',
          '',
          'success'
        );
        //this.cosntruir_form();
        this.salida.emit('true');
        this.btn = false;
      }
    }, error =>{
      this.btn = false;
      Swal.fire(
        'Error de ingreso!',
        '',
        'error'
      )
    })
  }
}
