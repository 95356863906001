import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { ProveedorService } from '../../../../shared/services/proveedor.service';
import { DecimalPipe } from '@angular/common';


@Component({
  selector: 'app-formulario-proveedores',
  templateUrl: './formulario-proveedores.component.html',
  styleUrls: ['./formulario-proveedores.component.css'],
})
export class FormularioProveedoresComponent implements OnInit {
  public form: FormGroup;

  btn_save:boolean=false;
  proveedor: any;
  proveedores: any = [
    { id: 1, name: 'empresas' },
    { id: 2, name: 'fletes' },
    { id: 3, name: 'maestros' },
  ];

  constructor(private fb: FormBuilder, private _proveedor:ProveedorService, private DecimalPipe:DecimalPipe) {
    this.construir_form();
  }

  ngOnInit(): void {}
  construir_form() {
    this.form = this.fb.group({
      rut: [''],
      nombre: ['', Validators.required],
      contacto: ['', Validators.required],
      direccion: ['', Validators.required],
      tipo: [null, Validators.required],
    });
  }

  register() {
    this.btn_save = true;
    if (this.form.valid) {
      console.log(this.form.value);
      
      this._proveedor.guardar(this.form.value).subscribe(res=>{
        if(res.estado){
          
          if (this.proveedor == '1') {
            this.form.controls.horario.setValue('');
            this.form.controls.vendedor.setValue('');
            this.form.controls.giro.setValue('');
            this.proveedor = null;
          }
      
          if (this.proveedor == '2') {
            this.form.controls.vehiculo.setValue('');
            this.form.controls.capacidad.setValue('');
            this.form.controls.tarifa.setValue('');
            this.proveedor = null;
          }
      
          if (this.proveedor == '3') {
            this.form.controls.especialidad.setValue('');
            this.form.controls.observacion.setValue('');
            this.proveedor = null;
          }
          Swal.fire(
            ''+res.mensaje+'',
            '',
            'success'
          );
          this.construir_form();
          this.btn_save = false;
        }else{
          this.btn_save = false;
        }
      });
    } else {
      Swal.fire(
        'Faltan campos con (*) por llenar',
        '',
        'error'
      );
      this.btn_save = false;
    }
  }

  changeProveedor(event) {
    this.proveedor = event;
    if (this.proveedor == '1') {
      this.form_campos_empresa();
    }

    if (this.proveedor == '2') {
      this.form_campos_flete();
    }

    if (this.proveedor == '3') {
      this.form_campos_maestros();
    }
  }

  form_campos_empresa() {
    this.remove_campos_flete();
    this.remove_campos_maestros();
    this.form.setControl('rut', new FormControl('', Validators.required));
    this.form.addControl('horario', new FormControl(''));
    this.form.addControl('vendedor', new FormControl(''));
    this.form.addControl('giro', new FormControl(''));
    
  }
  form_campos_flete() {
    this.remove_campos_empresa();
    this.remove_campos_maestros();
    this.form.addControl('vehiculo', new FormControl(''));
    this.form.addControl('capacidad', new FormControl(''));
    this.form.addControl('tarifa', new FormControl(''));

    this.dibujar_moneda();
    
  }
  form_campos_maestros() {
    this.remove_campos_empresa();
    this.remove_campos_flete();
    this.form.addControl('especialidad', new FormControl(''));
    this.form.addControl('observacion', new FormControl(''));
    
  }
  remove_campos_empresa(){
    this.form.setControl('rut', new FormControl(''));
    this.form.removeControl('horario');
    this.form.removeControl('vendedor');
    this.form.removeControl('giro');
  }
  remove_campos_flete(){
    this.form.removeControl('vehiculo');
    this.form.removeControl('capacidad');
    this.form.removeControl('tarifa');
  }
  remove_campos_maestros(){
    this.form.removeControl('especialidad');
    this.form.removeControl('observacion');
  }

  dibujar_moneda() {
    this.form.valueChanges.subscribe((form) => {
      console.log('mi-form=>', form);
      if (form.tarifa) {
        this.form.patchValue(
          {
            tarifa: this.DecimalPipe.transform(
              form.tarifa.replace(/\D/g, '')?.replace(/^0/, '')
            )?.replace(/,/g, '.'),
            //minimo: (this.currencyPipe.transform(form.minimo.replace(/\D/g,'').replace(/^0/,''),'USD','symbol','1.0-0').replace("$", "")).toString().replace(/,/g, ".")
          },
          { emitEvent: false }
        );
      }
    });
  }

}
