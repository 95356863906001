<app-navbar></app-navbar>

<div class="container-fluid mt-4">
    <div class="card salmon-outline">

        <div class="card-header cp-card-header">
            <div class="row">
                <div class="col-4 col-md-2"><a [routerLink]="['/auth/home']"><i class="fas fa-arrow-left"></i>
                        Volver</a>
                </div>
                <div class="col-7 col-md-8 text-center font-weight-bold">LISTA DE MATERIALES</div>
            </div>
        </div>

        <div class="card-body">
            <div class="row">
                <div class="col-md-4 mt-1">
                    <input [(ngModel)]="material" type="text" class="form-control" placeholder="Buscar material..."
                        aria-label="Recipient's username" aria-describedby="button-addon2">
                </div>
                <div class="col-md-4 mt-1">
                    <ng-select [(ngModel)]="empresa" placeholder="seleccione empresa proveedora" [searchable]="true"
                        [clearable]="true">
                        <section>
                            <ng-option value="0">TODAS LAS EMPRESAS PROOVEDORAS</ng-option>
                        </section>
                        <section *ngFor="let i of _empresas;">
                            <ng-option value="{{i.proveedor_empresa.id}}">
                                {{'Nº'+i.rut }} - {{ i.nombre }}
                            </ng-option>
                        </section>
                    </ng-select>
                </div>
                <div class="col-md-2 mt-1">
                    <button (click)="listar_materiales();" class="btn btn-registrar w-100">Buscar</button>
                </div>
                <div class="col-md-1 mt-1">
                    <button (click)="reload();" class="btn btn-registrar w-100">
                        <i class="fas fa-sync-alt"></i>
                    </button>
                </div>
            </div>
           <br>
            <div *ngIf="count > 0" class="table-responsive">
                <table class="table table-bordered">
                    <thead class="thead-dark text-center">
                        <tr class="table-primary">
                            <th>MATERIAL</th>
                            <th>PRECIO</th>
                            <th>EMPRESA</th>
                            <th>RUT</th>
                            <th>HORARIO</th>
                            <th>DIRECCION</th>
                            <th>CONTACTO</th>
                            <th>FECHA</th>
                            <th colspan="2">OPCION</th>
                            <!-- <th class="anchoTh">Costo</th>
                      <th>Cuotas</th>
                      <th class="anchoTh">Opciones</th> -->
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="text-center" *ngFor="let i of listado;">
                            <td><b>{{ i.material }}</b></td>
                            <td><b>{{ money_clp(i.precio) }}</b></td>
                            <td>{{ i.nombre }}</td>
                            <td>{{ i.rut }}</td>
                            <td>{{ i.horario }}</td>
                            <td>{{ i.direccion }}</td>
                            <td>{{ i.contacto }}</td>
                            <td>{{ i.fecha_vigente | date:'dd/MM/yyyy':'+0000':'es-CL'}}</td>
                            <td>
                                <button (click)="open_modal(editar);datos=i;" class="btn btn-editar tooltipCustom top mx-1">
                                    <!-- <i class="fas fa-edit"></i> -->
                                    <i class="fas fa-edit"></i>
                                    <span class="tiptext">Editar material</span>
                                </button>
                            </td>
                            <td>
                                <button (click)="open_modal(historial);datos=i;" class="btn btn-editar tooltipCustom top mx-1">
                                    <!-- <i class="fas fa-edit"></i> -->
                                    <i class="fas fa-history"></i>
                                    <span class="tiptext">historial de precios</span>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <ng-template #editar let-c="close" let-d="dismiss">
                    <div class="row">
                        <div class="col">
                            <div  class="cp-modal-header modal-header text-center" id="demoFont">
                                <h4 class="modal-title" id="modal-basic-title">Editar materiales</h4>
                                <button id="closeModalButton" type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <app-editar-materiales
                                 [material]='datos'
                                 (salida)="recibe($event)"
                                >

                                </app-editar-materiales>
                            </div>
                        </div>
                    </div>
                </ng-template>


                <ng-template #historial let-c="close" let-d="dismiss">
                    <div class="row">
                        <div class="col">
                            <div  class="cp-modal-header modal-header text-center" id="demoFont">
                                <h4 class="modal-title" id="modal-basic-title">Historial</h4>
                                <button id="closeModalButton" type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <app-historial-material
                                 [material]='datos'

                                >

                                </app-historial-material>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
</div>


<app-footer></app-footer>
