import { Component, Input, OnInit } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { MaterialesService } from 'src/app/shared/services/materiales.service';

@Component({
  selector: 'app-historial-material',
  templateUrl: './historial-material.component.html',
  styleUrls: ['./historial-material.component.css']
})
export class HistorialMaterialComponent implements OnInit {

  $material:any;
  lista:any;
  @Input() set material(data:any){
    if(data){
      this.$material = data;
    }
  }
  constructor(private _material:MaterialesService, public DecimalPipe: DecimalPipe,) { }

  ngOnInit(): void {
    this.listar();
  }

  listar(){
    this._material.listar_historial(this.$material.id_material).subscribe(res =>{
      if(res.estado){
        this.lista = res.historial;
      }
    })
  }
  money_clp(value) {
    // console.log(value);
    return '$ ' + this.DecimalPipe.transform(value)?.replace(/,/g, '.');
  }

}
