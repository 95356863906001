import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(request.url.includes(environment.url.split('//')[1])){
      const header = request.clone({
        headers: request.headers.set(
          'authorization-header',
          environment.header
        ).set(
         'authorization-token',
         localStorage.getItem('token')?localStorage.getItem('token'):''
        ),
      });
      return next.handle(header);
    }
    
  }
}
