import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ProveedorService } from '../../../../shared/services/proveedor.service';
import Swal from 'sweetalert2';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'app-editar-proveedor',
  templateUrl: './editar-proveedor.component.html',
  styleUrls: ['./editar-proveedor.component.css']
})
export class EditarProveedorComponent implements OnInit {
  @Output() salida: EventEmitter<string> = new EventEmitter();
  prov:any;
  form:FormGroup;
  @Input() set proveedor(data:any){
    if(data){
      this.prov =data;
    }
  }
  
  constructor(private fb:FormBuilder, private _proveedor:ProveedorService, private DecimalPipe:DecimalPipe) { }

  ngOnInit(): void {
    console.log('prov', this.prov)
    this.construir_form();
  }

  construir_form(){
    
    this.form = this.fb.group({
      id:[this.prov.id],
      rut: [this.prov.rut],
      nombre : [this.prov.nombre],
      contacto : [this.prov.contacto],
      direccion: [this.prov.direccion],
    });

    if(this.prov.tipo == 1){ //si es empresa
      this.items_empresa();
    }
    if(this.prov.tipo == 2){ //si es fletero
      this.items_fletero();
    }
    if(this.prov.tipo == 3){ //si es maestro
      this.items_maestro();
    }
  }
  items_empresa(){
    this.form.addControl('horario',new FormControl(this.prov.proveedor_empresa.horario));
    this.form.addControl('vendedor',new FormControl(this.prov.proveedor_empresa.vendedor));
    this.form.addControl('giro',new FormControl(this.prov.giro));
  }
  items_fletero(){
    this.form.addControl('vehiculo', new FormControl(this.prov.proveedor_flete.vehiculo));
    this.form.addControl('capacidad', new FormControl(this.prov.proveedor_flete.capacidad));
    this.form.addControl('tarifa', new FormControl(this.prov.proveedor_flete.tarifa));
    this.dibujar_moneda();
  }
  items_maestro(){
    this.form.addControl('especialidad', new FormControl(this.prov.proveedor_maestro.especialidad));
    this.form.addControl('observacion', new FormControl(this.prov.proveedor_maestro.observacion));
  }

  update(){
    this._proveedor.actualizar_proveedor(this.form.value).subscribe(res=>{
      if(res.estado){
        Swal.fire(
          ''+res.mensaje+'',
          '',
          'success'
        );
        this.salida.emit('true');
      }else{
        Swal.fire(
          'Faltan campos con (*) por llenar',
          '',
          'error'
        );
      }
    }, error =>{
      Swal.fire(
        'Faltan campos con (*) por llenar',
        '',
        'error'
      );
    });
  }

  dibujar_moneda() {
    this.form.valueChanges.subscribe((form) => {
      console.log('mi-form=>', form);
      if (form.tarifa) {
        this.form.patchValue(
          {
            tarifa: this.DecimalPipe.transform(
              form.tarifa.replace(/\D/g, '')?.replace(/^0/, '')
            )?.replace(/,/g, '.'),
            //minimo: (this.currencyPipe.transform(form.minimo.replace(/\D/g,'').replace(/^0/,''),'USD','symbol','1.0-0').replace("$", "")).toString().replace(/,/g, ".")
          },
          { emitEvent: false }
        );
      }
    });
  }


}
