import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'estadoDetallePresupuesto'
})
export class EstadoDetallePresupuestoPipe implements PipeTransform {

  transform(value): unknown {
    switch (value) {
      case 1: return "Presupuestado"; break;
      case 2: return "No presupuestado"; break;
      default:
        break;
    }
  }

}
