<app-navbar></app-navbar>

<div class="container mt-4">
    
    <div class="card salmon-outline">
        <div class="card-header cp-card-header">
            <div align="center">HISTORIAL</div>
        </div>

        <div class="card-body">
            <div class="alert alert-warning" role="alert">
                Cuando el campo "por cobrar" sea igual o menor al "costo total" el boton cobrar sera bloqueado
              </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="cp-panel-head" align="center">Proyecto</div>
                    <div class="cp-panel-body">
                        <!-- <pre>{{proyecto | json}}</pre> -->
                        <b>Proyecto: </b> <label>{{ proyecto?.proyecto }}</label><br>
                        <b>Fecha de inicio: </b> <label>{{ proyecto?.fecha_inicio | date:'dd-MM-yyyy' }}</label><br>
                        <b>Estado: </b> <label>{{ proyecto?.estado | estadoProyecto }}</label><br>
                        <b>Nº Cliente: </b><label>{{ proyecto?.cliente.n_cliente }}</label><br>
                        <b>Cliente: </b><label>{{ proyecto?.cliente.nombre_completo }}</label><br>
                        <b>Costo total: </b><label><b>{{ money_clp(proyecto?.total) }}</b></label><br>
                        <b>Cuotas: </b><label>{{ proyecto?.cuotas }}</label><br>
                        <b>total Cobrado: </b><label><b>{{money_clp(suma_cobros)}}</b></label><br>
                        <b>por cobrar: </b><label><b style="color:chocolate">{{ money_clp(proyecto?.total - suma_cobros) }}</b></label>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="cp-panel-head" align="center">Cobros</div>
                    <div class="cp-panel-body">
                        <!-- <pre>{{list_cobros | json}}</pre> -->
                        <div class="table-response">
                           
                            <table *ngIf="list_cobros.length > 0" class="table table-border">
                                <thead>
                                    <tr>
                                        <th>Fecha</th>
                                        <th>Valor</th>
                                        <th>Obs.</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let i of list_cobros">
                                        <td>{{ i.fecha_pago | date:'dd/MM/yyyy':'+0000':'es-CL' }}</td>
                                        <td>{{ money_clp(i.valor) }}</td>
                                        <td>
                                            <button *ngIf="i.observacion" (click)="txt_obs=i;open_modal(obs)" class="btn btn-sm btn-registrar">
                                                <i class="fas fa-eye"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th>Total cobrado</th>
                                        <td><b>{{ money_clp(suma_cobros) }}</b></td>
                                    </tr>
                                </tfoot>
                            </table>
                            <div *ngIf="list_cobros.length == 0">
                                <label class="text-center">no existen cobros</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="mt-4 card salmon-outline">
        <div class="card-header cp-card-header">
            <div align="center">Cobrar CUOTA DEL PROYECTO</div>
        </div>

        <div class="card-body">
           <div class="row justify-content-center">
               <div class="col-md-6">
                <form [formGroup]="formulario">
                    <label>Proyecto</label>
                    <input class="form-control" type="text" [disabled]="true" [value]="proyecto?.proyecto">
                    <br>
                    <label>Fecha de pago</label>
                    <input formControlName="fecha_pago" class="form-control" type="date">
                    <br>
                    <label>Valor de cuota</label>
                    <input formControlName="valor" class="form-control" type="text">
                    <br>
                    <label>Observacion</label>
                    <textarea formControlName="observacion" class="form-control" id="" cols="30" rows="7"></textarea>
                </form>
                <button id="btn_cobrar" [disabled]="(proyecto?.total - suma_cobros)<=0" (click)="cobrar($event);" class="mt-3 btn btn-registrar w-100">Cobrar</button>
               </div>
           </div>
        </div>
    </div>
</div>

<!-- MODAL ADD ITEM (de momento en esta vista obsoleta) -->
<ng-template #obs let-c="close" let-d="dismiss">
    <div class="row">
        <div class="col">
            <div  class=" cp-card-header modal-header text-center" id="demoFont">
                <h4 class="modal-title" id="modal-basic-title">Observacion</h4>
                <button id="closeModalButton" type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="cp-panel-head">
                    <div align="center" > Observacion</div>
                </div>
               <div class="cp-panel-body">
                    <p>{{ txt_obs?.observacion }}</p>
                </div>
            </div>
            <div class="modal-footer" [hidden]="true">
                <button type="button" class="btn btn-outline-dark" (click)="c('Save click')">OK</button>
            </div>
        </div>
    </div>




    
</ng-template>


<app-footer></app-footer>