<section id="admin">
  <div class="container">
    <div class="row min-vh-100 justify-content-center align-items-center">
      <div class="col-12 col-lg-5 mx-auto">
        <div class="card borde">
          <div class="card-header text-center">
            <h4 class="card-title my-auto">
              <img class="logo-construplanet">
            </h4>
          </div>
          <div class="card-body">
            <form [formGroup]="loginForm" (ngSubmit)="login()">
              <div class="col-12 col-md-12 mb-3">
                <label class="form-label font-weight-bold">Correo Administrativo</label>
                <!-- <div class="input-icons">
                  <i class="fas fa-envelope icon"></i> -->
                  <input type="email" class="form-control input-field" formControlName="email"
                    placeholder="Ingrese su correo electronico">
                </div>
              <!-- </div> -->
              <div class="col-12 col-md-12 mb-3">
                <label class="form-label font-weight-bold">Contraseña</label>
                <div class="input-group mb-3">
                  <!-- <div class="input-icons"> -->
                    <!-- <i class="fas fa-unlock-alt icon"></i> -->
                    <input type="password" class="form-control input-password input-field" formControlName="password"
                    placeholder="Ingrese su contraseña" [type]="showPasswordLogin ? 'text' : 'password'">
                  <!-- </div> -->
                    <button class="btn btn-primary borde-input" type="button"
                      (click)="showPasswordLogin = !showPasswordLogin">
                      <i *ngIf="!showPasswordLogin" class="fas fa-eye-slash"></i>
                      <i *ngIf="showPasswordLogin" class="fas fa-eye"></i>
                    </button>
                </div>
              </div>
              <hr>
              <div class="col-12 col-md-12">
                <div class="d-grid gap-2">
                  <button class="btn btn-block btn-primary" type="submit" >
                    <span class="fw-bold">Ingresar <i class="fas fa-sign-in-alt"></i></span>
                    <!-- <span> <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
                      Cargando</span> -->
                  </button>
                </div>
              </div>
            </form>
          </div>

          <div class="card-footer text-center">
            <div class="row">
              <div class="col-12">
                <label> &copy; {{fecha|date:'yyyy'}} Neofox Informática</label>
              </div>
              <div class="col-12">
                <img width="30px" height="30px" src="assets/fox-head.png">
                <label> v1.5.3</label>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</section>
