<app-navbar></app-navbar>
<div class="container mt-2">
   
   <div class="card salmon-outline">
        <div class="card-header cp-card-header">
            <!-- <pre>{{ proyecto  | json}}</pre> -->
            <div align="center">HISTORIAL: {{ proyecto?.proyecto}}</div>
        </div>

        <div class="card-body">
        

                <div class="row justify-content-center">
                    <div class="col-md-8">
                        <div class="table-responsive">
                            <table class="table table-bordered">

                                <!-- SEPARADOR 1 -->
                                <thead class="thead-dark">
                                    <tr>
                                        <th colspan="4" class="text-center">control de gastos</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th>Nº cliente</th>
                                        <td colspan="3">{{ proyecto?.cliente.n_cliente }}</td>
                                    </tr>
    
                                    <tr>
                                        <th>Estado de proyecto</th>
                                        <td colspan="3">{{ proyecto?.estado | estadoProyecto }}</td>
                                    </tr>
                                </tbody>
    
                                <!-- SEPARADOR 2 -->
                                <thead class="thead-dark">
                                    <tr>
                                        <th colspan="4" class="text-center">compras</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th>Venta(+)</th>
                                        <td colspan="2" class="tooltipEstado top">
                                            {{ money_clp(proyecto?.total) }}
                                            <span class="tiptext"> <b>Venta(+): </b> Costo total del proyecto</span>
                                        </td>
                                    <td></td>
                                    </tr>
                                    <tr>
                                        <th>Cancelado</th>
                                        <td colspan="2" class="tooltipEstado top">
                                            {{ money_clp(cobro) }}
                                            <span class="tiptext"> <b>Cancelado: </b> Cobros al cliente / pagos realizados al proyecto</span>
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Gastos(-)</th>
                                        <td colspan="2" class="tooltipEstado top">
                                            {{ money_clp(compra)}} 
                                            <span class="tiptext"> <b>Gastos(-): </b> Suma de todas las compras hechas desde modulo presupuestos existentes, item iva de la compra</span>
                                        </td>
                                        <!-- la suma de toda la compra reflejada en detalle presupuesto resumen->total compra -->
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Resultado</th>
                                        <td colspan="2" class="tooltipEstado top">
                                            {{ money_clp(cobro - compra)}}
                                            <span class="tiptext"> <b>Resultado: </b> calculo entre cancelado - gastos(-)</span>
                                        </td>
                                        <td></td>
                                    </tr>
                                </tbody>
    
                                <!-- SEPARADOR 3 -->
                                <thead class="thead-dark">
                                    <tr>
                                        <th colspan="4" class="text-center">Porcentaje de error en el presupuesto</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th>GASTOS PRESUPUESTADOS</th>
                                        <td class="tooltipEstado top" >
                                            {{ money_clp(compra_p) }}
                                            <span class="tiptext"> <b>GASTOS PRESUPUESTADOS: </b> Suma total de las compras con categoria presupuestada</span>
                                        </td>
    
                                        <td class="porc_positivo">{{ porcentaje_p+'%' }}</td>
                                        <td rowspan="2">
                                            <button [disabled]="btn_guarda" (click)="guardar_porcentaje(porcentaje_p, porcentaje_np );" class="btn btn-outline-success">Guardar porcentaje</button>
                                            <button (click)="this.get_porcentaje();open_modal(ver_porcentaje);" class="mt-2 btn btn-outline-info">Ver porcentaje</button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>GASTOS NO PRESUPUESTADOS</th>
                                        <td class="tooltipEstado top">
                                            {{ money_clp(compra_np) }}
                                            <span class="tiptext"> <b>GASTOS NO PRESUPUESTADOS: </b> Suma total de las compras con categoria no presupuestada</span>
                                        </td>
    
                                        <td class="porc_negativo">{{ porcentaje_np+'%' }}</td>
                                    </tr>
    
                                   
    
    
    
                                    <tr>
                                        <th>Resultado</th>
                                        <td colspan="2" class="tooltipEstado top">
                                            {{ money_clp(compra_p + compra_np)}}
                                            <span class="tiptext"> <b>Resultado: </b> calculo entre gastos p. + gastos no p.(-)</span>
                                        </td>
                                        <td></td>
                                    </tr>
                                    
                                </tbody>
    
                                <!-- SEPARADOR 4 -->
                                <thead class="thead-dark">
                                    <tr>
                                        <!-- PROSUPUESTADAS -->
                                        <th colspan="4" class="text-center">AHORRO/PERDIDA EN COMPRAS.</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th>Compra(+)</th>
                                        <td colspan="2" class="tooltipEstado top">
                                            {{ money_clp(ahorro) }}
                                            <span class="tiptext"> <b>Compra(+): </b> Suma total de todas las compras que dieron positivo en el presupuesto</span>
                                        </td>
                                    
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Compra(-)</th>
                                        <td colspan="2" class="tooltipEstado top">
                                            {{money_clp(perdida)}}
                                            <span class="tiptext"> <b>Compra(-): </b> Suma total de todas las compras que dieron negativo en el presupuesto</span>
                                        </td>
                                        
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Resultado</th>
                                        <td colspan="2" *ngIf="ahorro-perdida < 0" style="color:red">{{ money_clp(ahorro-perdida) }}</td>
                                        <td colspan="2" *ngIf="ahorro-perdida >= 0" style="color:green">{{ money_clp(ahorro-perdida) }}</td>
                                        
                                        <td *ngIf="ahorro-perdida < 0" class="porc_negativo"  ><b>PERDIDA</b></td>
                                        <td *ngIf="ahorro-perdida >= 0" class="porc_positivo" ><b>AHORRO</b></td>
                                    </tr>
                                </tbody>
    
                                <!-- FLETE -->
                                <thead>
                                    <tr class="thead-dark">
                                        <th colspan="4" class="text-center">Flete</th> 
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th>Presupuestado</th>
                                        <td colspan="2" class="tooltipEstado top">
                                            {{money_clp(flete?.presupuestado)}}
                                            <span class="tiptext"> <b>Presupuestado: </b> Suma total de montos presupuestados en cada flete</span>
                                        </td>
                                        
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Costo actual</th>
                                        <td colspan="2" class="tooltipEstado top">
                                            {{ money_clp(flete?.costo_actual) }}
                                            <span class="tiptext"> <b>Costo actual: </b> Suma todos los detalles especificamente el costo de cada flete </span>
                                        </td>
                                        
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Resultado</th>
                                        <td colspan="2" class="tooltipEstado top"> {{ money_clp(flete?.resultado) }}
                                            <span class="tiptext"> <b>Resultado: </b>presupuestado - costo actual</span>
                                        </td>
                                        
                                        <td></td>
                                    </tr>
                                </tbody>
    
                                <!-- SEPARADOR 4 -->
    
                                <!-- <pre>{{ mdo | json }}</pre> -->
                                <thead class="thead-dark">
                                    <tr>
                                        <th colspan="4" class="text-center">MANO DE OBRA Y SALDO ACTUAL</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th>Comisión</th>
                                        <td colspan="2" class="tooltipEstado top">{{ money_clp(mdo?.comision)}}
                                            <span class="tiptext"> <b>comision: </b>Comision del proyecto</span>
                                        </td>
                                        
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Mano de obra</th>
                                        <td colspan="2" class="tooltipEstado top">{{ money_clp(mdo?.mano_obra) }}
                                            <span class="tiptext"> <b>Mano de obra: </b>mano de obra cancelada</span>
                                        </td>
                                        
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>presupuesto Mano de obra</th>
                                        <td colspan="2" class="tooltipEstado top">{{ money_clp(mdo?.presupuesto_mano_obra) }}
                                            <span class="tiptext"> <b>Presupuesto Mano de obra: </b>mano de obra presupuestada</span>
                                        </td>
                                        
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Resultado</th>
                                        <td colspan="2" class="tooltipEstado top">{{ money_clp(mdo?.resultado) }}
                                            <span class="tiptext"> <b>Resultado: </b>suma de comision y mano de obra</span>
                                        </td>
                                        
                                        <td></td>
                                    </tr>
                                </tbody>

                                <thead class="thead-dark">
                                    <tr>
                                        <th colspan="4" class="text-center">saldo actual - RESUMEN</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th>Gasto general</th>
                                        <td colspan="2" class="tooltipEstado top">
                                            {{money_clp(gasto_general((compra_p + compra_np),(flete?.costo_actual),(mdo?.resultado)))}}
                                            <span class="tiptext"> <b>Gasto general: </b>suma de resultado de porcentaje de ahorro/perdida, costo actual de flete, resultado de mano de obra y saldo actual </span>
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>saldo actual del proyecto</th>
                                        <td colspan="2" class="tooltipEstado top">
                                            {{ money_clp(saldo_actual(cobro, gasto_general((compra_p + compra_np),(flete?.costo_actual),(mdo?.resultado)))) }}
                                            <span class="tiptext"> <b>Gasto general: </b>resta de Cancelado de las compra, Gasto genera de saldo actual</span>
                                        </td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

        <!-- suma de resultado de porcentaje de ahorro/perdida, costo actual de flete, resultado de mano de obra y saldo actual -->

        </div>

    </div>
    <!-- ver porcentajes -->
    <ng-template #ver_porcentaje let-c="close" let-d="dismiss">
        <div class="row">
          <div class="col">
            <div class="cp-modal-header modal-header text-center" id="demoFont">
              <h4 class="modal-title" id="modal-basic-title">Porcentaje</h4>
              <button id="closeModalButton" type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
                <div class="cp-panel-head">
                    <div align="center">
                      
                      Lista
                  
                    </div>
                  </div>
                  <div class="cp-panel-body">
                      <table class="table">
                          <thead>
                              <tr>
                                  <td>Fecha</td>
                                  <td>Acierto</td>
                                  <td>Error</td>
                              </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let i of porcentajes">
                                <td>{{ i.createdAt | date:'dd/MM/yyyy':'+0000':'es-CL' }}</td>
                                <td class="porc_positivo">{{ i.acierto+'%' }}</td>
                                <td class="porc_negativo">{{ i.error+'%' }}</td>
                            </tr>
                        </tbody>
                      </table>
                  </div>
            </div>
          </div>
        </div>
      </ng-template>
</div>
<app-footer></app-footer>