

  <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <div class="navbar-brand" [routerLink]="['/auth/home']">
      CONSTRUPLANET <!-- <img class="logo-construplanet"> -->
    </div>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/auth/home']" routerLinkActive="router-link-active"><i class="fas fa-home"></i> Home</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" aria-current="page" [routerLink]="['/auth/formulario-cliente']" routerLinkActive="router-link-active"><i class="fas fa-user"></i> Clientes</a>
        </li>

        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="fas fa-hammer"></i> Proyectos
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown">
            <a class="dropdown-item" [routerLink]="['/auth/formulario-proyecto']" routerLinkActive="router-link-active"><i class="far fa-file-alt"></i> Formulario</a>
            <a class="dropdown-item" [routerLink]="['/auth/lista-proyecto']" routerLinkActive="router-link-active"><i class="fas fa-list"></i> Listado</a>
            <!-- <div class="dropdown-divider"></div> -->
          </div>
        </li>

        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="fas fa-dollar-sign"></i> Presupuestos
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown">
            <a class="dropdown-item" [routerLink]="['/auth/formulario-presupuesto']" routerLinkActive="router-link-active"><i class="far fa-file-alt"></i> Formulario</a>
            <a class="dropdown-item" [routerLink]="['/auth/existente-presupuesto']" routerLinkActive="router-link-active"><i class="fas fa-list"></i> Listado</a>
            <!-- <div class="dropdown-divider"></div> -->
          </div>
        </li>

        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="fas fa-warehouse"></i> Proveedores
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown">
            <a class="dropdown-item" [routerLink]="['/auth/formulario-proveedor']" routerLinkActive="router-link-active" routerLinkActive="router-link-active"><i class="far fa-file-alt"></i> FORMULARIO</a>
            <a class="dropdown-item" [routerLink]="['/auth/lista-proveedor']" routerLinkActive="router-link-active" routerLinkActive="router-link-active"><i class="fas fa-list"></i> LISTADO</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" [routerLink]="['/auth/lista-materiales']" routerLinkActive="router-link-active"><i class="fas fa-tools"></i> LISTA DE MATERIALES</a>
          </div>
        </li>

        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="fas fa-cash-register"></i> GASTOS GENERALES
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown">
            <a class="dropdown-item" [routerLink]="['/auth/formulario-gastos-generales']" routerLinkActive="router-link-active"><i class="far fa-file-alt"></i> FORMULARIO</a>
            <a class="dropdown-item" [routerLink]="['/auth/lista-gastos-generales']" routerLinkActive="router-link-active"><i class="fas fa-list"></i> LISTADO</a>

          </div>
        </li>

      </ul>

      <ul class="navbar-nav mb-2 mb-lg-0">

        <!-- <form class="form-inline my-2 my-lg-0 mx-auto">
          <input class="form-control mr-sm-2" type="search" placeholder="busqueda" aria-label="Search">
          <button class="btn btn-outline-success my-2 my-sm-0" type="submit"><i class="fas fa-search"></i></button>
        </form> -->


        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="fas fa-user-cog"></i> {{usuario.usuario}}
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown">
            <a style="cursor: pointer" class="dropdown-item" (click)="salir();">Salir</a>
            <!-- <a class="dropdown-item" href="#">Another action</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="#">Something else here</a> -->
          </div>
        </li>

      </ul>

    </div>
  </nav>
