import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ProyectoService } from 'src/app/shared/services/proyecto.service';
import { CobroService } from 'src/app/shared/services/cobro.service';
import { DecimalPipe } from '@angular/common';
import Swal from 'sweetalert2';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-pago-proyecto',
  templateUrl: './pago-proyecto.component.html',
  styleUrls: ['./pago-proyecto.component.css']
})
export class PagoProyectoComponent implements OnInit {

  public formulario: FormGroup;
  proyecto_id = this._route.snapshot.params.id;
  proyecto;
  list_cobros:any=[];
  suma_cobros=0;
  txt_obs='pronto se verá aqui la observación, saludos!';
  constructor(private _route:ActivatedRoute,
              private _proyecto:ProyectoService,
              private fb:FormBuilder ,
              public DecimalPipe:DecimalPipe,
              public _cobrar:CobroService,
              config: NgbModalConfig, 
              private modal:NgbModal,
  ) { }

  ngOnInit(): void {
    
    this.get_proyecto(this.proyecto_id)
    this.construccion_form();
    this.listar_cobros();
    
  }

  construccion_form(){
    this.formulario = this.fb.group({
      id_proyecto:[this.proyecto_id, Validators.required],
      valor:['', Validators.required],
      fecha_pago:['', Validators.required],
      observacion:['']
    });

    this.dibujar_moneda();
  }

  cobrar($event:MouseEvent){
    ($event.target as HTMLButtonElement).disabled = true;
    
    if(!this.formulario.valid){
      Swal.fire(
        'Faltan campos por llenar!',
        '',
        'error'
      );
      ($event.target as HTMLButtonElement).disabled = false;
      return false;
    }

    const data = {
      id_proyecto: this._form('id_proyecto'),
      valor: this._form('valor').replace(/[$.]/g,''),
      fecha_pago: this._form('fecha_pago'),
      observacion: this._form('observacion')
    };

    this._cobrar.cobrar(data).subscribe(res => {
      if(res.estado){
        Swal.fire(
          'Cobro ingresado!',
          '',
          'success'
        );
        ($event.target as HTMLButtonElement).disabled = false;
        this.listar_cobros();
        this.construccion_form();
      }else{
        Swal.fire(
          'Algo salió mal 1!',
          '',
          'error'
        );
        ($event.target as HTMLButtonElement).disabled = false;
      }
    }, error=>{
      Swal.fire(
        'Algo salió mal 2!',
        '',
        'error'
      );
      ($event.target as HTMLButtonElement).disabled = false;
    });
  }

  listar_cobros(){
    this._cobrar.listar_cobros(this.proyecto_id).subscribe(res=>{
      if(res.estado){
        this.list_cobros = res.pagos;
        this.suma_cobros = this.list_cobros.map(item => item.valor).reduce((prev, next) => prev + next);
        // console.log('sum',this.suma_cobros);
      }
    });
  }


  get_proyecto(id){
    this._proyecto.listar().subscribe(res=>{

      let filtro = res.proyectos.filter(function(item){
        return (item.id == id)?item:null;
      });
      this.proyecto = filtro[0]
    });
  }

  money_clp(value){
    // console.log(value);
    return '$ '+this.DecimalPipe.transform(value)?.replace(/,/g,'.')
  }

  open_modal(ref){
    // console.log("open=>",ref);
    this.modal.open(ref, { size: 'md' ,  windowClass: 'big-modal-none'});
  }

  _form(name){
    return this.formulario.get(name).value;
  }

  dibujar_moneda(){
    this.formulario.valueChanges.subscribe( form => {
      console.log("mi-form=>",form);
      if(form.valor){
        this.formulario.patchValue({
          valor: this.DecimalPipe.transform(form.valor.replace(/\D/g,'')?.replace(/^0/,''))?.replace(/,/g,'.')
          //minimo: (this.currencyPipe.transform(form.minimo.replace(/\D/g,'').replace(/^0/,''),'USD','symbol','1.0-0').replace("$", "")).toString().replace(/,/g, ".")
        },{emitEvent:false}
        );
      }
    });
  }

}
