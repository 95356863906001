import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DecimalPipe } from '@angular/common';
import { MaterialesService } from '../../../../shared/services/materiales.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-editar-materiales',
  templateUrl: './editar-materiales.component.html',
  styleUrls: ['./editar-materiales.component.css']
})
export class EditarMaterialesComponent implements OnInit {

  @Input() material: any = null;
  @Output() salida = new EventEmitter<string>();
  btn:boolean=false;
  form:FormGroup;
  constructor(private fb:FormBuilder, private DecimalPipe:DecimalPipe, private _material:MaterialesService) { }

  ngOnInit(): void {
    this.construir_form();
  }

  construir_form() {
    
    this.form = this.fb.group({
      id: [this.material.id_material],
      material: [this.material.material, Validators.required],
      precio: ['', Validators.required],
      fecha:['', Validators.required]
    });
    this.dibujar_moneda();
    this.form.patchValue({//no es necesario poner [] en los fields
      precio:this.material?.precio,
      
    });
  }
  dibujar_moneda() {
    this.form.valueChanges.subscribe((form) => {
      console.log('mi-form=>', form);
      if (form.precio) {
        this.form.patchValue(
          {
            precio: this.DecimalPipe.transform(
              form.precio.toString().replace(/\D/g, '')?.replace(/^0/, '')
            )?.replace(/,/g, '.'),
            //minimo: (this.currencyPipe.transform(form.minimo.replace(/\D/g,'').replace(/^0/,''),'USD','symbol','1.0-0').replace("$", "")).toString().replace(/,/g, ".")
          },
          { emitEvent: false }
        );
      }
    });
  }
  
  update(){
    this.btn = true;
    if(this.form.invalid){
      Swal.fire('Faltan campos por llenar', '', 'error');
      this.btn = false;
      return false;
    }
    this.form.controls['precio'].setValue(this.form.controls.precio.value.replace(/[$.]/g,'')+'kk');
   
    const data = {
      id: this.form.controls.id.value,
      material: this.form.controls.material.value,
      precio: this.form.controls.precio.value.replace(/[$.]/g,''),
      fecha:this.form.controls.fecha.value,
      activo:'true'
    }
    this._material.actualizar_material(data).subscribe(res=>{
      if (res.estado) {
        Swal.fire(''+res.mensaje+'', '', 'success');
        //this.construir_form();
        this.btn = false;
        this.salida.emit('true');
        
      }
    }, error =>{
      this.btn = false;
      Swal.fire('Algo salió mal', '', 'error');
    })
    
  }

}
