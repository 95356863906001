import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProyectoService {

  private apiUrl = environment.url;
  constructor(private http: HttpClient) { }

  listar():Observable<any>{
    var url = `${this.apiUrl}/proyectos`;
    return this.http.get(url);
  }
  guardar(data):Observable<any>{
    var url = `${this.apiUrl}/proyectos`;
    return this.http.post(url, data);
  }

  update(data):Observable<any>{
    var url = `${this.apiUrl}/proyectos`;
    return this.http.put(url, data);
  }

  guardar_porcentaje(data):Observable<any>{
    var url = `${this.apiUrl}/proyectos/porcentaje`;
    return this.http.post(url, data);
  }

  traer_porcentaje(proyecto_id):Observable<any>{
    var url = `${this.apiUrl}/proyectos/porcentaje/${proyecto_id}`;
    return this.http.get(url);
  }

  eliminar_proyecto(data):Observable<any>{
    const options = {
      headers: new HttpHeaders({
         'Content-Type': 'application/json',
      }),
      body: data,
   };
    var url = `${this.apiUrl}/proyectos`;
    return this.http.delete(url, options);
  }

  
}
