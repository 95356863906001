import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PresupuestoService } from 'src/app/shared/services/presupuesto.service';
import Swal from 'sweetalert2';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'app-opcion-agregar-item-presupuesto',
  templateUrl: './opcion-agregar-item-presupuesto.component.html',
  styleUrls: ['./opcion-agregar-item-presupuesto.component.css']
})
export class OpcionAgregarItemPresupuestoComponent implements OnInit {

  @Input() input_presupuesto;
  @Input() input_presupuesto_txt;
  @Input() input_proyecto_txt;

  public form_item: FormGroup;

  estado=[
    {id:1, estado:'Presupuestado', color:'blue'},
    {id:2, estado:'No presupuestado', color:'red'}
  ];
  tipos=[
    {id:1, tipo:'Materiales'},
    {id:2, tipo:'Mano de obra'},
    {id:3, tipo:'Maquinarias, herramientas y equipos'},
    {id:4, tipo:'Comision'},
  ];
  unidades=[
    {id:99, unidad:'S/U'},
    {id:1, unidad:'Unidad'},
    {id:2, unidad:'Caja'},
    {id:3, unidad:'Gl'},
    {id:4, unidad:'Kg'},
    {id:5, unidad:'Bolsas'}
  ];
  tbla_presupuestos_exist=false;
  no_mostrar_cv=false;
  btn_insert:boolean=false;
  constructor(
    private fb: FormBuilder,
     private _presupuesto: PresupuestoService,  
    public DecimalPipe:DecimalPipe,) { }

  ngOnInit(): void {
    this.construccion_form();
    this.form_item.controls.id_presupuesto.setValue(this.input_presupuesto);
    this.form_item.controls.estado.setValue("1");
  }

  construccion_form(){
    this.form_item = this.fb.group({
      estado:[null, Validators.required],
      id_presupuesto:[null, Validators.required],
      tipo:[null, Validators.required],
      item:['', Validators.required],
      unidad:[99],
      ml:[''],
      m2:[''],
      m3:[''],
      // m_total:[''], // opcional 
      cantidad:['', Validators.required],
      precio:['', Validators.required],
      total:[''], //opcional
    });

    this.dibujar_moneda();
  }

  m(){
    if(this.form_item.controls.ml.value?.trim()==''
      &&this.form_item.controls.m2.value?.trim()==''
      &&this.form_item.controls.m3.value?.trim()==''){
      this.form_item.controls.cantidad.setValue('');
      return false;
    }

    let ml=(this.form_item.controls.ml.value == 0 || this.form_item.controls.ml.value == '' )?1:this.form_item.controls.ml.value;
    let m2=(this.form_item.controls.m2.value == 0 || this.form_item.controls.m2.value == '' )?1:this.form_item.controls.m2.value;
    let m3=(this.form_item.controls.m3.value == 0 || this.form_item.controls.m3.value == '' )?1:this.form_item.controls.m3.value;

    this.form_item.controls.cantidad.setValue(ml * m2 * m3);
  }

  t(){
    let cantidad = this.form_item.controls.cantidad.value;
    let  valor = this.form_item.controls.precio.value?.replace(/[$.]/g,'')
    console.log("valor->",valor)
    this.form_item.controls.total.setValue(cantidad * valor);
  }

  ch_proyecto(event){

    this.tbla_presupuestos_exist = true;
    console.log('ch->',event)
    if(event.id == 4){
      this.no_mostrar_cv = true;
      this.form_item.controls.ml.setValue('0');
      this.form_item.controls.m2.setValue('0');
      this.form_item.controls.m3.setValue('0');
      this.form_item.controls.cantidad.setValue('0');
      
      this.form_item.controls.precio.setValue('0');
      this.form_item.controls.total.setValue('0');
    }else{
      this.no_mostrar_cv = false;
      this.form_item.controls.ml.setValue('');
      this.form_item.controls.m2.setValue('');
      this.form_item.controls.m3.setValue('');
      this.form_item.controls.cantidad.setValue('');
      
      this.form_item.controls.precio.setValue('');
      this.form_item.controls.total.setValue('');
    }

    
  }

  insert_item(){
    this.btn_insert = true;
    if(this.form_item.valid){
      const data={
        estado: this._form('estado'),
        id_presupuesto: this._form('id_presupuesto'),
        tipo: this._form('tipo'),
        item: this._form('item'),
        unidad: this._form('unidad'),
        ml: this._form('ml'),
        m2: this._form('m2'),
        m3: this._form('m3'),
        cantidad: this._form('cantidad'),
        precio: this._form('precio')?.replace(/[$.]/g,''),
        total: (this._form('tipo')=='4')? this._form('total')?.replace(/[$.]/g,''):0,
      };
      
      
      this._presupuesto.guardar_detalle_presupuesto(data).subscribe(res=>{
        if(res.estado){
          this.limpiar_form();
          Swal.fire(
            'Item ingresado!',
            'Cierre para volver a ingresar más',
            'success'
          );
          this.btn_insert=false;
          this.limpiar_form();
        }else{
          Swal.fire(
            'Error de ingreso!',
            '',
            'error'
          )
          this.btn_insert=false;
          return false;
        }
      }, error=>{
        Swal.fire(
          'Error de ingreso!',
          '',
          'error'
        )
        this.btn_insert=false;
        return false;
      });
    }else{
      Swal.fire(
        'Error de ingreso!',
        '',
        'error'
      )
      this.btn_insert=false;
      return false;
    }
   

  }
  limpiar_form(){
      this.form_item.controls.estado.setValue(null);
      this.form_item.controls.tipo.setValue(null);
      this.form_item.controls.item.setValue('');
      this.form_item.controls.unidad.setValue(99);
      this.form_item.controls.ml.setValue('');
      this.form_item.controls.m2.setValue('');
      this.form_item.controls.m3.setValue('');
      this.form_item.controls.cantidad.setValue('');
     
      this.form_item.controls.precio.setValue('');
      this.form_item.controls.total.setValue('');

  }

  ch_estado(event){
    console.log(event);
    //no presupuestado
    if(event == 2){
      this.form_item.controls.ml.setValue('0');
      this.form_item.controls.m2.setValue('0');
      this.form_item.controls.m3.setValue('0');
      this.form_item.controls.cantidad.setValue('0');
      
      this.form_item.controls.precio.setValue('0');
      this.form_item.controls.total.setValue('0');
    }
    if(event == 1){
      this.form_item.controls.ml.setValue('');
      this.form_item.controls.m2.setValue('');
      this.form_item.controls.m3.setValue('');
      this.form_item.controls.cantidad.setValue('');
      
      this.form_item.controls.precio.setValue('');
      this.form_item.controls.total.setValue('');
    }

    if(this.form_item.controls.tipo.value == 4){
      // alert("es comision");
      this.form_item.controls.ml.setValue('0');
      this.form_item.controls.m2.setValue('0');
      this.form_item.controls.m3.setValue('0');
      this.form_item.controls.cantidad.setValue('0');
     
      this.form_item.controls.precio.setValue('0');
      this.form_item.controls.total.setValue('0');
    }
  }

  _form(name){
    return this.form_item.get(name).value;
  }

  dibujar_moneda(){
    this.form_item.valueChanges.subscribe( form => {
      console.log("mi-form=>",form);
      if(form.precio){
        this.form_item.patchValue({
          precio: this.DecimalPipe.transform(form.precio.replace(/\D/g,''))?.replace(/,/g,'.'),
          
        },{emitEvent:false}
        );
      }

      if(form.total){
        var total =""+form.total;
        console.log("ttottal->",total)
        this.form_item.patchValue({
          
           total: this.DecimalPipe.transform(total.replace(/\D/g,''))?.replace(/,/g,'.')
        },{emitEvent:false}
        );
      }
    });
  }

  

}
