<div class="cp-panel-head">
  <div align="center">
    {{ input_presupuesto_txt }}

  </div>
</div>
<div class="cp-panel-body">

  <div class="row">
    <div class="col-md-10">
      <input type="text" class="form-control" [(ngModel)]="search" (keydown.enter)="filterItems(search)"
        placeholder="buscar por item..">
    </div>
    <div class="col-md-1">
      <button class="btn btn-light" (click)="listar(); search='';">
        <i class="fas fa-sync-alt"></i>
      </button>
    </div>
  </div>

  <div class="table-responsive">

    <!-- tabla materiales -->
    <table *ngIf="list_materiales.length > 0" class="mt-4 table table-bordered">
      <thead class="thead-dark">
        <tr>
          <th class="text-center" colspan="19">
            {{ 'Materiales' }}
          </th>
        </tr>
      </thead>

      <thead class="thead-dark">
        <tr class="text-center">
          <!-- <td>Tipo</td> -->
          <th></th>
          <th colspan="2">Item</th>
          <th>Unidad</th>
          <th>Ml</th>
          <th>M2</th>
          <th>M3</th>
          <!-- <th>Total</th> -->
          <th>Cant.</th>
          <th>Precio</th>
          <th colspan="2">T. ppto.</th>
          <th>Cant.</th>
          <th colspan="2">T. compra</th>
          <th>Resultado</th>
          <th colspan="4">Opciones</th>
        </tr>
      </thead>

      <tr class="text-center" *ngFor="let i of list_materiales | ordenarPor:'estado'"
        [ngStyle]="{'background':(i.estado==1)?'#EBF5FB' : '#FDEDEC'}">
        <!-- <td>{{ i.tipo | tipoPresupuesto }}</td> -->
        <td class="tooltipEstado top" [ngStyle]="{'background-color':(i.estado==1)?'#5DADE2' : '#EC7063'}">
          <span class="tiptext" *ngIf="i.estado == 1">presupuestado</span>
          <span class="tiptext" *ngIf="i.estado != 1">no presupuestado</span>
          <!-- {{ i.estado | estadoDetallePresupuesto }} -->
        </td>
        <td colspan="2" class="text-left">{{ i.item }}</td>
        <td>{{ i.unidad | unidades }}</td>
        <td>{{ i.ml }}</td>
        <td>{{ i.m2 }}</td>
        <td>{{ i.m3 }}</td>
        <!-- <td>{{ calculo_m(i.ml, i.m2, i.m3) }}</td> -->
        <td>{{ i.cantidad }}</td>
        <td>{{ money_clp(i.precio) }}</td>
        <td colspan="2">{{ money_clp(i.cantidad * i.precio) }}</td>
        <td class="tope">{{ (i['compra-presupuesto']==null)?'--': ( i['compra-presupuesto']?.cantidad ) }}</td>
        <td colspan="2">{{ (i['compra-presupuesto']==null)?'--': (money_clp((i['compra-presupuesto']?.iva))) }}</td>
        <td>{{ (i['compra-presupuesto']==null)?'--': (money_clp((i.cantidad * i.precio) -
          (i['compra-presupuesto']?.iva))) }}</td>
        <td>


          <button class="btn btn-sm btn-success tooltipCustom top"
            (click)="
            actualiza=(i['compra-presupuesto']==null)?null:i['compra-presupuesto'];
            tipo_presupuesto='';
            item_texto=i.item;
            id_detalle=i.id;
            open_modal_compra(compra);">
            <i class="fas fa-shopping-cart"></i>
            <span class="tiptext">comprar</span>
          </button>

        </td>
        <td>
          <button class="btn btn-sm btn-info tooltipCustom top"
            (click)="tipo_presupuesto='';compra_presupuesto=i['compra-presupuesto'];_open_modal(ver_compra,'md');">
            <i class="fas fa-eye"></i>
            <span class="tiptext">ver compra</span>
          </button>

        </td>
        <td>
          <button *ngIf="(i['compra-presupuesto']==null)" 
          (click)="_open_modal(editar,'xl');data=i;comision=null;"
          class="btn btn-sm btn-primary tooltipCustom top">
            <i class="fas fa-edit"></i>
          </button>
        </td>

        <td>
          <button *ngIf="(i['compra-presupuesto']==null)"
            (click)="eliminar(i.id)"
            class="btn btn-sm btn-danger tooltipCustom top">
            <i class="fas fa-trash-alt"></i>
          </button>
        </td>

      </tr>

    </table>


    <!-- tabla mano de obras -->
    <table *ngIf="list_mano_obra.length > 0" class="mt-4 table table-bordered">
      <thead class="thead-dark">
        <tr>
          <th class="text-center" colspan="19">{{ 'Mano de obras' }}</th>
        </tr>
      </thead>
      <thead class="thead-dark">
        <tr class="text-center">
          <!-- <td>Tipo</td> -->
          <th></th>
          <th colspan="2">Item</th>
          <!-- <th>Unidad</th>
          <th>Ml</th>
          <th>M2</th>
          <th>M3</th> -->
          <!-- <th>Total</th> -->
          <th>Cant.</th>
          <th>Precio</th>
          <th colspan="2">T. ppto.</th>
          <!-- <th>Cant.</th> -->
          <th colspan="2">T. compra</th>
          <th>Resultado</th>
          <th colspan="4">Opciones</th>
        </tr>
      </thead>
      <tr class="text-center" *ngFor="let i of list_mano_obra | ordenarPor:'estado'"
      [ngStyle]="{'background':(i.estado==1)?'#EBF5FB' : '#FDEDEC'}">
        <!-- <td>{{ i.tipo | tipoPresupuesto }}</td> -->
        <td class="tooltipEstado top" [ngStyle]="{'background-color':(i.estado==1)?'#5DADE2' : '#EC7063'}">
          <span class="tiptext" *ngIf="i.estado == 1">presupuestado</span>
          <span class="tiptext" *ngIf="i.estado != 1">no presupuestado</span>
          <!-- {{ i.estado | estadoDetallePresupuesto }} -->
        </td>
        <td colspan="2" class="text-left">{{ i.item }}</td>
        <!-- <td>{{ i.unidad | unidades }}</td> -->
        <!-- <td>{{ i.ml }}</td> -->
        <!-- <td>{{ i.m2 }}</td> -->
        <!-- <td>{{ i.m3 }}</td> -->
        <!-- <td>{{ calculo_m(i.ml, i.m2, i.m3) }}</td> -->
        <td>{{ i.cantidad }}</td>
        <td>{{ money_clp(i.precio) }}</td>
        <td colspan="2">{{ money_clp(i.cantidad * i.precio) }}</td>
        <!-- <td class="tope">{{ (i['compra-presupuesto']==null)?'--': ( i['compra-presupuesto']?.cantidad ) }}</td> -->
        <td colspan="2" class="tope">{{ (i['compra-presupuesto']==null)?'--': (money_clp((i['compra-presupuesto']?.iva))) }}</td>
        <td>{{ (i['compra-presupuesto']==null)?'--': (money_clp((i.cantidad * i.precio) -
          (i['compra-presupuesto']?.iva))) }}</td>
        <td>

          <button class="btn btn-sm btn-success tooltipCustom top"
            (click)="
            actualiza=(i['compra-presupuesto']==null)?null:i['compra-presupuesto'];
            tipo_presupuesto='mano_obra';
            item_texto=i.item;
            id_detalle=i.id;
            open_modal_compra(compra);">
            <i class="fas fa-shopping-cart"></i>
            <span class="tiptext">comprar</span>
          </button>
        </td>

        <td>
          <button class="btn btn-sm btn-info tooltipCustom top"
            (click)="tipo_presupuesto='mano_obra';compra_presupuesto=i['compra-presupuesto'];_open_modal(ver_compra,'md');">
            <i class="fas fa-eye"></i>
            <span class="tiptext">ver compra</span>
          </button>
        </td>

        <td>
          <button *ngIf="(i['compra-presupuesto']==null)"
          (click)="_open_modal(editar,'xl');data=i;comision=null;"
          class="btn btn-sm btn-primary tooltipCustom top">
            <i class="fas fa-edit"></i>
          </button>
        </td>

        <td>
          <button *ngIf="(i['compra-presupuesto']==null)"
          (click)="eliminar(i.id)"
          class="btn btn-sm btn-danger tooltipCustom top">
            <i class="fas fa-trash-alt"></i>
          </button>
        </td>
      </tr>

    </table>

    <!-- tabla maquinarias -->

    <table *ngIf="list_maquinaria.length > 0" class="mt-4 table table-bordered">
      <thead class="thead-dark">
        <tr>
          <th class="text-center" colspan="19">{{ 'Maquinarias, herramientas y equipos' }}</th>
        </tr>
      </thead>
      <thead class="thead-dark">
        <tr class="text-center">
          <!-- <td>Tipo</td> -->
          <th></th>
          <th colspan="2">Item</th>
          <th>Unidad</th>
          <th>Ml</th>
          <th>M2</th>
          <th>M3</th>
          <!-- <th>Total</th> -->
          <th>Cant.</th>
          <th>Precio</th>
          <th colspan="2">T. ppto.</th>
          <th>Cant.</th>
          <th colspan="2">T. compra</th>
          <th>Resultado</th>
          <th colspan="4">Opciones</th>
        </tr>
      </thead>
      <tr class="text-center" *ngFor="let i of list_maquinaria | ordenarPor:'estado'"
      [ngStyle]="{'background':(i.estado==1)?'#EBF5FB' : '#FDEDEC'}">
        <!-- <td>{{ i.tipo | tipoPresupuesto }}</td> -->
        <td class="tooltipEstado top" [ngStyle]="{'background-color':(i.estado==1)?'#5DADE2' : '#EC7063'}">
          <span class="tiptext" *ngIf="i.estado == 1">presupuestado</span>
          <span class="tiptext" *ngIf="i.estado != 1">no presupuestado</span>
          <!-- {{ i.estado | estadoDetallePresupuesto }} -->
        </td>
        <td colspan="2" class="text-left">{{ i.item }}</td>
        <td>{{ i.unidad | unidades }}</td>
        <td>{{ i.ml }}</td>
        <td>{{ i.m2 }}</td>
        <td>{{ i.m3 }}</td>
        <!-- <td>{{ calculo_m(i.ml, i.m2, i.m3) }}</td> -->
        <td>{{ i.cantidad }}</td>
        <td>{{ money_clp(i.precio) }}</td>
        <td colspan="2">{{ money_clp(i.cantidad * i.precio) }}</td>
        <td class="tope">{{ (i['compra-presupuesto']==null)?'--': ( i['compra-presupuesto']?.cantidad ) }}</td>
        <td colspan="2">{{ (i['compra-presupuesto']==null)?'--': (money_clp((i['compra-presupuesto']?.iva))) }}</td>
        <td>{{ (i['compra-presupuesto']==null)?'--': (money_clp((i.cantidad * i.precio) -
          (i['compra-presupuesto']?.iva))) }}</td>
        <td>


          <button class="btn btn-sm btn-success tooltipCustom top"
            (click)="
            actualiza=(i['compra-presupuesto']==null)?null:i['compra-presupuesto'];
            tipo_presupuesto='';
            item_texto=i.item;
            id_detalle=i.id;
            open_modal_compra(compra);">
            <i class="fas fa-shopping-cart"></i>
            <span class="tiptext">comprar</span>
          </button>
        </td>

        <td>
          <button class="btn btn-sm btn-info tooltipCustom top"
            (click)="tipo_presupuesto='';compra_presupuesto=i['compra-presupuesto'];_open_modal(ver_compra,'md');">
            <i class="fas fa-eye"></i>
            <span class="tiptext">ver compra</span>
          </button>
        </td>
        <td>
          <button *ngIf="(i['compra-presupuesto']==null)"
          (click)="_open_modal(editar,'xl');data=i;comision=null;"
           class="btn btn-sm btn-primary tooltipCustom top">
            <i class="fas fa-edit"></i>
          </button>
        </td>

        <td>
          <button *ngIf="(i['compra-presupuesto']==null)"
          (click)="eliminar(i.id)"
          class="btn btn-sm btn-danger tooltipCustom top">
            <i class="fas fa-trash-alt"></i>
          </button>
        </td>
      </tr>

    </table>

    <!-- tabla comision -->

    <table *ngIf="list_comision.length > 0" class="mt-4 table table-bordered">
      <thead class="thead-dark">
        <tr>
          <th class="text-center" colspan="16">{{ 'Comision' }}</th>
        </tr>
      </thead>
      <thead class="thead-dark">
        <tr class="text-center">
          <!-- <td>Tipo</td> -->
          <th></th>
          <th>Item</th>
          
          <th>Total</th>
          
        <th colspan="2">Opciones</th> 
        </tr>
      </thead>
      <tr class="text-center" *ngFor="let i of list_comision | ordenarPor:'estado'">
        <!-- <td>{{ i.tipo | tipoPresupuesto }}</td> -->
        <td [ngStyle]="{'background-color':(i.estado==1)?'#5DADE2' : '#EC7063'}">
          <!-- {{ i.estado | estadoDetallePresupuesto }} -->
        </td>
        <td class="text-left">{{ i.item }}</td>

        <td >{{ money_clp(i.total) }}</td>

        <td>
          <button *ngIf="(i['compra-presupuesto']==null)"
          (click)="_open_modal(editar,'xl');data=i;comision=true"
           class="btn btn-sm btn-primary tooltipCustom top">
            <i class="fas fa-edit"></i>
          </button>
        </td>

        <td>
          <button *ngIf="(i['compra-presupuesto']==null)"
          (click)="eliminar(i.id)"
          class="btn btn-sm btn-danger tooltipCustom top">
            <i class="fas fa-trash-alt"></i>
          </button>
        </td>

      </tr>

    </table>


    <div class="mt-4">
      <div class="row">
        <div class="col-md-4">
          <table class="table table-bordered">
            <thead class="thead-dark">
              <th colspan="2" class="text-center">RESUMEN</th>
            </thead>
            <tbody>
              <tr>
                <td class="table-dark"><i class="fas fa-calculator"></i> Total presupuesto :</td>
                <th>{{ money_clp(total_c_p) }}</th>
              </tr>
              <tr>
                <td class="table-dark"><i class="fas fa-file-invoice-dollar"></i> Total compra :</td>
                <th>{{ money_clp(total_compra) }}</th>
              </tr>

              <tr class="text-white" [ngStyle]="{'background':((total_resultado) >= 0)?'#1F7F34' : '#f55c47'}">
                <td class="table-dark"><i class="fas fa-receipt"></i> Total resultado :</td>
                <th>{{ money_clp(total_resultado) }}</th>
                <!-- <th>{{ money_clp(total_c_p - total_compra) }}</th> -->

              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>






  </div>

  <!-- MODAL COMPRA -->
  <ng-template #compra let-c="close" let-d="dismiss">
    <div class="row">
      <div class="col">
        <div class="cp-modal-header modal-header text-center" id="demoFont">
          <h4 class="modal-title" id="modal-basic-title">Compra de item</h4>
          <button id="closeModalButton" type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <app-formulario-compra 
          (envia)="recibe($event,compra)" 
          [tipo_presupuesto]="tipo_presupuesto" 
          [item_txt]="item_texto" 
          [id_detalle]="id_detalle"
          [actualiza]="actualiza"
          >
          </app-formulario-compra>
          
        </div>
      </div>
    </div>
  </ng-template>

  <!-- MODAL VER COMPRA -->
  <ng-template #ver_compra let-c="close" let-d="dismiss">
    <div class="row">
      <div class="col">
        <div class="cp-modal-header modal-header text-center" id="demoFont">
          <h4 class="modal-title" id="modal-basic-title">Compra de item</h4>
          <button id="closeModalButton" type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <app-ver-presupuesto-compra [compra]="compra_presupuesto" [tipo_presupuesto]="tipo_presupuesto">
          </app-ver-presupuesto-compra>
         
        </div>
      </div>
    </div>
  </ng-template>

  <!-- EDITAR DETALLE PRESUPUESTO -->
  <ng-template #editar let-c="close" let-d="dismiss">
    <div class="row">
      <div class="col">
        <div class="cp-modal-header modal-header text-center" id="demoFont">
          <h4 class="modal-title" id="modal-basic-title">Editar detalle del presupuesto</h4>
          <button id="closeModalButton" type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <app-editar-detalle-presupuesto
            [detalle_presupuesto]="data"
            [comision]="comision"
            (salida)="_recibe($event)"
          >
          </app-editar-detalle-presupuesto>
         
        </div>
      </div>
    </div>
  </ng-template>

</div>
