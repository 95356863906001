<div class="container-fluid">
        <div class="cp-panel-head">
            <div align="center"> Proyecto: {{input_proyecto_txt}}  | Presupuesto: {{ input_presupuesto_txt }}</div>
        </div>
        <div class="cp-panel-body">
            <form [formGroup]="form_item">

               <label for="">Tipo de presupuesto</label>
               <!-- <select    class="form-control" name="" id="">
                   <option value="1">Materiales</option>
                   <option value="2">Mano de obra</option>
                   <option value="3">Maquinareas, herramientas y equipos</option>
               </select> -->

               <ng-select
                    formControlName="tipo"
                    [items]="tipos"
                    bindLabel="tipo"
                    bindValue="id"
                    placeholder="--Seleccione--"
                    (change)="ch_proyecto($event)"
                    single="true"
                >

                </ng-select>


                <label>Estado</label>
                <ng-select
                class="mb-2"
                formControlName="estado"
                placeholder="seleccione"
                [searchable]="true"
                [clearable]="true"
                (change)="ch_estado($event)"
                >
                    <section *ngFor="let i of estado;">
                        <ng-option  value="{{i.id}}">
                            <i [ngClass]="{'text-danger':i.color=='red', 'text-primary':i.color=='blue'}" class="fas fa-square"></i> {{ i.estado }}
                        </ng-option>
                    </section>
                </ng-select>


               <label for="">Item</label>
               <input formControlName="item" class="form-control" type="text" name="" id="">

               <hr>
               <div *ngIf="form_item.controls.tipo.value == 1 || form_item.controls.tipo.value == 3 " class="row">
                   <div class="col-md-3">
                    <label for="">Unidad</label>

                    <ng-select
                        formControlName="unidad"
                        [items]="unidades"
                        bindLabel="unidad"
                        bindValue="id"
                        placeholder="--Seleccione--"
                        single="true"
                    >

                    </ng-select>
                   </div>
                   <div class="col-md-2">
                    <label for="">Ml</label>
                    <input formControlName="ml" (input)="m();" type="text" class="form-control">
                   </div>
                   <div class="col-md-2">
                    <label for="">M2</label>
                    <input formControlName="m2" (input)="m();" type="text" class="form-control">
                   </div>
                   <div class="col-md-2">
                    <label for="">M3</label>
                    <input formControlName="m3" (input)="m();" type="text" class="form-control">
                   </div>
                   <!-- <div class="col-md-3">
                    <label for="">Total</label>
                    <input formControlName="m_total"
                    [value]=""
                      type="text" class="form-control">
                   </div> -->
               </div>
               <hr>
               <div class="row">
                   <div *ngIf="!no_mostrar_cv" class="col-md-4">
                       <label for="">Cantidad</label>
                       <input formControlName="cantidad" (input)="t();" type="text" class="form-control">
                   </div>
                   <div *ngIf="!no_mostrar_cv" class="col-md-4">
                       <label for="">Valor unitario</label>
                       <input formControlName="precio" (input)="t();" type="text" class="form-control">
                   </div>
                   <div class="col-md-4">
                       <label for="">Total</label>
                       <input formControlName="total" type="text" class="form-control">
                   </div>
               </div>
               <hr>

    </form>

     <div align="right">
     <button [disabled]="btn_insert" class="btn btn-success" (click)="insert_item();">Ingresar</button>
    </div>
        </div>




</div>
