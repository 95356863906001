<div class="row justify-content-center">
    <div class="col-md-12">
        <div class="cp-panel-head" align="center">Proveedor/empresa</div>
        <div class="cp-panel-body">
            <div class="row">
                <div class="col-md-6">
                    <label>RUT</label> 
                    <input class="form-control" type="text" value="{{material?.rut}}" disabled>  
                </div>
                <div class="col-md-6">
                    <label>NOMBRE</label> 
                    <input class="form-control" type="text" value="{{material?.nombre}}" disabled> 
                </div>
            </div>
        </div>
        <div class="cp-panel-head mt-3" align="center">Material</div>
        <div class="cp-panel-body">
            <!-- <pre>{{proveedor | json}}</pre>
            <b>RUT:</b> <label>{{proveedor?.rut}}</label><br>
            <b>NOMBRE:</b> <label>{{proveedor?.nombre}}</label><br> -->
            
            <form [formGroup]="form">
                <div class="row">
                    <div class="col-md-12">
                        <label>MATERIAL</label> 
                        <input formControlName="material" class="form-control" type="text" value="" >  
                    </div>
                
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <label>FECHA</label> 
                        <input formControlName="fecha" class="form-control" type="date" value="" >  
                    </div>
                    <div class="col-md-6">
                        <label>PRECIO</label> 
                        <input formControlName="precio" class="form-control" type="text" > 
                    </div>
                </div>
            </form>
            <button [disabled]="btn" (click)="update();" class="btn btn-registrar w-100 mt-2">EDITAR</button>
        </div>
    </div>
</div>