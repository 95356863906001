import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, ActivatedRoute } from '@angular/router';
import { Observable, ReplaySubject } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class PublicGuardGuard implements CanActivate {

  constructor(
    private auth: AuthService, 
    private router: Router,
    private _route: ActivatedRoute
    ){}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    
      if (!this.auth.isAuthenticated()) {
        
        console.log("no logeao")
       
        return true;
       
      }else{
        console.log("logeao")
        this.router.navigate(['auth/home']);
        return false;
      }
     
  }
  
}
