import { HttpClient, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ComprobanteService {

  private apiUrl = environment.url;
  private _url = environment._url;
  constructor(private http: HttpClient) { }

  guardar_comprobante(data):Observable<any>{
    var url = `${this.apiUrl}/presupuestos/documento`;
    return this.http.post(url, data);
  }

  get_comprobante_por_presupuesto(id):Observable<any>{
    console.log("get_comprobantes=> ",this.http.get(url))
    var url = `${this.apiUrl}/presupuestos/documento/${id}`;
    return this.http.get(url);
  }

  comprobante(comprobante):Observable<any>{
    console.log("service: "+comprobante)
    var url = `${this._url}uploads/${comprobante}`;
    return this.http.get(url);
  }

  // getFile(txt): Observable<any>{
  //   const headers = new HttpHeaders({'No-Auth':'True'});
  //   headers.append('Content-Type', 'application/json');
  //   var url = `https://api.construplanet.neofox.cl/uploads/pdf-test-1622777615951.pdf`;

  //   console.log("kill=> ",this.http.get(url))
  //   return this.http.get(url,{headers: headers});
  // }

  getFile(txt:string){
    
    var url = `https://api.construplanet.neofox.cl/api/presupuestos/documento/30`;
    const url2 = 'https://api.construplanet.neofox.cl/uploads/pdf-test-1622777615951.pdf'
    return this.http.get(url);






    
    // const req = this.http.get(url2,{ responseType: 'blob' }).pipe(
    //   map((response:any) => {
    //     console.log("mappeo: ",response)
    //     return response;
    //   }),
    //   catchError((error:any) => {
    //     console.log("su_error", error)
    //     return error;
    //   })
    // );

    // return req;
  }
  get(url: string): Observable<Blob> {
    

    return new Observable((observer) => {
        let objectUrl = null;

        this.http
            .get(url, { responseType: 'blob' })
            .subscribe(m => {
                objectUrl = URL.createObjectURL(m);
                observer.next(objectUrl);
            });

        return () => {
            if (objectUrl) {
                URL.revokeObjectURL(objectUrl);
                objectUrl = null;
            }
        };
    });
}
}
