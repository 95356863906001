import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {ComprobanteService} from 'src/app/shared/services/comprobante.service'
import { environment } from 'src/environments/environment';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { flatMap, map, takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';



@Component({
  selector: 'app-opcion-comprobante',
  templateUrl: './opcion-comprobante.component.html',
  styleUrls: ['./opcion-comprobante.component.css']
})
export class OpcionComprobanteComponent implements OnInit {

  @Input() input_presupuesto;
  @Input() input_presupuesto_txt;
  @Input() input_proyecto_txt;
  data_url:string="/kkck";
  
  @ViewChild('iframe') iframe: ElementRef;
  public isLoading: Boolean;
  public secureSrc: SafeResourceUrl;
  
  private destroy$: Subject<void> = new Subject<void>();

  tabla:any=[];
  btn_registra:boolean=false;

  fileToUpload: File = null;
  public apiUrl = environment._url;

  public form: FormGroup;
  constructor(config: NgbModalConfig,
              private modal:NgbModal,
              private fb: FormBuilder,
              private cd: ChangeDetectorRef,
              private comprobante:ComprobanteService,
              private sanitizer: DomSanitizer,
              ) { }

  ngOnInit(): void {
    this.build_form();
    this.listar_comprobantes();
    // console.log("iframe=>", this.iframe)
  }


  build_form(){
    this.form = this.fb.group({
      id_presupuesto:[this.input_presupuesto, Validators.required],
      n_documento:['',Validators.required],
      documento: [null, Validators.required]
    });
  }

  register(){
    this.btn_registra = true;
    const data = new FormData();
    data.append('id_presupuesto', this.form.get('id_presupuesto').value);
    data.append('n_documento', this.form.get('n_documento').value);
    data.append('documento', this.fileToUpload);

    this.comprobante.guardar_comprobante(data).subscribe(res=>{
      if(res.estado){
        Swal.fire(
          ''+res.mensaje+'',
          '',
          'success'
        );
        this.btn_registra = false;
        this.build_form();
        this.listar_comprobantes();
      }else{
        Swal.fire(
          'Algo salió mal!',
          '',
          'error'
        );
        this.btn_registra = false;
        return false;
      }
    }, error=>{
      Swal.fire(
        'Algo salió mal!',
        '',
        'error'
      );
      this.btn_registra = false;
      return false;
    });
  }

  listar_comprobantes(){
    console.log("listar",this.comprobante.get_comprobante_por_presupuesto(this.input_presupuesto))
    this.comprobante.get_comprobante_por_presupuesto(this.input_presupuesto).subscribe(res=>{
      if(res.estado){
        this.tabla = res.documentos;
      }
    })
  }

   traer_documento(name_doc){

    var xhr = new XMLHttpRequest();
    console.log("test");
    console.log("test");
    xhr.open('GET', 'https://api.construplanetltda.cl/uploads/'+name_doc);
    xhr.onreadystatechange = handler;
    xhr.responseType = 'blob';
    xhr.setRequestHeader('authorization-header', environment.header)
    xhr.setRequestHeader('authorization-token', localStorage.getItem('token')?localStorage.getItem('token'):'')
    ;
    xhr.send();
    
    function handler() {
      if (this.readyState === this.DONE) {
        if (this.status === 200) {
          
          var data_url = URL.createObjectURL(this.response);
             console.log("test->",data_url);
             document.querySelector("iframe").src = data_url
        } else {
          console.error('no pdf :(');
        }
      }
    } 
  }

  open_modal(ref){
    this.modal.open(ref, { size:"lg", windowClass: 'big-modal-none' });
  }

  // onFileChange(event) {
  
  //   if (event.target.files.length > 0) {
  //     const file = event.target.files[0];
  //     this.form.patchValue({
  //       documento: file
  //     });
  //   }
  // }

  onSelectImage(event) {
    this.fileToUpload = event.srcElement.files[0];
  }

  // handleFileInput(files: FileList) {
  //   this.fileToUpload = files.item(0);
  //   // console.log("files", this.fileToUpload)
  //   // console.log("->files", files)
  // }

  // onFileChange(event) {
  //   console.log("abrir")
  //   const reader = new FileReader();
 
  //   if(event.target.files && event.target.files.length) {
  //     const file = event.target.files[0];
  //     console.log("file=>",file)
  //     reader.readAsDataURL(file);
  
  //     reader.onload = () => {
  //       this.form.patchValue({
  //         documento: reader.result
  //      });
      
  //       // need to run CD since file load runs outside of zone
  //       // necesita ejecutar el CD ya que la carga del archivo se ejecuta fuera de la zona
  //       this.cd.markForCheck();
  //       // marcar para comprobar
  //     };
  //   }
  // }

}
