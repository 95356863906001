import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DecimalPipe } from '@angular/common';
import Swal from 'sweetalert2';
import { PresupuestoService } from 'src/app/shared/services/presupuesto.service';

@Component({
  selector: 'app-editar-detalle-presupuesto',
  templateUrl: './editar-detalle-presupuesto.component.html',
  styleUrls: ['./editar-detalle-presupuesto.component.css']
})
export class EditarDetallePresupuestoComponent implements OnInit {
  _detalle_pre:any;
  form_item: FormGroup;
  btn_insert: boolean = false;
  unidades=[
    {id:'99', unidad:'S/U'},
    {id:'1', unidad:'Unidad'},
    {id:'2', unidad:'Caja'},
    {id:'3', unidad:'Gl'},
    {id:'4', unidad:'Kg'},
    {id:'5', unidad:'Bolsas'}
  ];

  @Input() set detalle_presupuesto(data:any){
    if(data){
      this._detalle_pre = data;
    }
  }
  @Input() comision:boolean = null;
  @Output() salida = new EventEmitter<string>();
  constructor(
    private fb:FormBuilder, 
    public DecimalPipe:DecimalPipe,
    private _presupuesto:PresupuestoService  
  ) {

   }

  ngOnInit(): void {
    this.construccion_form();
    this.update_form();
  }
  construccion_form(){
    this.form_item = this.fb.group({
      id:[],
      item:['', Validators.required],
      unidad:[String(this._detalle_pre.unidad)],
      ml:[''],
      m2:[''],
      m3:[''],
      cantidad:['', Validators.required],
      precio:['', Validators.required],
      total:[''], //opcional
    });

    this.dibujar_moneda();
  }
  update_form(){
    this.form_item.patchValue({//no es necesario poner [] en los fields
      id:this._detalle_pre.id,
      item:this._detalle_pre.item,
      unidad:String(this._detalle_pre.unidad),
      ml:this._detalle_pre.ml,
      m2:this._detalle_pre.m2,
      m3:this._detalle_pre.m3,
      cantidad:this._detalle_pre.cantidad,
      precio:String(this._detalle_pre.precio),
      total:(this.comision==null)?String(this._detalle_pre.precio * this._detalle_pre.cantidad):this._detalle_pre.total, //opcional
    });

    this.dibujar_moneda();
  }
  dibujar_moneda(){
    this.form_item.valueChanges.subscribe( form => {
      if(form.precio){
        this.form_item.patchValue({
          precio: this.DecimalPipe.transform(form.precio.replace(/\D/g,''))?.replace(/,/g,'.'),
          
        },{emitEvent:false}
        );
      }
      if(form.total){
        var total =""+form.total;
        this.form_item.patchValue({
           total: this.DecimalPipe.transform(total.replace(/\D/g,''))?.replace(/,/g,'.')
        },{emitEvent:false}
        );
      }
    });
  }
  t(){
    let cantidad = this.form_item.controls.cantidad.value;
    let  valor = this.form_item.controls.precio.value?.replace(/[$.]/g,'')
    this.form_item.controls.total.setValue(cantidad * valor);
  }
  m(){
    if(String(this.form_item.controls.ml.value)?.trim()==''
      &&String(this.form_item.controls.m2.value)?.trim()==''
      &&String(this.form_item.controls.m3.value)?.trim()==''){
      this.form_item.controls.cantidad.setValue('');
      return false;
    }

    let ml=(this.form_item.controls.ml.value == 0 || this.form_item.controls.ml.value == '' )?1:this.form_item.controls.ml.value;
    let m2=(this.form_item.controls.m2.value == 0 || this.form_item.controls.m2.value == '' )?1:this.form_item.controls.m2.value;
    let m3=(this.form_item.controls.m3.value == 0 || this.form_item.controls.m3.value == '' )?1:this.form_item.controls.m3.value;

    this.form_item.controls.cantidad.setValue(ml * m2 * m3);
  }
  _form(name){
    return this.form_item.get(name).value;
  }
  insert_item(){
    this.btn_insert = true;
    if(this.form_item.valid){
      const data={
        
        id: this._form('id'),
        item: this._form('item'),
        unidad: this._form('unidad'),
        ml: this._form('ml'),
        m2: this._form('m2'),
        m3: this._form('m3'),
        cantidad: this._form('cantidad'),
        precio: this._form('precio')?.replace(/[$.]/g,''),
        total: (this._form('total')!='0')? this._form('total')?.replace(/[$.]/g,''):0,
      };
     
      this._presupuesto.actualizar_detalle_presupuesto(data).subscribe(res=>{
        if(res.estado){
          // this.limpiar_form();
          Swal.fire(
            'Item actualizado!',
            'Cierre para volver al detalle',
            'success'
          );
          this.btn_insert=false;
          this.salida.emit('true');
          // this.limpiar_form();
        }else{
          Swal.fire(
            'Error de actualizado!',
            '',
            'error'
          )
          this.btn_insert=false;
          return false;
        }
      }, error=>{
        Swal.fire(
          'Error de actualizado!',
          '',
          'error'
        )
        this.btn_insert=false;
        return false;
      });
    }else{
      Swal.fire(
        'Error de actualizado!',
        '',
        'error'
      )
      this.btn_insert=false;
      return false;
    }
   

  }

}
