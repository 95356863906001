<app-navbar></app-navbar>

<div class="container mt-4">
    <div class="card salmon-outline">

        <div class="card-header cp-card-header">
            <div align="center">FORMULARIO DE PROYECTO</div>
        </div>

        <div class="card-body">
            <form [formGroup]="form">
                <div class="row">
                    <div class="col-md-12">
                        <label for="">Cliente</label>
                        <ng-select formControlName="id_cliente"
                         placeholder="seleccione"
                         [searchable]="true"
                          [clearable]="true" >
                            <section *ngFor="let i of clientes;">
                                <ng-option value="{{i.id}}">
                                    {{'Nº'+i.n_cliente }} - {{ i.nombres+' '+i.apellidos }}
                                </ng-option>
                            </section>
                        </ng-select>

                    </div>

                </div>

                <div class="row mt-2">
                    <div class="col-md-12">
                        <label for="">Proyecto</label>
                        <input formControlName="proyecto" class="form-control" type="text" name="" id="">
                    </div>

                </div>

                <div class="row mt-2">
                    <div class="col-md-6">
                        <label for="">Fecha de inicio</label>
                        <input formControlName="fecha_inicio" class="form-control" type="date" name="" id="">
                    </div>
                    <div class="col-md-6">
                        <label>Estado del proyecto</label>
                        <input class="form-control" type="text" [disabled]="true" value="NO INICIADO">
                    </div>
                </div>

                <div class="row mt-2">
                    <div class="col-md-6">
                        <label>Costo total</label>
                        <input formControlName="total" class="form-control" type="text" name="" id="">
                    </div>
                    <div class="col-md-6">
                        <label for="">Cuotas</label>
                        <input formControlName="cuotas" class="form-control" type="number" name="" id="">
                    </div>
                </div>
            </form>
        </div>
        <div class="card-footer">
          <div class="row">
              <div class="col-12 col-md-6 mt-2">
                <button [disabled]="btn_guarda" (click)="registrar();" class="btn btn-registrar btn-block">Registar</button>
              </div>
              <div class="col-12 col-md-6 mt-2">
                <button class="btn btn-visualizar btn-block" [routerLink]="['/auth/lista-proyecto']"><i class="fas fa-list"></i> Ver proyectos</button>
            </div>
            </div>
        </div>

    </div>
</div>

<app-footer></app-footer>
