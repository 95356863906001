<app-navbar></app-navbar>

<div class="container-fluid mt-4">
    <div class="card salmon-outline">

        <div class="card-header cp-card-header">
            <div class="row">
                <div class="col-4 col-md-2"><a [routerLink]="['/auth/formulario-proveedor']"><i
                            class="fas fa-arrow-left"></i> Volver</a>
                </div>
                <div class="col-7 col-md-8 text-center font-weight-bold">LISTA DE PROVEEDORES</div>
            </div>
        </div>

        <div class="card-body">

            <div class="row">
                <div class="col-md-12">
                    <label for="">PROVEEDOR</label>
                    <ng-select [(ngModel)]="select_prov" (change)="listar($event)" placeholder="seleccione"
                        [searchable]="true">
                        <section *ngFor="let i of proveedores;">
                            <ng-option value="{{i.id}}">
                                {{ i.name }}
                            </ng-option>
                        </section>
                    </ng-select>
                </div>
            </div>

            <div class="table-responsive mt-3">
                <table *ngIf="proveedor == 1" class="table table-bordered">
                    <thead class="thead-dark text-center">
                        <tr class="table-primary">
                            <th>Rut</th>
                            <th>Nombre</th>
                            <th>Giro</th>
                            <th>Contacto</th>
                            <th>Direccion</th>
                            <th >Horario</th>
                            <th>Vendedor</th>
                            <th class="anchoTh">Opciones</th>
                        </tr>
                    </thead>

                    <tr *ngFor="let t of listado" class="text-center">  
                        <td>{{ t.rut }}</td>
                        <td>{{ t.nombre}}</td>
                        <td>{{ t.giro }}</td>
                        <!-- <td>{{ t.fecha_inicio | date:'dd/MM/yyyy':'+0000':'es-CL' }}</td> -->
                        <td>{{ t.contacto }}</td>
                        <!-- <td>{{ t.total | number:'1.0-0' }}</td> -->
                        <td>{{ t.direccion }}</td>
                        <td>{{ t.proveedor_empresa.horario }}</td>
                        <td>{{ t.proveedor_empresa.vendedor }}</td>
                        <td>
                            <button (click)="open_modal(cb, 'lg');datos=t;" class="btn btn-editar tooltipCustom top mx-1">
                                <!-- <i class="fas fa-edit"></i> -->
                                <i class="far fa-credit-card"></i>
                                <span class="tiptext">Cuenta bancaria</span>
                            </button>
                            <button (click)="open_modal(cm, 'md');datos=t;" class="btn btn-editar tooltipCustom top mx-1">
                                <!-- <i class="fas fa-edit"></i> -->
                                <i class="fas fa-hammer"></i>
                                <span class="tiptext">Materiales</span>
                            </button>
                            <button (click)="open_modal_lg(editar);datos=t;" class="btn btn-editar tooltipCustom top mx-1">
                                <!-- <i class="fas fa-edit"></i> -->
                                <i class="fas fa-edit"></i>
                                <span class="tiptext">Editar proveedor</span>
                            </button>
                        </td>
                    </tr>
                </table>

                <table *ngIf="proveedor == 2" class="table table-bordered">
                    <thead class="thead-dark text-center">
                        <tr class="table-primary">
                            <th>Rut</th>
                            <th>Nombre</th>
                            <th>Contacto</th>
                            <th>Direccion</th>
                            <th>Vehiculo</th>
                            <th>Capacidad</th>
                            <th>Tarifa</th>
                            <th class="anchoTh">Opciones</th>
                        </tr>
                    </thead>

                    <tr *ngFor="let t of listado" class="text-center">
                       
                        <td>{{ t.rut }}</td>
                        <td>{{ t.nombre }}</td>
                        <!-- <td>{{ t.fecha_inicio | date:'dd/MM/yyyy':'+0000':'es-CL' }}</td> -->
                        <td>{{ t.contacto }}</td>
                        <!-- <td>{{ t.total | number:'1.0-0' }}</td> -->
                        <td>{{ t.direccion }}</td>
                        <td>{{ t.proveedor_flete.vehiculo }}</td>
                        <td>{{ t.proveedor_flete.capacidad }}</td>
                        <td>{{ money_clp(t.proveedor_flete.tarifa) }}</td>
                        <td>

                            <button (click)="open_modal(cb, 'lg');datos=t;" class="btn btn-editar tooltipCustom top mx-1">
                                <!-- <i class="fas fa-edit"></i> -->
                                <i class="far fa-credit-card"></i>
                                <span class="tiptext">Cuenta bancaria</span>
                            </button>

                            <button (click)="open_modal_lg(editar);datos=t;" class="btn btn-editar tooltipCustom top mx-1">
                                <!-- <i class="fas fa-edit"></i> -->
                                <i class="fas fa-edit"></i>
                                <span class="tiptext">Editar proveedor</span>
                            </button>

                        </td>
                    </tr>
                </table>

                <table *ngIf="proveedor == 3" class="table table-bordered">
                    <thead class="thead-dark text-center">
                        <tr class="table-primary">
                            <th>Rut</th>
                            <th style="width: 5%">Nombre</th>
                            <th>Contacto</th>
                            <th>Direccion</th>
                            <th style="width: 5%">Especialidad</th>
                            <th style="width: 3%">Observacion</th>
                            <th>Opciones</th>
                        </tr>
                    </thead>

                    <tr *ngFor="let t of listado" class="text-center">
                        <td>{{ t.rut }}</td>
                        <td>{{ t.nombre }}</td>
                        <td>{{ t.contacto }}</td>
                        <td>{{ t.direccion }}</td>
                        <td>{{ t.proveedor_maestro.especialidad }}</td>
                        <td>
                            <button class="btn btn-visualizar" (click)="open_modal_md(obs);x=t;construir_fm(x)">
                                <i class="fas fa-eye"></i>
                            </button>
                            
                            
                        </td>
                        <td>

                            <button (click)="open_modal(cb, 'lg');datos=t;" class="btn btn-editar tooltipCustom top mx-1">
                                <!-- <i class="fas fa-edit"></i> -->
                                <i class="far fa-credit-card"></i>
                                <span class="tiptext">Cuenta bancaria</span>
                            </button>

                            <button (click)="open_modal_lg(editar);datos=t;" class="btn btn-editar tooltipCustom top mx-1">
                                <!-- <i class="fas fa-edit"></i> -->
                                <i class="fas fa-edit"></i>
                                <span class="tiptext">Editar proveedor</span>
                            </button>
                        </td>
                    </tr>
                </table>

                <ng-template #cb let-c="close" let-d="dismiss">
                    <div class="row">
                        <div class="col">
                            <div  class="cp-modal-header modal-header text-center" id="demoFont">
                                <h4 class="modal-title" id="modal-basic-title">CUENTA BANCARIA</h4>
                                <button id="closeModalButton" type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                
                                <app-cuenta-bancaria
                                [proveedor]="datos"
                                
                                >

                                </app-cuenta-bancaria>
                            </div>
                        </div>
                    </div>
                </ng-template>

                <ng-template #cm let-c="close" let-d="dismiss">
                    <div class="row">
                        <div class="col">
                            <div  class="cp-modal-header modal-header text-center" id="demoFont">
                                <h4 class="modal-title" id="modal-basic-title">Formulario de materiales</h4>
                                <button id="closeModalButton" type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                
                                <app-formulario-materiales
                                [proveedor]="datos"
                                (salida)="recibe($event)"
                                >

                                </app-formulario-materiales>
                            </div>
                        </div>
                    </div>
                </ng-template>

                <ng-template #editar let-c="close" let-d="dismiss">
                    <div class="row">
                        <div class="col">
                            <div  class="cp-modal-header modal-header text-center" id="demoFont">
                                <h4 class="modal-title" id="modal-basic-title">Editar proveedor</h4>
                                <button id="closeModalButton" type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <app-editar-proveedor
                                [proveedor]="datos"
                                (salida)="recibe($event)">

                                </app-editar-proveedor>
                                
                            </div>
                        </div>
                    </div>
                </ng-template>

                <ng-template #obs let-c="close" let-d="dismiss">
                    <div class="row">
                       <div class="col">
                           <div  class="cp-modal-header modal-header text-center" id="demoFont">
                               <h4 class="modal-title" id="modal-basic-title">Observacion</h4>
                               <button id="closeModalButton" type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                                   <span aria-hidden="true">&times;</span>
                               </button>
                           </div>
                           <div class="modal-body">
                               <form [formGroup]="fm_update">
                                <textarea formControlName="observacion" rows="10" class="form-control">
                                    <!-- {{ x.proveedor_maestro.observacion }} -->
                                </textarea>
                                <div class="row justify-content-center mt-2">
                                    <div class="col-12 col-md-4">
                                        <button (click)="actualizar(x.id, observacion)" class="btn btn-registrar w-100">ACTUALIZAR</button>
                                    </div>
                                </div>
                               </form>
                           </div>
                       </div>
                   </div>
                   
               </ng-template>
            </div>
        </div>
    </div>
</div>

<app-footer></app-footer>