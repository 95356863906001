<div class="cp-panel-head">
  <div align="center">
    {{ (tipo_presupuesto=='')?'Formulario de compra de items detalle presupuesto':'Pago mano de obra' }}
    

  </div>
</div>
<div *ngIf="tipo_presupuesto==''" class="cp-panel-body">
  <form [formGroup]="_form">
    <div class="row justify-content-center">
      <div class="col-md-6">
        <label for="">Item presupuesto</label>

        <input type="text" value="{{ item_txt  }}" class="form-control" [disabled]="true">
      </div>
      <div class="col-md-6">
        <label for="">Nº documento</label>
        <input formControlName="n_documento" type="text" class="form-control">
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-md-3">
        <label for="">Cantidad</label>
        <input formControlName="cantidad" (input)="sacar_neto();" type="text" class="form-control">
      </div>
      <div class="col-md-3">
        <label for="">Valor</label>
        <input formControlName="valor" (input)="sacar_neto();" type="text" class="form-control">
      </div>
      <div class="col-md-3">
        <label for="">Neto</label>
        <input formControlName="neto" type="text" class="form-control">
      </div>
      <div class="col-md-3">
        <label for="">Iva</label>
        <input formControlName="iva" type="text" class="form-control">
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-md-12">
        <label for="">Observación</label>
        <textarea formControlName="observacion" class="form-control" name="" id="" cols="30" rows="7"></textarea>
      </div>
    </div>

    <!-- <div class="row justify-content-center">
            <div class="col-md-12">
                <label for="">Comprobante</label>
                <input type="file" class="form-control">
            </div>
        </div> -->

  </form>
 
  <button *ngIf="!actualiza" [disabled]="btn_ingresa" (click)="ingresar();" class=" mt-4 btn btn-success w-100">Ingresar</button>
  <button *ngIf="actualiza" [disabled]="btn_update" (click)="actualizar();" class=" mt-4 btn btn-success w-100">Actualizar</button>
</div>


<!-- <pre>{{ actualiza | json}}</pre> -->

<!-- tipo mano de obra -->
<div *ngIf="tipo_presupuesto=='mano_obra'" class="cp-panel-body">
  <form [formGroup]="_form">
    <div class="row">
      <div class="col-md-6">
        <label for="">Item presupuesto</label>

        <input type="text" value="{{ item_txt  }}" class="form-control" [disabled]="true">
      </div>
      <!-- <div class="col-md-6">
        <label for="">Nº documento</label>
        <input formControlName="n_documento" type="text" class="form-control">
      </div> -->
    </div>

    <div class="row">
      <!-- <div class="col-md-3">
        <label for="">Cantidad</label>
        <input formControlName="cantidad" (input)="sacar_neto();" type="text" class="form-control">
      </div>
      <div class="col-md-3">
        <label for="">Valor</label>
        <input formControlName="valor" (input)="sacar_neto();" type="text" class="form-control">
      </div>
      <div class="col-md-3">
        <label for="">Neto</label>
        <input formControlName="neto" type="text" class="form-control">
      </div> -->
      <div class="col-md-6">
        <label for="">Monto pago</label>
        <input formControlName="iva" type="text" class="form-control">
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-md-12">
        <label for="">Observación</label>
        <textarea formControlName="observacion" class="form-control" name="" id="" cols="30" rows="7"></textarea>
      </div>
    </div>

    <!-- <div class="row justify-content-center">
            <div class="col-md-12">
                <label for="">Comprobante</label>
                <input type="file" class="form-control">
            </div>
        </div> -->

  </form>
  <button *ngIf="!actualiza" [disabled]="btn_ingresa" (click)="ingresar();" class=" mt-4 btn btn-success w-100">Ingresar</button>
  <button *ngIf="actualiza" [disabled]="btn_update" (click)="actualizar();" class=" mt-4 btn btn-success w-100">Actualizar</button>
</div>
