<app-navbar></app-navbar>

<div class="container-fluid mt-4">

            <div class="card salmon-outline">

                <div class="card-header cp-card-header">
                    <div align="center">NUEVO PRESUPUESTO</div>
                </div>
                <div class="card-body">
                    <div class="alert alert-warning" role="alert">
                       aqui solo se puede ingresar un presupuesto y detalles del mismo, Para realizar compras en un presupuesto <a style="color: darkcyan;" [routerLink]="['/auth/existente-presupuesto']">haga click aqui</a>
                      </div>
                    <!-- <form action=""> -->
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <label class="txt-gris" for="">Proyecto</label>
                                <!-- <select class="form-control" name="" id="">
                                    <option value="">Proyecto 1</option>
                                </select> -->
                                <!-- <ng-select
                                    [(ngModel)] = "proyecto"
                                    [items]="proyectos"
                                    bindLabel="proyecto"
                                    bindValue="id"
                                    placeholder="--Seleccione--"
                                    (change)="ch_proyecto($event)"
                                    single="true"
                                >

                                </ng-select> -->
                                <ng-select
                                    [(ngModel)] = "proyecto"
                                    class="mb-2"
                                    placeholder="--seleccione--"
                                    [searchable]="true"
                                    [clearable]="true"
                                    (change)="ch_proyecto($event)"
                                    >
                                    <section *ngFor="let i of proyectos;">
                                            <ng-option  value="{{i.id}}">

                                            <b>{{ i.proyecto }}</b> - nº{{ i.cliente.n_cliente }} - {{ i.cliente.nombre_completo }}
                                            </ng-option>
                                        </section>
                                    </ng-select>
                            </div>

                            <div class="col-12 col-md-6">
                                <label class="txt-gris" for="">Titulo de presupuesto</label>
                                <input [(ngModel)]="titulo" type="text" class="form-control">
                            </div>

                        </div>


                    <!-- </form> -->
                    <div *ngIf="!detalle" [disanled]="btn_guarda" align="center" class="mt-3"><button class="btn btn-primary" (click)="registrar_presupuesto();">Registrar</button></div>

                    <div *ngIf="detalle" class="mt-2" align="right"><button id="btn-item"  class="btn btn-success btn-sm" (click)="open_modal(visor)"><i class="fas fa-plus"></i> Agregar Item</button></div>

                    <!-- tabla que mostrara los items -->
                    <div *ngIf="detalle" class="row mt-4">
                        <div class="col-md-12">
                            <!-- {{data | json}} -->
                            <div class="table-responsive">
                                <!-- tabla materiales -->
        <table *ngIf="list_materiales.length > 0" class="mt-4 table table-bordered">
            <thead class="thead-dark">
            <tr>
                <th class="text-center" colspan="13">
                    {{ 'Materiales' }}
                </th>
            </tr>
            </thead>

            <thead class="thead-dark">
                <tr>
                <!-- <td>Tipo</td> -->
                <th></th>
                <th>Item</th>
                <th>Unidad</th>
                <th>Ml</th>
                <th>M2</th>
                <th>M3</th>
                <!-- <th>Total</th> -->
                <th>Cant.</th>
                <th>Precio</th>
                <th colspan="2">Total</th>
                <!-- <th>Opciones</th> -->
                </tr>
            </thead>

            <tr *ngFor="let i of list_materiales | ordenarPor:'estado'">
                <!-- <td>{{ i.tipo | tipoPresupuesto }}</td> -->
                <td [ngStyle]="{'background-color':(i.estado==1)?'#5DADE2' : '#EC7063'}">
                    <!-- {{ i.estado | estadoDetallePresupuesto }} -->
                </td>
                <td>{{ i.item }}</td>
                <td>{{ i.unidad | unidades }}</td>
                <td>{{ i.ml }}</td>
                <td>{{ i.m2 }}</td>
                <td>{{ i.m3 }}</td>
                <!-- <td>{{ calculo_m(i.ml, i.m2, i.m3) }}</td> -->
                <td>{{ i.cantidad }}</td>
                <td>{{ money_clp(i.precio) }}</td>
                <td colspan="2">{{ money_clp(i.cantidad * i.precio) }}</td>
                <!-- <td>
                    <button class="btn btn-sm btn-success tooltipCustom top"
                    (click)="item_texto=i.item;id_detalle=i.id;open_compra();">
                    <i class="fas fa-shopping-cart"></i>
                    <span class="tiptext">comprar</span>
                    </button>
                </td> -->
            </tr>

        </table>

        <!-- tabla mano de obras -->
        <table *ngIf="list_mano_obra.length > 0" class="mt-4 table table-bordered">
           <thead class="thead-dark">
            <tr>
                <th class="text-center" colspan="13">{{ 'Mano de obras' }}</th>
            </tr>
           </thead>
           <thead class="thead-dark">
            <tr>
                <!-- <td>Tipo</td> -->
                <th></th>
                <th>Item</th>
                <!-- <th>Unidad</th>
                <th>Ml</th>
                <th>M2</th>
                <th>M3</th> -->
                <!-- <th>Total</th> -->
                <th>Cant.</th>
                <th>Precio</th>
                <th colspan="2">Total</th>
                <!-- <th>Opciones</th> -->
            </tr>
            </thead>
            <tr *ngFor="let i of list_mano_obra | ordenarPor:'estado'">
                <!-- <td>{{ i.tipo | tipoPresupuesto }}</td> -->
                <td [ngStyle]="{'background-color':(i.estado==1)?'#5DADE2' : '#EC7063'}">
                    <!-- {{ i.estado | estadoDetallePresupuesto }} -->
                </td>
                <td>{{ i.item }}</td>
                <!-- <td>{{ i.unidad | unidades }}</td>
                <td>{{ i.ml }}</td>
                <td>{{ i.m2 }}</td>
                <td>{{ i.m3 }}</td> -->
                <!-- <td>{{ calculo_m(i.ml, i.m2, i.m3) }}</td> -->
                <td>{{ i.cantidad }}</td>
                <td>{{ money_clp(i.precio) }}</td>
                <td colspan="2">{{ money_clp(i.cantidad * i.precio) }}</td>
                <!-- <td>
                    <button class="btn btn-sm btn-success tooltipCustom top"
                    (click)="item_texto=i.item;id_detalle=i.id;open_modal(compra);">
                    <i class="fas fa-shopping-cart"></i>
                    <span class="tiptext">comprar</span>
                    </button>
                </td> -->
            </tr>

        </table>

        <!-- tabla maquinarias -->

        <table *ngIf="list_maquinaria.length > 0" class="mt-4 table table-bordered">
            <thead class="thead-dark">
                <tr>
                  <th class="text-center" colspan="13">{{ 'Maquinarias, herramientas y equipos' }}</th>
                </tr>
            </thead>
            <thead class="thead-dark">
                <tr>
                    <!-- <td>Tipo</td> -->
                    <th></th>
                    <th>Item</th>
                    <th>Unidad</th>
                    <th>Ml</th>
                    <th>M2</th>
                    <th>M3</th>
                    <!-- <th>Total</th> -->
                    <th>Cant.</th>
                    <th>Precio</th>
                    <th colspan="2">Total</th>
                    <!-- <th>Opciones</th> -->
                </tr>
                </thead>
            <tr *ngFor="let i of list_maquinaria | ordenarPor:'estado'">
                <!-- <td>{{ i.tipo | tipoPresupuesto }}</td> -->
                <td [ngStyle]="{'background-color':(i.estado==1)?'#5DADE2' : '#EC7063'}">
                    <!-- {{ i.estado | estadoDetallePresupuesto }} -->
                </td>
                <td>{{ i.item }}</td>
                <td>{{ i.unidad | unidades }}</td>
                <td>{{ i.ml }}</td>
                <td>{{ i.m2 }}</td>
                <td>{{ i.m3 }}</td>
                <!-- <td>{{ calculo_m(i.ml, i.m2, i.m3) }}</td> -->
                <td>{{ i.cantidad }}</td>
                <td>{{ money_clp(i.precio) }}</td>
                <td colspan="2">{{ money_clp(i.cantidad * i.precio) }}</td>
                <!-- <td>

                    <button class="btn btn-sm btn-success tooltipCustom top"
                    (click)="item_texto=i.item;id_detalle=i.id;open_modal(compra);">
                    <i class="fas fa-shopping-cart"></i>
                    <span class="tiptext">comprar</span>
                    </button>
                </td> -->
            </tr>

        </table>

        <!-- tabla comision -->
    <table *ngIf="list_comision.length > 0" class="mt-4 table table-bordered">
        <thead class="thead-dark">
          <tr>
            <th class="text-center" colspan="16">{{ 'Comision' }}</th>
          </tr>
        </thead>
        <thead class="thead-dark">
          <tr class="text-center">
            <!-- <td>Tipo</td> -->
            <th></th>
            <th colspan="2">Item</th>
            <!-- <th>Unidad</th>
            <th>Ml</th>
            <th>M2</th>
            <th>M3</th> -->
            <th>Total</th>
            <!-- <th>Cant.</th>
            <th>Precio</th>
            <th colspan="2">T. ppto.</th>
            <th colspan="2">T. compra</th>
            <th>Resultado</th>
            <th>Opciones</th> -->
          </tr>
        </thead>
        <tr class="text-center" *ngFor="let i of list_comision | ordenarPor:'estado'">
          <!-- <td>{{ i.tipo | tipoPresupuesto }}</td> -->
          <td [ngStyle]="{'background-color':(i.estado==1)?'#5DADE2' : '#EC7063'}">
            <!-- {{ i.estado | estadoDetallePresupuesto }} -->
          </td>
          <td colspan="2" class="text-left">{{ i.item }}</td>

          <td colspan="2">{{ money_clp(i.total) }}</td>

        </tr>

      </table>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="card-footer">
                    <div align="right"><button class="btn btn-outline-success" [routerLink]="['/auth/existente-presupuesto']">Ir a presupuesto existente <i class="fas fa-arrow-right"></i></button></div>
                    <!-- <button (click)="detalle = true;" class="btn btn-light btn-block">Registrar</button> -->
                </div>
            </div>

            <!-- MODAL COMPRA -->
            <ng-template #compra let-c="close" let-d="dismiss">
                <div class="row">
                <div class="col">
                    <div class="cp-modal-header modal-header text-center" id="demoFont">
                    <h4 class="modal-title" id="modal-basic-title">Compra de item</h4>
                    <button id="closeModalButton" type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    </div>
                    <div class="modal-body">
                    <app-formulario-compra [item_txt]="item_texto" [id_detalle]="id_detalle">
                    </app-formulario-compra>
                    <!-- <app-opcion-agregar-item-presupuesto
                            [input_presupuesto]="presupuesto_id"
                            [input_presupuesto_txt]="presupuesto_txt"
                            [input_proyecto_txt]="proyecto_txt"
                            ></app-opcion-agregar-item-presupuesto> -->
                    </div>
                </div>
                </div>
            </ng-template>



            <!-- MODAL ADD ITEM (de momento en esta vista obsoleta) -->
            <ng-template #visor let-c="close" let-d="dismiss">
                <div class="row">
                    <div class="col">
                        <div  class=" cp-card-header modal-header text-center" id="demoFont">
                            <h4 class="modal-title" id="modal-basic-title">Agregar Item de presupuesto</h4>
                            <button id="closeModalButton" type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <form [formGroup]="form_item">

                               <label for="">Tipo de presupuesto</label>


                               <ng-select
                                    formControlName="tipo"
                                    [items]="tipos"
                                    bindLabel="tipo"
                                    bindValue="id"
                                    placeholder="--Seleccione--"
                                    (change)="ch_proyecto($event)"
                                    single="true"
                                >

                                </ng-select>

                                <label>Estado</label>
                                <ng-select
                                class="mb-2"
                                formControlName="estado"
                                placeholder="seleccione"
                                [searchable]="true"
                                [clearable]="true"
                                (change)="ch_estado($event)"
                                >
                                    <section *ngFor="let i of estado;">
                                        <ng-option  value="{{i.id}}">
                                            <i [ngClass]="{'text-danger':i.color=='red', 'text-primary':i.color=='blue'}" class="fas fa-square"></i> {{ i.estado }}
                                        </ng-option>
                                    </section>
                                </ng-select>


                               <label for="">Item</label>
                               <input formControlName="item" class="form-control" type="text" name="" id="">

                               <hr>
                               <div *ngIf="form_item.controls.tipo.value == 1 " class="row">
                                   <div class="col-md-3">
                                    <label for="">Unidad</label>

                                    <ng-select
                                        formControlName="unidad"
                                        [items]="unidades"
                                        bindLabel="unidad"
                                        bindValue="id"
                                        placeholder="--Seleccione--"
                                        single="true"
                                    >

                                    </ng-select>
                                   </div>
                                   <div class="col-md-2">
                                    <label for="">Ml</label>
                                    <input formControlName="ml" (input)="m();" type="text" class="form-control">
                                   </div>
                                   <div class="col-md-2">
                                    <label for="">M2</label>
                                    <input formControlName="m2" (input)="m();" type="text" class="form-control">
                                   </div>
                                   <div class="col-md-2">
                                    <label for="">M3</label>
                                    <input formControlName="m3" (input)="m();" type="text" class="form-control">
                                   </div>
                                   <!-- <div class="col-md-3">
                                    <label for="">Total</label>
                                    <input formControlName="m_total"
                                    [value]=""
                                      type="text" class="form-control">
                                   </div> -->
                               </div>
                               <hr>
                               <div class="row">
                                   <div *ngIf="!no_mostrar_cv" class="col-md-4">
                                       <label for="">Cantidad</label>
                                       <input  formControlName="cantidad" (input)="t();" type="text" class="form-control">
                                   </div>
                                   <div *ngIf="!no_mostrar_cv" class="col-md-4">
                                       <label for="">Valor unitario</label>
                                       <input formControlName="precio" (input)="t();" type="text" class="form-control">
                                   </div>
                                   <div class="col-md-4">
                                       <label for="">Total</label>
                                       <input formControlName="total" type="text" class="form-control">
                                   </div>
                               </div>
                               <hr>

                           </form>

                           <div align="right">
                            <button [disabled]="btn_agrega" class="btn btn-success" (click)="insert_item();"><i class="fas fa-plus"></i> agregar</button>
                           </div>



                        </div>
                        <div class="modal-footer" [hidden]="true">
                            <button type="button" class="btn btn-outline-dark" (click)="c('Save click')">OK</button>
                        </div>
                    </div>
                </div>





            </ng-template>



</div>

<app-footer></app-footer>
