
<div *ngIf="comision==null" class="container-fluid">
    
        <form [formGroup]="form_item">

           <label for="">Item</label>
           <input formControlName="item" class="form-control" type="text" name="" id="">

           <hr>
           <div *ngIf="_detalle_pre.tipo == 1 || _detalle_pre.tipo == 3" class="row">
               <div class="col-md-3">
                <label for="">Unidad</label>

                <ng-select
                    formControlName="unidad"
                    [items]="unidades"
                    bindLabel="unidad"
                    bindValue="id"
                    placeholder="--Seleccione--"
                    single="true"
                >

                </ng-select>
               </div>
               <div class="col-md-2">
                <label for="">Ml</label>
                <input formControlName="ml" (input)="m();" type="text" class="form-control">
               </div>
               <div class="col-md-2">
                <label for="">M2</label>
                <input formControlName="m2" (input)="m();" type="text" class="form-control">
               </div>
               <div class="col-md-2">
                <label for="">M3</label>
                <input formControlName="m3" (input)="m();" type="text" class="form-control">
               </div>
               <hr>
           </div>
           
           <div class="row">
               <div  class="col-md-4">
                   <label for="">Cantidad</label>
                   <input formControlName="cantidad" (input)="t();" type="text" class="form-control">
               </div>
               <div  class="col-md-4">
                   <label for="">Valor unitario</label>
                   <input formControlName="precio" (input)="t();" type="text" class="form-control">
               </div>
               <div class="col-md-4">
                   <label for="">Total</label>
                   <input formControlName="total" type="text" class="form-control">
               </div>
           </div>
           <hr>

        </form>

        <div align="right">
        <button [disabled]="btn_insert" class="btn btn-success" (click)="insert_item();">Actualizar</button>
        </div>
   




</div>

<div *ngIf="comision==true" class="container-fluid">
    <!-- <pre>{{_detalle_pre|json}}</pre> -->
    <form [formGroup]="form_item">

        <label for="">Item</label>
        <input formControlName="item" class="form-control" type="text" name="" id="">

        <hr>
    
        <div class="row">
            
            <div class="col-md-4">
                <label for="">Total</label>
                <input formControlName="total" type="text" class="form-control">
            </div>
        </div>
        <hr>

     </form>

     <div align="right">
     <button [disabled]="btn_insert" class="btn btn-success" (click)="insert_item();">Actualizar</button>
     </div>
</div>
