import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';

import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HeaderInterceptor } from './shared/interceptors/header.interceptor';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './private/components/navbar/navbar.component';
import { FooterComponent } from './private/components/footer/footer.component';
import { LoginComponent } from './public/pages/login/login.component';
import { FormularioClienteComponent } from './private/components/cliente/formulario-cliente/formulario-cliente.component';
import { FormularioProyectoComponent } from './private/components/proyecto/formulario-proyecto/formulario-proyecto.component';
import { FormularioPresupuestoComponent } from './private/components/presupuesto/formulario-presupuesto/formulario-presupuesto.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { HomeComponent } from './private/pages/home/home.component';
import { ListaProyectoComponent } from './private/components/proyecto/lista-proyecto/lista-proyecto.component';
import { DatePipe, DecimalPipe, registerLocaleData } from '@angular/common';
import { PresupuestoExistenteComponent } from './private/components/presupuesto/presupuesto-existente/presupuesto-existente.component';
import { PagoProyectoComponent } from './private/components/proyecto/pago-proyecto/pago-proyecto.component';
import { OpcionVerDetallePresupuestoComponent } from './private/components/presupuesto/existente-presupuesto/opcion-ver-detalle-presupuesto/opcion-ver-detalle-presupuesto.component';
import { OpcionAgregarItemPresupuestoComponent } from './private/components/presupuesto/existente-presupuesto/opcion-agregar-item-presupuesto/opcion-agregar-item-presupuesto.component';
import { UnidadesPipe } from './shared/pipes/unidades.pipe';
import { TipoPresupuestoPipe } from './shared/pipes/tipo-presupuesto.pipe';
import { OrdenarPorPipe } from './shared/pipes/ordenar-por.pipe';
import { EstadoDetallePresupuestoPipe } from './shared/pipes/estado-detalle-presupuesto.pipe';
import { FormularioCompraComponent } from './private/components/compra/formulario-compra/formulario-compra.component';
import { OpcionFleteComponent } from './private/components/presupuesto/existente-presupuesto/opcion-flete/opcion-flete.component';
import { EstadoProyectoPipe } from './shared/pipes/estado-proyecto.pipe';
import { AuthComponent } from './private/pages/auth/auth.component';
import { VerPresupuestoCompraComponent } from './private/components/compra/ver-presupuesto-compra/ver-presupuesto-compra.component';
import { NgbActiveModal, NgbModalRef, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { OpcionComprobanteComponent } from './private/components/presupuesto/existente-presupuesto/opcion-comprobante/opcion-comprobante.component';
import { SafePipe } from './shared/pipes/safe.pipe';
import { ActualizarClienteComponent } from './private/components/cliente/actualizar-cliente/actualizar-cliente.component';
import { HistorialProyectoComponent } from './private/components/proyecto/historial-proyecto/historial-proyecto.component';
import localeEs from '@angular/common/locales/es-CL';
import { FormularioProveedoresComponent } from './private/components/proveedores/formulario-proveedores/formulario-proveedores.component';
import { ListaProveedoresComponent } from './private/components/proveedores/lista-proveedores/lista-proveedores.component';
import { FormularioGastosGeneralesComponent } from './private/components/gastos-generales/formulario-gastos-generales/formulario-gastos-generales.component';
import { ListaGastosGeneralesComponent } from './private/components/gastos-generales/lista-gastos-generales/lista-gastos-generales.component';
import { CuentaBancariaComponent } from './private/components/proveedores/cuenta-bancaria/cuenta-bancaria.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { FormularioMaterialesComponent } from './private/components/proveedores/formulario-materiales/formulario-materiales.component';
import { ListaMaterialesComponent } from './private/components/proveedores/lista-materiales/lista-materiales.component';
import { EditarProveedorComponent } from './private/components/proveedores/editar-proveedor/editar-proveedor.component';
import { EditarMaterialesComponent } from './private/components/proveedores/editar-materiales/editar-materiales.component';
import { EditarCuentaBancarioComponent } from './private/components/proveedores/editar-cuenta-bancario/editar-cuenta-bancario.component';
import { HistorialMaterialComponent } from './private/components/proveedores/historial-material/historial-material.component';
import { EditarGastosComponent } from './private/components/gastos-generales/editar-gastos/editar-gastos.component';
import { EditarDetallePresupuestoComponent } from './private/components/presupuesto/existente-presupuesto/editar-detalle-presupuesto/editar-detalle-presupuesto.component';

registerLocaleData(localeEs);

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    LoginComponent,
    FormularioClienteComponent,
    FormularioProyectoComponent,
    FormularioPresupuestoComponent,
    HomeComponent,
    ListaProyectoComponent,
    PresupuestoExistenteComponent,
    PagoProyectoComponent,
    OpcionVerDetallePresupuestoComponent,
    OpcionAgregarItemPresupuestoComponent,
    UnidadesPipe,
    TipoPresupuestoPipe,
    OrdenarPorPipe,
    EstadoDetallePresupuestoPipe,
    FormularioCompraComponent,
    OpcionFleteComponent,
    EstadoProyectoPipe,
    AuthComponent,
    VerPresupuestoCompraComponent,
    OpcionComprobanteComponent,
    SafePipe,
    ActualizarClienteComponent,
    HistorialProyectoComponent,
    FormularioProveedoresComponent,
    ListaProveedoresComponent,
    FormularioGastosGeneralesComponent,
    ListaGastosGeneralesComponent,
    CuentaBancariaComponent,
    FormularioMaterialesComponent,
    ListaMaterialesComponent,
    EditarProveedorComponent,
    EditarMaterialesComponent,
    EditarCuentaBancarioComponent,
    HistorialMaterialComponent,
    EditarGastosComponent,
    EditarDetallePresupuestoComponent
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgbModule,
    AutocompleteLibModule
   
    
  ],
  providers: [
    DecimalPipe,
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeaderInterceptor,
      multi: true,
    },
    NgbActiveModal,
    {
      provide: LOCALE_ID,
      useValue: 'es-CL',
    },
    // NgbModalRef
    
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
