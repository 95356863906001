<app-navbar></app-navbar>

<div class="container-fluid mt-4">
  <div class="card salmon-outline">

    <div class="card-header cp-card-header">
      <div class="row">
        <div class="col-4 col-md-2"><a [routerLink]="['/auth/formulario-proyecto']"><i class="fas fa-arrow-left"></i> Volver</a>
        </div>
        <div class="col-7 col-md-8 text-center font-weight-bold">LISTA DE PROYECTOS</div>
      </div>
    </div>

    <div class="card-body">

      <div class="row">
        <div class="col-9 col-md-11">
          <input type="text" class="form-control" [(ngModel)]="search" (keydown.enter)="filter_cliente(search)"
            placeholder="buscar por proyecto, nombre o numero cliente..">
        </div>
        <div class="col-1 col-md-1">
          <button class="btn btn-light w-100" (click)="listar(); search='';">
            <i class="fas fa-sync-alt"></i>
          </button>
        </div>
      </div>
      <br>
      <div class="table-responsive">
        <table class="table table-bordered">
          <thead class="thead-dark text-center">
            <tr class="table-primary">
              <th></th>
              <th>Nº</th>
              <th>Cliente</th>
              <th>Fecha de inicio</th>
              <th>Proyecto</th>
              <th class="anchoTh">Costo</th>
              <th>Cuotas</th>
              <th colspan="4">Opciones</th>
            </tr>
          </thead>

          <tr *ngFor="let t of listado" class="text-center">
            <td *ngIf="t.estado==1" class="azul tooltipEstado top">
              <span class="tiptext">no iniciado</span>
              <!-- {{ t.estado | estadoProyecto}} -->
            </td>
            <td class="tooltipEstado top" *ngIf="t.estado==2" class="amarillo tooltipEstado top">
              <span class="tiptext">en proceso</span>

              <!-- {{ t.estado | estadoProyecto}} -->
            </td>
            <td class="tooltipEstado top" *ngIf="t.estado==3" class="verde tooltipEstado top">
              <span class="tiptext">finalizado</span>

              <!-- {{ t.estado | estadoProyecto}} -->
            </td>
            <td>{{ t.cliente.n_cliente }}</td>
            <td>{{ t.cliente.nombre_completo }}</td>
            <td>{{ t.fecha_inicio | date:'dd/MM/yyyy':'+0000':'es-CL' }}</td>
            <td>{{ t.proyecto }}</td>
            <!-- <td>{{ t.total | number:'1.0-0' }}</td> -->
            <td>{{ money_clp(t.total) }}</td>
            <td>{{ t.cuotas }}</td>
            <td>

              <button class="btn btn-visualizar tooltipCustom top mx-1" [routerLink]="['/auth/pago-proyecto/'+t.id]">
                <i class="fas fa-hand-holding-usd"></i>
                <span class="tiptext">cobrar</span>
              </button>
            </td>
            <td>
              <button [routerLink]="['/auth/historial-proyecto/'+t.id]"
                class="btn btn-historial tooltipCustom top mx-1">
                <i class="fas fa-history"></i>
                <span class="tiptext">resumen</span>
              </button>
            </td>
            <td>
              <button (click)="
              proyecto_id=t.id;
              estado_index=t.estado;
              campos_editar=t;
              open_modal(editar);
              construir_form();
              " class="btn btn-editar tooltipCustom top mx-1">
                <i class="fas fa-edit"></i>
                <span class="tiptext">editar</span>
              </button>
            </td>
            <td>  
              <button (click)="eliminar(t.id)" class="btn btn-danger tooltipCustom top mx-1">
                <i class="fas fa-trash"></i>
                <span class="tiptext">eliminar</span>
              </button>

            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>

<!-- modal editar -->
<ng-template #editar let-c="close" let-d="dismiss">
  <div class="row">
    <div class="col">
      <div class=" cp-card-header modal-header text-center" id="demoFont">
        <h4 class="modal-title" id="modal-basic-title">Editar</h4>
        <button id="closeModalButton" type="button" class="close" aria-label="Close" (click)="d('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="form_editar">
          <label>Estado</label>
          <ng-select formControlName="estado" [items]="estados" bindLabel="estado" bindValue="id"
            placeholder="--Seleccione--" single="true">
            <!-- (change)="ch_proyecto($event)" -->

          </ng-select>
          <hr>
          <label>Proyecto</label>
          <input formControlName="proyecto" type="text" class="form-control">
          <br>
          <label>Fecha de inicio</label>
          <input formControlName="fecha_inicio" type="date" class="form-control">
          <br>
          <label>Costo total</label>
          <input formControlName="total" class="form-control" type="text" name="" id="">
          <br>
          <label>Cuotas</label>
          <input formControlName="cuotas" class="form-control" type="text">
        </form>
        <br>
        <button [disabled]="btn_update" (click)="update();" class="btn btn-registrar w-100">Actualizar</button>
      </div>
      <div class="modal-footer" [hidden]="true">
        <button type="button" class="btn btn-outline-dark" (click)="c('Save click')">OK</button>
      </div>
    </div>
  </div>

</ng-template>

<app-footer></app-footer>
