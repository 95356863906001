<div>
    <form [formGroup]="form_update">
        <div class="row">
            <!-- {{ input_cliente | json }} -->
            <div class="col-md-6">
                <label  for="">Nª Cliente</label>
                <input formControlName="n_cliente" type="text" class="form-control">
            </div>
            <div class="col-md-6">
                <label  for="">Fecha de ingreso</label>
                <input formControlName="fecha_creacion" type="date" class="form-control">
            </div>
        </div>

        <div class="row mt-3">
            <div class="col-md-6">
                <label  for="">Nombres</label>
                <input formControlName="nombres" type="text" class="form-control">
            </div>
            <div class="col-md-6">
                <label  for="">Apellidos</label>
                <input formControlName="apellidos" type="text" class="form-control">
            </div>
        </div>

        <div class="row mt-3">
            <div class="col-md-6">
                <label  for="">Contacto</label>
                <input formControlName="n_contacto" type="text" class="form-control">
            </div>
            <div class="col-md-6">
                <label  for="">Dirección</label>
                <input formControlName="direccion" type="text" class="form-control">
            </div>
        </div>


    </form>
    <br>
    <button (click)="update();" class="btn btn-registrar">Actualizar</button>
</div>