import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ProveedorService } from '../../../../shared/services/proveedor.service';
import { DecimalPipe } from '@angular/common';
import { FormGroup, FormBuilder } from '@angular/forms';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-lista-proveedores',
  templateUrl: './lista-proveedores.component.html',
  styleUrls: ['./lista-proveedores.component.css'],
})
export class ListaProveedoresComponent implements OnInit {
  select_prov=null;
  listado: any;
  proveedor: any;
  datos:any;
  proveedores: any = [
    { id: 1, name: 'empresas' },
    { id: 2, name: 'fletes' },
    { id: 3, name: 'maestros' },
  ];
  modalref=null;
  fm_update:FormGroup;
  
  constructor(
    private _proveedor: ProveedorService,
    config: NgbModalConfig,
    private modal: NgbModal,
    public DecimalPipe: DecimalPipe,
    private fb:FormBuilder
  ) {
    config.backdrop = 'static';
    config.keyboard = false;
  }
  recibe(entrada){
    console.log("entrada:", entrada)
    if(entrada=='true'){
      this.modalref.close();
      this.listar(this.select_prov);
    }
  }

  ngOnInit(): void {}
  listar(event) {
    this.listado = null;
    this.proveedor = event;
    console.log(this.proveedor);
    this._proveedor.traer(event).subscribe((res) => {
      if (res.estado) {
        this.listado = res.proveedores;
        
      }
      console.log(res);
    });
  }

  open_modal(ref, size) {
    this.modalref = this.modal.open(ref, { size: 'lg', windowClass: 'big-modal' });
    return false;
  }
  open_modal_md(ref) {
    this.modalref = this.modal.open(ref, { size: 'md' });
    return false;
  }
  open_modal_lg(ref){
    this.modalref = this.modal.open(ref, { size: 'lg' });
    return false;
  }

  money_clp(value) {
    if(value==''){
      return '0';
    }
    if(typeof value == 'string'){
      return value;
    }
    return '$ ' + this.DecimalPipe.transform(value)?.replace(/,/g, '.');
  }
  construir_fm(x){
    console.log('x',x)
    this.fm_update = this.fb.group({
      id:x.id,
      observacion:x.proveedor_maestro.observacion
    })
  }
  actualizar(id, obs){
    
    this._proveedor.actualizar_proveedor(this.fm_update.value).subscribe(res=>{
      if(res.estado){
        Swal.fire(
          ''+res.mensaje+'',
          '',
          'success'
        );
        this.modalref.close();
        this.listar(this.select_prov);
      }else{
        Swal.fire(
          'Faltan campos por llenar',
          '',
          'error'
        );
      }
    }, error =>{
      Swal.fire(
        'Faltan campos por llenar',
        '',
        'error'
      );
    });
  }
}
